import React, { useState } from 'react';
import { Tag, Radio } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Button from '../Button';
import { text, sizes } from '../../constants';
import WarningIcon from '../../assets/icons/ic_exclamation_warning_filled.svg';

const styles = {
  root: {
    width: '561px',
    maxWidth: '900px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rootMobile: {
    width: '100%',
  },
  icon: {
    width: 56,
    height: 56,
    marginBottom: sizes.SPACE_SMALL,
  },
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
    marginBottom: sizes.SPACE_SMALL,
  },
  warning: {
    fontSize: 18,
    color: '#1c1c1c',
    marginBottom: sizes.SPACE_SMALL,
  },
  text: {
    fontSize: 18,
    lineHeight: '21px',
    color: '#1C1C1C',
    marginBottom: sizes.SPACE_MEDIUM,
  },
  radio: {
    fontSize: 18,
    marginBottom: sizes.SPACE_SMALL,
  },
  buttonLayout: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row-reverse',
    marginTop: sizes.SPACE_LARGE,
  },
  warningText: {
    color: '#1C1C1C',
    fontSize: '18px',
    lineHeight: '21px',
    marginTop: sizes.SPACE_MEDIUM,
    padding: '9px 20px',
    whiteSpace: 'normal',
  },
  acknowledgeText: {
    color: '#1C1C1C',
    fontSize: '18px',
    lineHeight: '21px',
    marginTop: sizes.SPACE_MEDIUM,
    whiteSpace: 'normal',
  },
};

const UnsecureWarning = ({ next, typeText }) => {
  const screens = useBreakpoint();
  const mobile = !screens.md;
  const [ack, setAck] = useState(false);
  return (
    <div>
      <div style={styles.title}>Security Warning</div>
      <div style={mobile ? styles.rootMobile : styles.root}>
        <div style={styles.warning}>
          <Tag style={styles.warningText} icon={<img src={WarningIcon} alt="warning" />} color="yellow">
            &nbsp;You’re logging in through an unsecure method.
          </Tag>
        </div>
        <div style={styles.text}>
          You’re logging in with an method that is not recommended. Because of
          the security risks involved, this form of sign should only be used by
          experienced users.
        </div>
        <div style={styles.radio}>
          <Radio style={styles.acknowledgeText} value checked={ack} onChange={(e) => setAck(e.target.value)}>
            I acknowledge the risks with logging in with a
            {' '}
            {typeText}
            .
          </Radio>
        </div>
        <div style={styles.buttonLayout}>
          <Button disabled={!ack} type="primary" onClick={next}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UnsecureWarning;
