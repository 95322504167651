import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setSearchedAccountAndFetchData } from '../actions/searchedAccount';
import TransactionsHeading from './TransactionsHeading';

const SearchWalletSection = ({ accountAddress }) => {
  const dispatch = useDispatch();
  const {
    account, balance, searchedNonce,
  } = useSelector(
    (state) => ({
      account: state.searchedAccount,
      balance: state.searchedBalance,
      searchedNonce: state.searchedNonce,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!account || account.address !== accountAddress) {
      dispatch(
        setSearchedAccountAndFetchData({
          address: accountAddress,
          type: 'search',
        }),
      );
    }
  }, [account, dispatch, accountAddress]);
  return (
    <TransactionsHeading title="Addresses" address={account?.address} nonce={searchedNonce} balance={balance?.data} />
  );
};

SearchWalletSection.propTypes = {};

export default SearchWalletSection;
