import ReduxUtils from '../utils/ReduxUtils';
import { stakingTransactionsRequest } from './searchedStakingTransactions';
import { fetchSearchedBalance } from './searchedBalance';
import { transactionsRequest } from './searchedAccountTransactions';
import { fetchSearchedNonce } from './searchedNonce';

export const SEARCHED_ACCOUNT_UPDATE = 'SEARCHED_ACCOUNT_UPDATE';

export const updateSearchedAccount = ReduxUtils.createAction(SEARCHED_ACCOUNT_UPDATE, 'searchedAccount');
export const WALLET_ADDRESS_LENGTH = 66;

export const setSearchedAccountAndFetchData = (newAccount) => async (dispatch, getState) => {
  const { searchedAccount } = getState();
  if (!searchedAccount ||
    (
      searchedAccount.address !== newAccount.address &&
      newAccount.address.length === WALLET_ADDRESS_LENGTH
    )) {
    dispatch(updateSearchedAccount(newAccount));
    dispatch(fetchSearchedNonce());
    dispatch(fetchSearchedBalance());
    dispatch(transactionsRequest());
    dispatch(stakingTransactionsRequest());
  }
};

// export const fetchDataIfSearchedAccountExists = () => async (dispatch, getState) => {
//   const { searchedAccount } = getState();
//   if (searchedAccount) {
//     dispatch(fetchSearchedNonce());
//     dispatch(fetchSearchedBalance());
//     dispatch(searchedAccountTransactions());
//     dispatch(searchedStakingTransactions());
//     dispatch(fetchAllSearchedDelegationData());
//   }
// };
