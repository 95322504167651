import React, { useState } from 'react';
import {
  Grid, Card, Row,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { right } from 'glamor';
import { text, sizes, elements, strings } from '../constants';
import HomeWalletSection from '../components/HomeWalletSection';
import HomeTransactions from '../components/HomeTransactions';
import HomeStakingSection from '../components/HomeStakingSection';
import HomeStakesList from '../components/HomeStakesList';
import HomeTitle from '../components/mobile/HomeTitle';
import { fetchAccountRecentTransactions } from '../actions/accountRecentTransactions';
import { fetchStakingTransactions } from '../actions/stakingTransactions';
import EmptyState from '../components/EmptyState';
import { fetchStakingFinalizationTransactions } from '../actions/stakingFinalizationTransactions';
import { toggleReceiveFundsModal, toggleLoginModal } from '../actions/modals';
import LoginModal from '../components/modals/LoginModal';
import SearchInput from '../components/SearchInput';
import { sendAmplitudeData } from '../utils/Amplitude';

const { useBreakpoint } = Grid;

const styles = {
  appTitle: {
    ...text.TITLE_LARGE,
    flexGrow: '1',
    flexDirection: 'row',
  },
  appHeading: {
    ...text.HEADING_LARGE,
    marginTop: sizes.SPACE_MEDIUM,
    flexGrow: '1',
    flexDirection: 'row',
  },
  headingContainer: {
    flexGrow: '1',
    flexDirection: 'row',
    display: 'flex',
    paddingBottom: sizes.SPACE_SMALL,
  },
  walletCard: {
    ...elements.CARD,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardHeading: {
    ...text.HEADING_MEDIUM,
    flexGrow: '1',
    marginLeft: sizes.SPACE_SMALL,
    marginRight: sizes.SPACE_SMALL,
    marginBottom: sizes.SPACE_SMALL,
  },
  textLabel: {
    ...text.BODY,
    flexGrow: '1',
    width: '10%',
    flexDirection: 'row',
    marginLeft: sizes.SPACE_SMALL,
    marginRight: sizes.SPACE_SMALL,
    marginTop: sizes.SPACE_SMALL,
    color: 'gray', // TODO Color code needed from Mishaal
    backgroundColor: 'white',
  },
  loginMobileButton: {
    float: right,
    flexGrow: '1',
    marginLeft: '60%',
  },
  tabs: {
    marginTop: sizes.SPACE_SMALL,
    paddingLeft: sizes.SPACE_SMALL,
    paddingRight: sizes.SPACE_SMALL,
  },
};

export default function Home() {
  const [redirect, setRedirect] = useState();
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const pools = useSelector((state) => state.pools);
  const mobile = !screens.md;

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      {sendAmplitudeData(strings.VIEW_HOME)}
      {!pools.data && pools.loading && (
        <div>
          <ContentLoader viewBox="0 0 100 80">
            <rect x="0" y="0" rx="1" ry="1" width="10" height="3" />
            <rect x="0" y="10" rx="2" ry="2" width="32" height="13" />
            <rect x="33" y="10" rx="2" ry="2" width="32" height="13" />
            <rect x="66" y="10" rx="2" ry="2" width="32" height="13" />
            <rect x="0" y="30" rx="2" ry="2" width="100" height="80" />
          </ContentLoader>
        </div>
      )}
      {pools?.data && (
        <>
          {account ? (
            <>
              {mobile ? <HomeTitle /> : <div style={styles.appTitle}>Home</div>}

              <div style={styles.appHeading}>Wallet</div>

              <HomeWalletSection />
              <div style={styles.appHeading}>Transactions</div>
              <HomeTransactions
                selectorAccount="account"
                selectorWalletTransactions="accountRecentTransactions"
                walletTransactionsSource={(page) =>
                  dispatch(fetchAccountRecentTransactions(page))}
                transactionsEmptyLayout={(
                  <EmptyState
                    title="You don’t have any transactions yet"
                    description="Deposit or receive AION to your account to get started"
                    buttonText="Start Receiving Aion"
                    onClick={() => {
                      sendAmplitudeData(strings.RECEIVE_AION);
                      dispatch(toggleReceiveFundsModal())}}
                  />
                )}
                selectorStakingTransactions="stakingTransactions"
                stakingTransactionsSource={(page) =>
                  dispatch(fetchStakingTransactions(page))}
                stakingTransactionsEmptyLayout={(
                  <EmptyState
                    title="You’re not currently staking to any pools"
                    description="Stake your AION to get started"
                    buttonText="Start Staking"
                    onClick={() => {
                      sendAmplitudeData(strings.START_STAKING);
                      setRedirect('/staking?tab=2');}}
                  />
                )}
                selectorStakingFinalizationTransactions="stakingFinalizationTransactions"
                stakingFinilizationTransactionsSource={(page) =>
                  dispatch(fetchStakingFinalizationTransactions(page))}
              />
              <div style={styles.appHeading}>Staking</div>
              {!mobile && (
                <HomeStakingSection
                  onStake={() => setRedirect('/staking?tab=2')}
                  onUnstake={() => setRedirect('/staking')}
                  onWithdraw={() => setRedirect('/staking')}
                />
              )}

              <Card style={styles.root} bodyStyle={styles.walletCard}>
                <div style={styles.cardHeading}>My Stakes and Rewards</div>
                <HomeStakesList />
              </Card>
            </>
          ) : (
            <>
              {mobile && <div style={{ marginBottom: '30px' }}><HomeTitle /></div>}
              <Row justify="center" style={{ marginBottom: '30px' }}>
                <SearchInput />
              </Row>
              <Card style={styles.walletCard}>
                <LoginModal customOnOptionSelected={(index) => {
                  dispatch(toggleLoginModal({ defaultOptionSelected: index }));
                }}
                />
              </Card>

            </>
          )}
        </>
      )}
    </div>
  );
}
