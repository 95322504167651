import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'antd';
import QRCode from 'qrcode.react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { text, sizes } from '../../constants';
import Button from '../Button';

const styles = {
  title: { marginBottom: sizes.SPACE_MEDIUM, ...text.TITLE_MEDIUM },
  heading: { marginBottom: sizes.SPACE_SMALL, ...text.HEADING_MEDIUM },
  qrWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: sizes.SPACE_SMALL,
  },
  alertWrapper: {
    marginTop: '48px',
    marginBottom: sizes.SPACE_SMALL,
  },
  alertMessage: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '21px',
    color: '#1C1C1C',
  },
  buttonWrapper: {
    marginTop: sizes.SPACE_MEDIUM,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
const ReceiveModal = () => {
  const account = useSelector((state) => state.account);
  const [copyButtonText, setCopyButtonText] = useState('Copy Address');
  return (
    <div>
      <div style={styles.title}>Receive Aion</div>
      <div style={styles.heading}>QR Code</div>
      <div style={styles.qrWrapper}>
        <QRCode value={account.address} />
      </div>
      <div style={styles.heading}>Address</div>
      {account.address}
      <div style={styles.alertWrapper}>
        <Alert
          message={(
            <div style={styles.alertMessage}>
              Only send Aion to this address
            </div>
          )}
          type="warning"
          showIcon
        />
      </div>
      <div>
        Sending coins or tokens other than Aion to this address may result in the
        loss of your deposit. Coins will be deposited after network
        confirmations.
      </div>
      <div style={styles.buttonWrapper}>
        <CopyToClipboard
          text={account.address}
          onCopy={() => {
            setCopyButtonText('Copied!');
            setTimeout(() => setCopyButtonText('Copy Address'), 3000);
          }}
        >
          <Button style={{ minWidth: '175px' }} type="primary">{copyButtonText}</Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default ReceiveModal;
