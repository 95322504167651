import React, { useState } from 'react';
import { Card, Tag } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { text, elements, sizes, strings } from '../../constants';
import LedgerIcon from '../../assets/icons/ic_ledger.svg';
import KeystoreIcon from '../../assets/icons/ic_keystore.svg';
import PrivateKeyIcon from '../../assets/icons/ic_privatekey.svg';
import MnemonicIcon from '../../assets/icons/ic_mnemonic.svg';
import LedgerFlow from './ledger';
import PrivateKeyFlow from './privatekey';
import KeysotreFlow from './keystore';
import MnemonicFlow from './mnemonic';
import { sendAmplitudeData } from '../../utils/Amplitude';

const styles = {
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
    marginBottom: '15px',
  },
  options: {
    display: 'flex',
    height: '272px',
    marginTop: '25px',
    marginBottom: '25px',
  },
  optionsMobile: {
    flexDirection: 'column',
  },
  card: {
    margin: sizes.SPACE_SMALL,
    width: '25%',
    minWidth: '230px',
    height: '100%',
    cursor: 'pointer',
  },
  cardMobile: {
    width: '100%',
    marginBottom: sizes.SPACE_SMALL,
  },
  cardBody: {
    ...elements.CARD,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '100%',
  },
  cardIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cardTitle: {
    ...text.BALANCE,
  },
  cardDescription: {
    ...text.BODY,
    color: '#6F6F6F',
  },
  cardTag: {
    minHeight: 22,
  },
};
const LOGIN_OPTIONS = [
  {
    icon: LedgerIcon,
    title: 'Ledger',
    description: 'Sign in using your Ledger hardware wallet (Nano S)',
    isRecommended: true,
  },
  {
    icon: KeystoreIcon,
    title: 'Keystore File',
    description: 'Use your encrypted keystore to sign in.',
    isRecommended: false,
  },
  {
    icon: PrivateKeyIcon,
    title: 'Private Key',
    description: 'Use your private key to sign into your AION account.',
    isRecommended: false,
  },
  {
    icon: MnemonicIcon,
    title: 'Mnemonic Phrase',
    description: 'Enter your mnemonic phrase to sign in.',
    isRecommended: false,
  },
];
const LoginModal = ({ customOnOptionSelected, modalExtraData }) => {
  const { defaultOptionSelected } = modalExtraData ?? {};
  const screens = useBreakpoint();
  const mobile = !screens.md;
  const [optionSelected, setOptionSelected] = useState(defaultOptionSelected ?? false);
  let content;
  switch (optionSelected) {
    case 0: {
      content = <LedgerFlow />;
      sendAmplitudeData(strings.LOGIN_LEDGER);
      break;
    }
    case 1: {
      content = <KeysotreFlow />;
      sendAmplitudeData(strings.LOGIN_KEYSTORE);
      break;
    }
    case 2: {
      content = <PrivateKeyFlow />;
      sendAmplitudeData(strings.LOGIN_PRIVATE_KEY);
      break;
    }
    case 3: {
      content = <MnemonicFlow />;
      sendAmplitudeData(strings.LOGIN_MNEMONIC_PHRASE);
      break;
    }
    default: {
      content = (
        <>
          <div style={styles.title}>Sign into your wallet</div>
          <div style={mobile ? styles.optionsMobile : styles.options}>
            {LOGIN_OPTIONS.map((option, index) => (
              <Card
                key={index}
                style={mobile ? styles.cardMobile : styles.card}
                bodyStyle={styles.cardBody}
                onClick={() => {
                  if (customOnOptionSelected) {
                    customOnOptionSelected(index);
                  } else {
                    setOptionSelected(index);
                  }
                }}
              >
                <img
                  style={styles.cardIcon}
                  src={option.icon}
                  alt={option.title}
                />
                <div style={styles.cardTitle}>{option.title}</div>
                <div style={styles.cardDescription}>{option.description}</div>
                <div style={styles.cardTag}>
                  {option.isRecommended && <Tag color="green">RECOMMENDED</Tag>}
                </div>
              </Card>
            ))}
          </div>
        </>
      );
    }
  }
  return <div>{content}</div>;
};

export default LoginModal;
