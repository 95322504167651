/* eslint-disable no-restricted-syntax */
import createKeccakHash from 'keccak';

export default class Checksum {
  static encode(addressIn = '') {
    const address = addressIn.toLowerCase().replace('0x', '');
    const hash = createKeccakHash('keccak256').update(address).digest('hex');
    let ret = '0x';

    for (let i = 0; i < address.length; i += 1) {
      if (parseInt(hash[i], 16) >= 8) {
        ret += address[i].toUpperCase();
      } else {
        ret += address[i];
      }
    }

    return ret;
  }

  static test(addr) {
    return this.encode(addr) === addr;
  }
}
