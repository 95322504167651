import React from 'react';
import {
  text, sizes,
} from '../constants';

const styles = {
  noResultsLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  appHeading: {
    ...text.HEADING_LARGE,
    marginTop: sizes.SPACE_MEDIUM,
    flexGrow: '1',
    flexDirection: 'row',
  },
};
const SearchError = () => (
  <div style={styles.noResultsLayout}>
    <div style={styles.appHeading}>No matching results </div>
    <div>Oops! We couldn&apos;t find an address or transaction hash that matched your search.</div>
  </div>
);

export default SearchError;
