let instance;
export default class ApiService {
  static getInstance(apiUrl, apiAccountUrl, handler) {
    if (!instance) {
      instance = new ApiService(apiUrl, apiAccountUrl, handler);
    }
    return instance;
  }

  constructor(apiUrl, apiAccountUrl, handler) {
    this.apiUrl = apiUrl;
    this.apiAccountUrl = apiAccountUrl;
    this.handler = handler;
  }

  async fetchStakingTransactions(address, searchMode) {
    const resp = await fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'transactions',
        params: [address, 0, 1000],
        id: '1',
      }),
    });
    const json = await resp.json();
    if (!searchMode) this.handler.handle(json);
    return json;
  }

  async fetchStakingFinalizationTransactions(address, searchMode) {
    const resp = await fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'finalizations',
        params: [address, 0, 1000],
        id: '1',
      }),
    });
    const json = await resp.json();
    if (!searchMode) this.handler.handle(json);
    return json;
  }

  async fetchDelegations(address) {
    const resp = await fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'delegations',
        params: [address, 0, 100000000],
        id: '1',
      }),
    });
    const json = await resp.json();
    return json;
  }

  async fetchPoolLogo(address) {
    const resp = await fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'pool_logo',
        params: [address],
        id: '1',
      }),
    });
    return resp.json();
  }

  async fetchAccountTransactions(address, page = 0, size = 10) {
    const resp = await fetch(
      `${this.apiAccountUrl}/transaction/account/${address}?page=${page}&size=${size}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      },
    );
    if (resp.status === 200) return resp.json();
    if (resp.status === 204) {
      return { transactionArray: [[]] };
    }
    return resp.json();
  }

  async fetchTransaction(txHash) {
    const resp = await fetch(`${this.apiAccountUrl}/transaction/${txHash}`);
    return resp.json();
  }

  async fetchTransactionHash(txHash) {
    const resp = await fetch(`${this.apiAccountUrl}/transaction/${txHash}`);
    return resp.json();
  }

  async fetchNonce(address) {
    const resp = await fetch(`${this.apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'eth_getTransactionCount',
        params: [address, 'pending'],
        id: '1',
      }),
    });
    return resp.json();
  }

  async fetchBalance(address) {
    const resp = await fetch(`${this.apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'eth_getBalance',
        params: [address],
        id: '1',
      }),
    });
    return resp.json();
  }

  async fetchRawTxSubmission(tx) {
    const resp = await fetch(`${this.apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'eth_sendRawTransaction',
        params: [tx],
        id: '1',
      }),
    });
    return resp.json();
  }

  async fetchTxReceipt(txHash) {
    const resp = await fetch(`${this.apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'eth_getTransactionReceipt',
        params: [txHash],
        id: '1',
      }),
    });
    return resp.json();
  }
}
