import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeAllModals } from '../../../actions/modals';
import { setAccountAndFetchData } from '../../../actions/account';
import UnsecureWarning from '../UnsecureWarning';
import MnemonicAccount from './MnemonicAccount';

export default () => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  let content;
  switch (step) {
    case 0: {
      content = (
        <UnsecureWarning typeText="mnemonic phrase" next={() => setStep(1)} />
      );
      break;
    }
    case 1: {
      content = (
        <MnemonicAccount
          next={(account) => {
            dispatch(closeAllModals());
            dispatch(
              setAccountAndFetchData({
                address: account.address,
                pubKey: account.pubKey,
                privateKey: account.privateKey,
                type: 'mnemonic',
              }),
            );
          }}
        />
      );
      break;
    }
    default: {
      break;
    }
  }
  return <div>{content}</div>;
};
