import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Switch, Tooltip,
} from 'antd';
import { useDispatch } from 'react-redux';
import { elements, strings } from '../constants';
import Button from './Button';
import { RAW_TX_TYPE } from '../actions/rawTransaction';
import { toggleUnstakeFundsModal } from '../actions/modals';
import { sendAmplitudeData } from '../utils/Amplitude';

const styles = {
  root: {},
  downBox: elements.DOWNBOX,
  withdraw: {
    fontSize: 10,
    lineHeight: '11px',
    padding: '4px 8px',
    height: 'auto',
    borderTopRightRadius: 10,
  },
  tooltip: {
    background: '#6F6F6F',
    borderRadius: 25,
    width: 17,
    height: 17,
    fontSize: 12,
    display: 'inline-block',
    color: 'white',
    verticalAlign: 'middle',
    textAlign: 'center',
  },
};

function HomeStakeControls({ autoStake, pool }) {
  const dispatch = useDispatch();
  return (
    <>
      <Row style={styles.root} align="middle" gutter={[16, 0]}>
        <Col>
          <Row align="middle" gutter={[8, 8]}>
            <Col>
              Auto-stake
              {' '}
              <Tooltip title="Toggle automatic reward staking">
                <span style={styles.tooltip}>?</span>
              </Tooltip>
            </Col>
            <Col>
              <Switch
                checked={autoStake}
                onChange={(flag) =>
                  dispatch(
                    toggleUnstakeFundsModal({
                      ...pool,
                      operationType: flag
                        ? RAW_TX_TYPE.AUTODELEGATE
                        : RAW_TX_TYPE.DISABLE_AUTODELEGATE,
                    }),
                  )}
              />
            </Col>
          </Row>
          <Row>
            <Button
              disabled={pool.rewards.comparedTo(0) === 0}
              style={styles.withdraw}
              onClick={() => {
                sendAmplitudeData(strings.WITHDRAW_REWARDS);
                dispatch(
                  toggleUnstakeFundsModal({
                    ...pool,
                    operationType: RAW_TX_TYPE.WITHDRAW,
                  }),
                );
              }}
            >
              {' '}
              Withdraw Rewards
              {' '}
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
}
HomeStakeControls.propTypes = {
  autoStake: PropTypes.bool.isRequired,
  pool: PropTypes.object.isRequired,
};
export default HomeStakeControls;
