import React, { useState } from 'react';
import {
  Row, Col, Input, Select,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  text, sizes, elements, numbers,
} from '../../constants';
import Button from '../Button';
import Balance from '../Balance';
import { formatAddress, formatName } from '../../utils/utils';
import {
  sendRawTransactionPool,
  RAW_TX_TYPE,
} from '../../actions/rawTransaction';
import DataModel from '../../models/DataModel';
import TransactionStatus from './TransactionStatus';
import AionLogo from '../../assets/logos/icon.svg';
import Message from '../Message';

const styles = {
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
  },
  text: {
    ...text.BODY_LARGE,
    marginTop: sizes.SPACE_MEDIUM,
  },
  lable: {
    ...elements.INPUT_LABLE,
    marginTop: sizes.SPACE_SMALL,
  },
  entireBal: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: 14,
    color: '#000000',
  },
  input: {
    marginTop: '8px',
    marginBottom: '5px',
    color: '#1C1C1C',
    fontSize: '26px',
  },
  logo: {
    ...elements.TABLE.logo,
  },
  dropdown: {
    fontSize: '24px',
    width: '100%',
    marginTop: '8px',
    marginBottom: '5px',
  },
  dropdownItemTop: {
    color: '#1C1C1C',
    fontSize: '24px',
    fontWeight: '400',
  },
  dropdownItemBottom: {
    color: '#888888!important',
    fontSize: '14px',
  },
  address: {
    marginTop: sizes.SPACE_SMALL,
    fontSize: '26px',
    lineHeight: '30px',
    color: '#888888',
    display: 'block',
  },
  fees: {
    marginTop: sizes.SPACE_SMALL,
    fontSize: '18px',
    color: '#1C1C1C',
    display: 'block',
  },
  submit: {
    marginTop: sizes.SPACE_MEDIUM,
  },
  submitMobile: {
    width: '100%',
    marginTop: '50px',
  },
  successLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  successIcon: {
    width: '140px',
    height: '140px',
    marginBottom: sizes.SPACE_MEDIUM,
  },
  successBody: {
    fontSize: '18px',
    textAlign: 'center',
  },
  transferToPoolName: {
    color: '#1C1C1C',
    fontSize: '24px',
    fontWeight: '400',
  },
};

const copy = {
  [RAW_TX_TYPE.DELEGATE]: {
    title: (name) => `Staking to ${name}`,
    intent: () => 'I want to stake:',
    help: (amount, staked, setAmount) => (
      <>
        <Col>
          <Row justify="center">
            <Col>
              <Balance value={amount.data} type="small" />
            </Col>
            <Col>&nbsp;available</Col>
          </Row>
        </Col>
        <Col
          style={styles.entireBal}
          onClick={() =>
            setAmount(
              amount.data
                .minus(
                  numbers.BN_GAS_USAGE_SEND.times(
                    numbers.BN_GAS_PRICE,
                  ).dividedBy(numbers.BN_AION),
                )
                .toNumber(),
            )}
        >
          <b>Entire Balance</b>
        </Col>
      </>
    ),
    warn: (fees) => (
      <>
        This pool will charge you
        {' '}
        <b>
          {fees.toNumber()}
          %
        </b>
        {' '}
        of your rewards.
      </>
    ),
    action: 'Stake Aion',
    destination: (name) => `To ${name}`,
  },
  [RAW_TX_TYPE.UNDELEGATE]: {
    title: (name) => `Unstaking from ${name}`,
    intent: () => 'I want to unstake:',
    help: (amount, staked, setAmount) => (
      <>
        <Col>
          <Row justify="center">
            <Col>
              <Balance value={staked} type="small" />
            </Col>
            <Col>&nbsp;staked to this pool</Col>
          </Row>
        </Col>
        <Col
          style={styles.entireBal}
          onClick={() => setAmount(staked.toNumber())}
        >
          <b>Entire Stake</b>
        </Col>
      </>
    ),
    warn: (fees) => (
      <>
        This pool will charge you&nbsp;
        <b>
          {fees.toNumber()}
          %
        </b>
        {' '}
        of your rewards.
      </>
    ),
    destination: (name) => `From ${name}`,
    action: 'Unstake Aion',
  },
  [RAW_TX_TYPE.WITHDRAW]: {
    title: (name) => `Withdraw Rewards from ${name}`,
    intent: () => 'I want to withdraw:',
    forceInput: (staked) => staked.toNumber(),
    help: () => (
      <>
        <Col>
          <Row justify="center">
            <Col>You can only withdraw all your rewards</Col>
          </Row>
        </Col>
      </>
    ),
    warn: (fees) => (
      <>
        This pool will charge you&nbsp;
        <b>
          {fees.toNumber()}
          %
        </b>
        {' '}
        of your rewards.
      </>
    ),
    destination: (name) => `From ${name}`,
    action: 'Withdraw Rewards',
  },
  [RAW_TX_TYPE.AUTODELEGATE]: {
    title: (name) => `Enable Autostake to ${name}`,
    intent: (name) => (
      <span>
        Your rewards from
        {' '}
        {name}
        {' '}
        will automatically be transfered to your staked
        balance every week.
        <br />
        <br />
        You will be charged
        {' '}
        <b>0.00021</b>
        <img width="16px" height="16px" src={AionLogo} alt="logo" />
        {' '}
        just once
        for this transaction.
      </span>
    ),
    skipBody: true,
    action: 'Enable auto-stake',
  },
  [RAW_TX_TYPE.DISABLE_AUTODELEGATE]: {
    title: (name) => `Disable Autostake from ${name}`,
    intent: (name) => (
      <span>
        Your rewards from
        {' '}
        {name}
        {' '}
        will no longer be automatically transfered to
        your staked balance.
        <br />
        <br />
        You will be charged
        {' '}
        <b>0.00021</b>
        <img width="16px" height="16px" src={AionLogo} alt="logo" />
        {' '}
        just once
        for this transaction.
      </span>
    ),
    skipBody: true,
    action: 'Disable auto-stake',
  },
  [RAW_TX_TYPE.MOVE_STAKE]: {
    title: (name) => `Transfer to ${name}`,
    intent: () => 'I want to transfer:',
    hasFrom: true,
    from: (pools, defaultAddress, handleChange) => (
      <Select
        suffixIcon={<CaretDownOutlined style={{ color: '#0F0F0F' }} />}
        size="large"
        style={styles.dropdown}
        defaultValue={defaultAddress}
        onChange={(value) => {
          handleChange(value);
        }}
      >
        {pools.map((item) => (
          <Select.Option
            key={item}
            value={item.address}
            style={{ paddingTop: '7px' }}
          >
            <div style={styles.dropdownItemTop}>
              <img style={styles.logo} src={item.logo} alt="pool logo" />
              {' '}
              {formatName(item.name, 14)}
              {' '}
              (
              {formatAddress(item.address, 6, 6)}
              )
            </div>
            <Col style={styles.dropdownItemBottom}>
              <Row justify="start">
                <Col>
                  <Balance type="small" value={item.staked} />
                </Col>
                <Col>&nbsp;staked</Col>
              </Row>
            </Col>
          </Select.Option>
        ))}
      </Select>
    ),
    help: (amount, staked, setAmount) => (
      <>
        <Col>
          <Row justify="center">
            <Col>
              <Balance value={staked} type="small" />
            </Col>
            <Col>&nbsp;staked to this pool</Col>
          </Row>
        </Col>
        <Col
          style={styles.entireBal}
          onClick={() => {
            setAmount(staked.toNumber());
          }}
        >
          <b>Entire Stake</b>
        </Col>
      </>
    ),
    warn: (fees) => (
      <>
        This pool will charge you&nbsp;
        <b>
          {fees.toNumber()}
          %
        </b>
        {' '}
        of your rewards.
      </>
    ),
    destination: (name) => `To ${name}`,
    action: 'Transfer Stake',
    hasError: (amount, staked) => amount > staked
      ? 'You can’t transfer an amount greater than what you staked to this pool.'
      : false,
  },
};
const formatDelegations = (delegations, pools, poolLogos) => {
  const data = [];
  Object.keys(delegations.data).forEach((key) => {
    const delegation = delegations.data[key];
    const pool = pools.data?.find((p) => p.address === key);
    const logo = poolLogos.data?.find((l) => l.address === key)?.logo;
    const poolData = {
      key,
      logo,
      name: pool?.meta_name,
      status: 'active',
      staked: delegation.stake,
      fees: pool?.fee,
      performance: pool?.performance,
      address: pool?.address,
      url: pool?.meta_url,
      selfBond: pool?.stake_self,
      stakeWeight: pool?.stake_weight,
      rewards: delegation.rewards,
      capacity: pool?.capacity,
    };
    data.push({
      ...poolData,
      controls: {
        pool: poolData,
        autoStake: delegation.auto_delegate_rewards,
      },
    });
  });
  return data;
};
const UnstakeModal = ({ modalExtraData }) => {
  const {
    name,
    address,
    fees,
    operationType,
    staked,
    rewards,
  } = modalExtraData;
  const content = copy[operationType];
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mobile = !screens.md;
  const {
    rawTransaction,
    balance,
    poolLogos,
    account,
    delegations,
    pools,
  } = useSelector((state) => state);
  const logo = poolLogos.data?.find((l) => l.address === address)?.logo;
  const delegatedPools = formatDelegations(delegations, pools, poolLogos);
  const initialToSelection =
    RAW_TX_TYPE.MOVE_STAKE === operationType && delegatedPools.length > 0
      ? delegatedPools[0]
      : null;
  const [transferTo, setTransferTo] = useState(initialToSelection?.address);
  let inputValue = content?.forceInput?.(staked) ?? amount;
  if (operationType === RAW_TX_TYPE.WITHDRAW) {
    inputValue = rewards;
  }
  const validationWarning = content?.hasError?.(
    parseFloat(inputValue),
    transferTo
      ? delegatedPools.find((pool) => pool.address === transferTo).staked
      : staked,
  );
  return (
    <div>
      {DataModel.canFetch(rawTransaction) ? (
        <>
          <Row>
            <Col flex="auto" style={styles.title}>
              {content.title(name)}
            </Col>
          </Row>
          <div style={styles.text}>{content.intent(name)}</div>
          {!content.skipBody && (
            <>
              {content.hasFrom && (
                <>
                  <div style={styles.lable}>From</div>
                  {content.from(
                    delegatedPools,
                    initialToSelection.address,
                    setTransferTo,
                  )}
                </>
              )}
              <div style={styles.lable}>Amount</div>
              <Input
                disabled={content.forceInput?.(staked)}
                style={styles.input}
                size="large"
                type="number"
                placeholder=""
                value={inputValue}
                onChange={(e) => setAmount(e.target.value)}
              />
              <Row gutter={8}>
                {content.help(
                  balance,
                  transferTo
                    ? delegatedPools.find((pool) => pool.address === transferTo)
                      .staked
                    : staked,
                  setAmount,
                )}
              </Row>
              {validationWarning && <Message style={{ marginTop: '10px' }} type="error">{validationWarning}</Message>}
              <br />
              <div style={styles.lable}>{content.destination(name)}</div>
              <span style={styles.address}>
                <img style={styles.logo} src={logo} alt="pool logo" />
                {' '}
                {formatAddress(address, 13)}
              </span>
              <span style={styles.fees}>{content.warn(fees)}</span>
            </>
          )}

          <Row justify="end">
            <Button
              style={{ ...styles.submit, ...(mobile && styles.submitMobile) }}
              type="primary"
              size={mobile ? 'large' : 'middle'}
              onClick={() =>
                dispatch(
                  sendRawTransactionPool(
                    RAW_TX_TYPE.MOVE_STAKE === operationType
                      ? transferTo
                      : address,
                    inputValue,
                    operationType,
                    RAW_TX_TYPE.MOVE_STAKE === operationType
                      ? address
                      : transferTo,
                  ),
                )}
            >
              {content.action}
            </Button>
          </Row>
        </>
      ) : (
        <TransactionStatus
          rawTransaction={rawTransaction}
          account={account}
          name={name}
          address={
            RAW_TX_TYPE.MOVE_STAKE === operationType ? transferTo : address
          }
          operationType={operationType}
          amount={inputValue}
          toName={
            delegatedPools.find((pool) => pool.address === transferTo)?.name
          }
          toAddress={
            RAW_TX_TYPE.MOVE_STAKE === operationType ? address : transferTo
          }
        />
      )}
    </div>
  );
};

export default UnstakeModal;
