import React, { useState } from 'react';
import { Tag, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import glamorous from 'glamorous';
import { CaretDownFilled } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { elements } from '../constants';
import Balance from './Balance';
import { formatAionValue, formatAionValueToShort, hexColorForPerformance } from '../utils/utils';
import OptionsIcon from '../assets/icons/ic_options.svg';
import { togglePoolDetailsModal } from '../actions/modals';
import './PoolsList.less';
import PoolDetails from './PoolDetails';
import PoolName from './PoolName';

const styles = {
  ...elements.TABLE,
  root: {
    display: 'flex',
    position: 'relative',
  },
  tableRoot: {
    flexGrow: 1,
  },
  performance: glamorous.div((props) => ({
    '&::before': {
      ...elements.PERFORMANCE,
      background: hexColorForPerformance(props.performance),
    },
  })),
  expandButtonLayout: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  expandButton: {
    border: 'solid 1px',
    borderRadius: '2px',
    marginRight: '15px',
  },
  downBox: elements.DOWNBOX,
  downBoxSelected: {
    background: '#F2F2F2',
  },
  poolDetails: {
    border: '1px solid #ECECEC',
    boxSizing: 'border-box',
    borderRadius: '5px',
    margin: 0,
  },
  tag: {
    textTransform: 'uppercase',
  },
  logoText: {
    fontSize: 14,
  },
  poolName: {
    width: '200px',
    display: 'flex',
    alignItems: 'center',
  },
  filterButtonLayout: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    marginTop: '18px',
  },
};
const stylesMobile = {
  performance: glamorous.div((props) => ({
    '&::before': {
      ...elements.PERFORMANCE,
      background: hexColorForPerformance(props.performance),
    },
  })),
  expandButtonLayout: {},
  expandButton: {},
  downBox: elements.DOWNBOX,
  downBoxSelected: {
    background: '#F2F2F2',
  },
  poolDetails: {
    border: '1px solid #ECECEC',
    boxSizing: 'border-box',
    borderRadius: '5px',
    margin: 0,
  },
  tag: {
    textTransform: 'uppercase',
  },
  logoText: {
    fontSize: 10,
  },
  poolName: {
    width: '110px',
    textAlign: 'start',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ...elements.TABLE_MOBILE,
};
const columns = [
  (styleToUse) => ({
    title: 'Name',
    dataIndex: 'name',
    className: 'nameCell',
    sorter: {
      compare: (a, b) => a.name.text.localeCompare(b.name.text),
    },
    render: (value) => (
      <div
        style={{
          ...styleToUse.borderTopBottom,
          ...styleToUse.borderLeft,
        }}
      >
        <PoolName
          name={value.text}
          logo={value.logo}
          style={styleToUse.poolName}
        />
      </div>
    ),
  }),
  (styleToUse) => ({
    title: 'Status',
    dataIndex: 'status',
    render: (value) => (
      <div style={styleToUse.borderTopBottom}>
        {value ? (
          <Tag color="green">ACTIVE</Tag>
        ) : (
          <Tag color="red">INACTIVE</Tag>
        )}
      </div>
    ),
  }),
  (styleToUse) => ({
    title: 'Total Staked',
    dataIndex: 'staked',
    sorter: {
      compare: (a, b) => a.staked - b.staked,
    },
    render: (value) => (
      <div style={styleToUse.borderTopBottom}>
        <Balance
          type="medium"
          value={value}
          formatter={formatAionValueToShort}
        />
      </div>
    ),
  }),
  (styleToUse) => ({
    title: 'Self Bond',
    dataIndex: 'selfBond',
    sorter: {
      compare: (a, b) => a.selfBond - b.selfBond,
    },
    render: (value) => (
      <div style={styleToUse.borderTopBottom}>
        <Balance
          type="medium"
          value={value}
          formatter={formatAionValueToShort}
        />
      </div>
    ),
  }),
  (styleToUse) => ({
    title: 'R.Capacity',
    dataIndex: 'capacity',
    sorter: {
      compare: (a, b) => a.capacity - b.capacity,
    },
    render: (value) => (
      <div style={styleToUse.borderTopBottom}>
        <Balance
          type="medium"
          value={value}
          formatter={formatAionValueToShort}
        />
      </div>
    ),
  }),
  (styleToUse) => ({
    title: 'Stake-Weight',
    dataIndex: 'stakeWeight',
    sorter: {
      compare: (a, b) => a.stakeWeight - b.stakeWeight,
    },
    render: (value) => (
      <div style={styleToUse.borderTopBottom}>
        {`${formatAionValue(100 * value)}%`}
      </div>
    ),
  }),
  (styleToUse) => ({
    title: 'Fees',
    dataIndex: 'fees',
    sorter: {
      compare: (a, b) => a.fees - b.fees,
    },
    render: (value) => (
      <div
        style={{
          ...styleToUse.borderTopBottom,
        }}
      >
        {`${formatAionValue(value)}%`}
      </div>
    ),
  }),
  (styleToUse) => ({
    title: styleToUse === styles ? 'Performance' : 'Perf.',
    dataIndex: 'performance',
    sorter: {
      compare: (a, b) => a.performance.value - b.performance.value,
    },
    render: (value) => (
      <styleToUse.performance
        performance={value.value}
        style={{
          ...styleToUse.borderTopBottom,
          ...(value?.mobile ? styleToUse.borderRight : null),
        }}
      >
        {`${parseInt(value.value * 100, 10)}%`}
      </styleToUse.performance>
    ),
  }),
  (styleToUse) => ({
    title: '',
    dataIndex: 'expand',
    className: 'expand-col',
    render: () => <div style={styleToUse.expandButtonLayout} />,
  }),
];

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

function PoolsList() {
  const [expanded, setExpanded] = useState(false);
  const poolLogos = useSelector((state) => state.poolLogos);
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const mobile = !screens.md;
  const pools = useSelector((state) => state.pools)?.data?.map((pool, i) => {
    const logo = poolLogos.data?.find((l) => l.address === pool.address)?.logo;
    return {
      key: i,
      name: { logo, text: pool.meta_name },
      status: pool?.calculate_active,
      staked: pool.stake_total,
      selfBond: pool.stake_self,
      stakeWeight: pool.stake_weight,
      capacity: pool.capacity,
      fees: pool.fee,
      performance: { value: pool.performance, mobile },
      // used by details
      address: pool.address,
      url: pool.meta_url,
    };
  });
  let columnSet = columns;
  if (expanded) {
    columnSet = [
      columns[0](styles),
      columns[1](styles),
      columns[2](styles),
      columns[3](styles),
      columns[4](styles),
      columns[5](styles),
      columns[6](styles),
      columns[7](styles),
      columns[8](styles),
    ];
  } else if (!expanded && !mobile) {
    columnSet = [
      columns[0](styles), // name
      columns[1](styles), // status
      columns[6](styles), // fees
      columns[7](styles), // performance
      columns[8](styles), // expand column
    ];
  } else if (mobile) {
    columnSet = [
      columns[0](stylesMobile), // name
      columns[1](stylesMobile), // status
      columns[6](stylesMobile), // fees
      columns[7](stylesMobile), // performance
    ];
  }
  return (
    <div style={styles.root}>
      <Table
        style={styles.tableRoot}
        className="my-table"
        rowClassName="my-stake-row "
        columns={columnSet}
        dataSource={pools}
        onChange={onChange}
        pagination={false}
        loading={!pools || pools.loading}
        onRow={
          mobile
            ? (record, rowIndex) => ({
              onClick: (event) => {
                dispatch(
                  togglePoolDetailsModal({
                    pool: {
                      ...record,
                      logo: record.name.logo,
                      name: record.name.text,
                    },
                  }),
                );
              },
            })
            : null
        }
        expandable={
          !mobile && {
            expandRowByClick: true,
            expandedRowRender: (pool) => (
              <div style={styles.poolDetails}>
                <PoolDetails
                  pool={{ ...pool, logo: pool.name.logo, name: pool.name.text }}
                />
              </div>
            ),
            rowExpandable: () => true,
            expandIcon: ({ expanded, onExpand, record }) => (
              <div
                role="presentation"
                style={{
                  ...styles.borderTopBottom,
                  ...styles.borderRight,
                }}
                onKeyPress={(e) => onExpand(record, e)}
                onClick={(e) => onExpand(record, e)}
              >
                <CaretDownFilled
                  style={{
                    ...styles.downBox,
                    ...(expanded ? styles.downBoxSelected : null),
                  }}
                />
              </div>
            ),
          }
        }
        expandIconColumnIndex={columnSet.length - 1}
      />
      {!mobile && (
        <div
          style={styles.filterButtonLayout}
          onClick={() => setExpanded(!expanded)}
        >
          <img
            style={styles.expandButton}
            src={OptionsIcon}
            alt="expand columns"
          />
        </div>
      )}
    </div>
  );
}

export default PoolsList;
