import React from 'react';
import ReactDOM from 'react-dom';
import { compose, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import persistState from 'redux-localstorage';
import App from './App';
import * as serviceWorker from './serviceWorker';
import SocketService from './utils/SocketService';
import DataHandler from './utils/DataHandler';
import rootReducer from './reducers/index';
import { fetchPools } from './actions/pools';
import { fetchDataIfAccountExists } from './actions/account';
import { initAmplitude } from './utils/Amplitude';

import './index.less';
import ApiService from './utils/ApiService';

// Handlers: Parse and format data returned from the services
const dataHandler = DataHandler.getInstance();

// Services: Are the data source for this application, hence are singletons
const socketService = SocketService.getInstance(
  process.env.REACT_APP_WS_URL,
  dataHandler,
);
const apiService = ApiService.getInstance(
  process.env.REACT_APP_HTTPS_URL,
  process.env.REACT_APP_HTTPS_URL_ACCOUNT,
  dataHandler,
);

// Services and Handlers should be assesed only from the state object
const initServices = () => ({
  dataHandler,
  socketService,
  apiService,
});

// initalizing amplitude for page analytics.
initAmplitude();

// Use redux dev tools is available
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer =
  process.env.REACT_APP_PERSIST_ACCOUNT === 'true'
    ? composeEnhancers(
      persistState(['account'], { key: 'state' }),
      applyMiddleware(thunkMiddleware.withExtraArgument(initServices())),
    )
    : composeEnhancers(
      applyMiddleware(thunkMiddleware.withExtraArgument(initServices())),
    );

const store = createStore(rootReducer, {}, enhancer);

// Handler updates the application state as responses come in
dataHandler.setStore(store);
dataHandler.setApiService(apiService);

// Fetch pools data. Needed to render initial UI
store.dispatch(fetchPools());

// load account data if account persists
if (process.env.REACT_APP_PERSIST_ACCOUNT === 'true') {
  store.dispatch(fetchDataIfAccountExists());
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

socketService.runWS();
