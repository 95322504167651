import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContentLoader from 'react-content-loader';
import BigNumber from 'bignumber.js';
import { Redirect } from 'react-router-dom';
import HomeTransactions from '../components/HomeTransactions';
import TransactionsHeading from '../components/TransactionsHeading';
import { fetchAccountTransactions } from '../actions/accountTransactions';
import { fetchStakingTransactions } from '../actions/stakingTransactions';
import EmptyState from '../components/EmptyState';
import { toggleReceiveFundsModal } from '../actions/modals';
import { sendAmplitudeData } from '../utils/Amplitude';
import { strings } from '../constants';

export default function MyTransactions() {
  const [redirect, setRedirect] = useState();
  const account = useSelector((state) => state.account);
  const pools = useSelector((state) => state.pools);
  const balance = useSelector((state) => state.balance);
  const stakedBalance = useSelector((state) => state.stakedBalance);
  const nonce = useSelector((state) => state.nonce);
  const dispatch = useDispatch();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      {sendAmplitudeData(strings.VIEW_TRANSACTIONS)}
      {!pools.data && pools.loading && (
        <div>
          <ContentLoader viewBox="0 0 100 80">
            <rect x="0" y="0" rx="1" ry="1" width="10" height="3" />
            <rect x="0" y="4" rx="1" ry="1" width="66" height="2" />
            <rect x="0" y="7" rx="1" ry="1" width="66" height="2" />
            <rect x="0" y="10" rx="1" ry="1" width="66" height="2" />
            <rect x="67" y="0" rx="1" ry="1" width="33" height="12" />
            <rect x="0" y="15" rx="2" ry="2" width="100" height="80" />
          </ContentLoader>
        </div>
      )}
      {account && pools.data && (
        <div>
          <TransactionsHeading
            title="Transactions"
            address={account.address}
            nonce={nonce}
            balance={
              balance?.data?.plus(stakedBalance?.data) ?? new BigNumber(0)
            }
          />
          <br />
          <HomeTransactions
            selectorAccount="account"
            showPages
            showStakingPages
            selectorWalletTransactions="accountTransactions"
            walletTransactionsSource={(page) => dispatch(fetchAccountTransactions(page))}
            transactionsEmptyLayout={(
              <EmptyState
                title="You don’t have any transactions yet"
                description="Deposit or receive AION to your account to get started"
                buttonText="Start Receiving Aion"
                onClick={() => {
                  sendAmplitudeData(strings.RECEIVE_AION);
                  dispatch(toggleReceiveFundsModal());}}
              />
            )}
            selectorStakingTransactions="stakingTransactions"
            stakingTransactionsSource={(page) =>
              dispatch(fetchStakingTransactions(page))}
            stakingTransactionsEmptyLayout={(
              <EmptyState
                title="You’re not currently staking to any pools"
                description="Stake your AION to get started"
                buttonText="Start Staking"
                onClick={() => {
                  sendAmplitudeData(strings.START_STAKING);
                  setRedirect('/staking?tab=2');}}
              />
              )}
          />
        </div>
      )}
    </div>
  );
}
