import { ACCOUNT_RECENT_TRANSACTIONS_UPDATE } from '../actions/accountRecentTransactions';
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

const initialState = new DataModel();

export default ReduxUtils.createReducer(initialState, {
  ...ReduxUtils.createObjectHandler(
    ACCOUNT_RECENT_TRANSACTIONS_UPDATE,
    'accountRecentTransactions',
  ),
});
