import {
  DELEGATIONS_UPDATE,
  STAKED_BALANCE_UPDATE,
  REWARDS_BALANCE_UPDATE,
} from '../actions/delegations';
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

const initialDelegationsState = new DataModel();
export const delegations = ReduxUtils.createReducer(initialDelegationsState, {
  ...ReduxUtils.createObjectHandler(DELEGATIONS_UPDATE, 'delegations'),
});

const initialStakedBalanceState = new DataModel();
export const stakedBalance = ReduxUtils.createReducer(
  initialStakedBalanceState,
  {
    ...ReduxUtils.createObjectHandler(STAKED_BALANCE_UPDATE, 'stakedBalance'),
  },
);

const initialRewardsBalanceState = new DataModel();
export const rewardsBalance = ReduxUtils.createReducer(
  initialRewardsBalanceState,
  {
    ...ReduxUtils.createObjectHandler(REWARDS_BALANCE_UPDATE, 'rewardsBalance'),
  },
);
