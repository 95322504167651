import { SEARCHED_ACCOUNT_TRANSACTIONS } from '../actions/searchedAccountTransactions';
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

const initialState = new DataModel();

export default ReduxUtils.createReducer(initialState, {
  ...ReduxUtils.createObjectHandler(
    SEARCHED_ACCOUNT_TRANSACTIONS,
    'searchedAccountTransactions',
  ),
});
