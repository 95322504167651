import React from 'react';
import Button from '../../Button';
import { text, sizes } from '../../../constants';
import ErrorIcon from '../../../assets/icons/ic_exclamation_error_filled.svg';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 360,
  },
  icon: {
    width: 56,
    height: 56,
    marginBottom: sizes.SPACE_SMALL,
  },
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
    marginBottom: sizes.SPACE_SMALL,
  },
  error: {
    fontSize: 18,
    lineHeight: '21px',
    marginBottom: sizes.SPACE_MEDIUM,
    background: '#FFEDEB',
    padding: '3px 6px',
  },
  text: {
    fontSize: 18,
    lineHeight: '21px',
    marginBottom: sizes.SPACE_SMALL,
  },
};

const LedgerError = ({ next }) => (
  <div>
    <div style={styles.title}>Ledger Error</div>
    <div style={styles.root}>
      <img style={styles.icon} src={ErrorIcon} alt="exclamation" />
      <div style={styles.error}>We couldn’t connect to your Ledger Wallet.</div>
      <div style={styles.text}>Please reconnect your device and try again.</div>
      <Button type="primary" onClick={next}>
        Try Again
      </Button>
    </div>
  </div>
);

export default LedgerError;
