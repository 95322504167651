import React from 'react';
import { sizes, text } from '../../constants';

const styles = {
  title: { marginBottom: sizes.SPACE_MEDIUM, ...text.TITLE_MEDIUM },
};
const AccountCopyModal = () => (
  <div>
    <div style={styles.title}>Address Copied!</div>
    <div>The address was copied to your clipboard.</div>
  </div>
);

export default AccountCopyModal;
