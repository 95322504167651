/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-async-promise-executor */
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

export const POOL_LOGOS_UPDATE = 'POOL_LOGOS_UPDATE';

export const updatePoolLogos = ReduxUtils.createAction(
  POOL_LOGOS_UPDATE,
  'poolLogos',
);

export const fetchPoolLogos = (poolAddresses) => async (
  dispatch,
  getState,
  { apiService },
) => {
  dispatch(updatePoolLogos(DataModel.initLoading()));
  try {
    const promArr = poolAddresses.map(
      (addr) =>
        new Promise(async (resolve, reject) => {
          const res = await apiService.fetchPoolLogo(addr);
          if (res.error) {
            reject(res.error);
          }
          resolve({ address: addr, logo: res.result });
        }),
    );
    const logos = await Promise.all(promArr);
    dispatch(updatePoolLogos(new DataModel(logos)));
  } catch (ex) {
    console.log(ex);
    dispatch(updatePoolLogos(DataModel.error(0, ex.message)));
  }
};
