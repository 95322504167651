import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { text, sizes } from '../../constants';
import Button from '../Button';
import { formatAddress, formatAddressEnd } from '../../utils/utils';
import {
  updateRawTransaction,
  RAW_TX_TYPE,
} from '../../actions/rawTransaction';
import { closeAllModals } from '../../actions/modals';
import IconChecked from '../../assets/icons/ic_check_filled.svg';
import IconError from '../../assets/icons/ic_close_error_filled.svg';
import AionLogo from '../../assets/logos/icon.svg';
import DataModel from '../../models/DataModel';
import LedgerConfirm from '../../assets/logos/ledger_confirm.png';

const styles = {
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
  },
  spinner: {
    fontSize: '110px',
    color: '#CFCFCF',
  },
  successLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: sizes.SPACE_MEDIUM,
    paddingRight: sizes.SPACE_MEDIUM,
  },
  success: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  successIcon: {
    width: '140px',
    height: '140px',
    marginBottom: sizes.SPACE_MEDIUM,
  },
  successBody: {
    fontSize: '18px',
    textAlign: 'center',
  },
  spinnerLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ledgerWaitingText: {
    marginTop: sizes.SPACE_LARGE,
    color: '#1C1C1C',
    fontSize: '14px',
  },
  ledgerInfo: {
    color: '#1C1C1C',
    fontSize: '18px',
  },
  ledgerImage: {
    marginTop: sizes.SPACE_LARGE,
    width: '100%',
  },
  aionImage: {
    width: '16px',
    height: '16px',
    marginTop: '-2px',
  },
};

const copy = {
  [RAW_TX_TYPE.DELEGATE]: {
    successTitle: 'Aion successfully staked',
    successBody: (amount) => (
      <>
        You successfully staked
        {' '}
        <b>{amount}</b>
        <img style={styles.aionImage} src={AionLogo} alt="logo" />
        {' '}
      </>
    ),
    successExtra: (
      name,
      address,
      userAddress,
    ) => `to ${name} (${formatAddressEnd(address)}) from your Wallet address
      (${formatAddress(userAddress)}) `,
  },
  [RAW_TX_TYPE.UNDELEGATE]: {
    successTitle: 'Aion successfully unstaked',
    successBody: (amount) => (
      <>
        You successfully unstaked
        {' '}
        <b>{amount}</b>
        <img style={styles.aionImage} src={AionLogo} alt="logo" />
        {' '}
      </>
    ),
    successExtra: (
      name,
      address,
      userAddress,
    ) => `from ${name} (${formatAddressEnd(address)}) to your Wallet address
      (${formatAddress(userAddress)}) `,
  },
  [RAW_TX_TYPE.WITHDRAW]: {
    successTitle: 'Aion successfully withdrawn',
    successBody: (amount) => (
      <>
        You successfully withdrawn
        {' '}
        <b>{amount?.toNumber() ?? `${amount}`}</b>
        {' '}
        <img style={styles.aionImage} src={AionLogo} alt="logo" />
        {' '}
        in rewards
      </>
    ),
    successExtra: (
      name,
      address,
      userAddress,
    ) => `from ${name} (${formatAddressEnd(address)}) 
      (${formatAddress(userAddress)}) `,
  },
  [RAW_TX_TYPE.AUTODELEGATE]: {
    successTitle: 'Autostake Enabled',
    successBody: (amount) => <>You successfully setup autostake of your</>,
    successExtra: (name, address, userAddress) =>
      `rewards from ${name} (${formatAddressEnd(address)})`,
  },
  [RAW_TX_TYPE.DISABLE_AUTODELEGATE]: {
    successTitle: 'Autostake Disabled',
    successBody: (amount) => <>You successfully disabled autostake of your</>,
    successExtra: (name, address, userAddress) =>
      `rewards from ${name} (${formatAddressEnd(address)})`,
  },
  [RAW_TX_TYPE.MOVE_STAKE]: {
    successTitle: 'Aion successfully transfered',
    successBody: (amount) => (
      <>
        You successfully transfered
        <b>{amount}</b>
        {' '}
        <img style={styles.aionImage} src={AionLogo} alt="logo" />
        {' '}
        from
      </>
    ),
    successExtra: (name, address, userAddress, toName, toAddress) =>
      ` ${name} (${formatAddressEnd(address)}) to ${toName} (${formatAddressEnd(
        toAddress,
      )}) `,
  },
  [RAW_TX_TYPE.SEND]: {
    successTitle: 'Aion successfully sent',
    successBody: (amount) => (
      <>
        You successfully sent
        {' '}
        <b>{amount}</b>
        {' '}
        <img style={styles.aionImage} src={AionLogo} alt="logo" />
        {' '}
      </>
    ),
    successExtra: (name, address, userAddress) =>
      `to ${formatAddressEnd(address)}`,
  },
};
const TransactionStatus = ({
  rawTransaction,
  account,
  name,
  address,
  operationType,
  amount,
  toName,
  toAddress,
}) => {
  const content = copy[operationType];
  const waitingForLedgerSign = account.type === 'ledger';
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mobile = !screens.md;
  const [copyButtonText, setCopyButtonText] = useState('COPY TRANSACTION HASH');
  return (
    <div style={styles.successLayout}>
      {rawTransaction.error && (
        <>
          <img style={styles.successIcon} src={IconError} alt="error" />
          <div style={styles.title}>Transaction Failed</div>
          <br />
          <span>{rawTransaction.error.message}</span>
          <br />
          <Row justify="center" align="middle">
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  dispatch(closeAllModals());
                  dispatch(updateRawTransaction(new DataModel()));
                }}
              >
                Try Again
              </Button>
            </Col>
          </Row>
        </>
      )}
      {rawTransaction.loading && (
        waitingForLedgerSign ? (
          <div>
            <div style={styles.title}>Confirm your transaction</div>
            <img style={styles.ledgerImage} src={LedgerConfirm} alt="confirm on ledger" />
            <span style={styles.ledgerInfo}>Confirm your transaction on your Ledger Device</span>
            <div style={styles.ledgerWaitingText}>
              <Spin />
              {' '}
              Waiting for confirmation on Ledger
            </div>
          </div>
        ) : (
          <div style={styles.spinnerLayout}>
            {' '}
            <Spin />
            <br />
            <div>We&apos;re sending your transaction to the network...</div>
          </div>
        )

      )}
      {rawTransaction.data && (
        <div style={styles.success}>
          <img style={styles.successIcon} src={IconChecked} alt="success" />
          <div style={styles.title}>{content.successTitle}</div>
          <br />
          <span style={styles.successBody}>
            {content.successBody(amount)}
            &nbsp;
            {content.successExtra(
              name,
              address,
              account.address,
              toName,
              toAddress,
            )}
          </span>
          <br />
          <Row justify={mobile ? 'center' : 'space-between'}>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  dispatch(closeAllModals(false));
                  dispatch(updateRawTransaction(new DataModel()));
                }}
              >
                Got it!
              </Button>
            </Col>
            <Col>
              <CopyToClipboard
                text={rawTransaction.data}
                onCopy={() => {
                  setCopyButtonText('Copied!');
                  setTimeout(() => setCopyButtonText('COPY TRANSACTION HASH'), 3000);
                }}
              >
                <Button style={{ minWidth: '273px' }} type="link"><b>{copyButtonText}</b></Button>
              </CopyToClipboard>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

TransactionStatus.propTypes = {
  rawTransaction: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  operationType: PropTypes.string.isRequired,
  name: PropTypes.string,
  address: PropTypes.string,
  amount: PropTypes.number,
};

export default TransactionStatus;
