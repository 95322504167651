import React from 'react';
import ErrorIcon from '../assets/icons/ic_exclamation_error_filled.svg';
import WarningIcon from '../assets/icons/ic_exclamation_warning_filled.svg';

const styles = {
  root: {
    display: 'flex',
    fontSize: '16px',
    alignItems: 'center',
    color: '#000000',
    padding: '3px',
  },
  icon: {
    width: '21px',
    height: '21px',
    margin: '0 5px 0 15px',
  },
  error: {
    background: '#FFEDEB',
    borderRadius: '3px',
  },
  warning: {
    background: '#FFFCE9',
    borderRadius: '3px',
  },
};
const Message = ({ style = {}, type, children }) => {
  let icon;
  let elementStyle;
  switch (type) {
    case 'warning': {
      icon = WarningIcon;
      elementStyle = styles.warning;
      break;
    }
    default: {
      icon = ErrorIcon;
      elementStyle = styles.error;
    }
  }
  return (
    <div style={{ ...styles.root, ...elementStyle, ...style }}>
      <img style={styles.icon} src={icon} alt="icon" />
      <div>{children}</div>
    </div>
  );
};

export default Message;
