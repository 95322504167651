import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

export const BALANCE_UPDATE = 'BALANCE_UPDATE';

export const updateBalance = ReduxUtils.createAction(BALANCE_UPDATE, 'balance');

export const fetchBalance = () => async (
  dispatch,
  getState,
  { socketService },
) => {
  const { account, balance } = getState();
  dispatch(updateBalance(balance.data ? new DataModel(balance.data) : DataModel.initLoading()));
  try {
    socketService.wsSend({
      method: 'eth_getBalance',
      params: [account.address],
    });
  } catch (ex) {
    dispatch(updateBalance(DataModel.error(0, ex.message)));
  }
};
