import React from 'react';
import { Layout } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sizes, decoration, strings } from '../constants';
import { formatAddressEnd } from '../utils/utils';
import Button from './Button';
import { toggleLoginModal, toggleAccountModal } from '../actions/modals';
import MyAionLogo from '../assets/logos/myAionLogo.svg';
import SearchInput from './SearchInput';
import { sendAmplitudeData } from '../utils/Amplitude';

const { Header } = Layout;
const styles = {
  root: {
    position: 'fixed',
    width: '100vw',
    height: sizes.NAV_HEIGHT,
    padding: '0 24px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 999,
  },
  appTitle: {
    height: 30,
    marginLeft: '20px',
  },
  buttonsLayout: {
    display: 'flex',
  },
  actionButton: {
    marginLeft: '20px',
  },
  appSearchWrapper: {
    marginLeft: '110px',
    flexGrow: 1,
  },
  appSearch: {
    ...decoration.SHADOW_SMALL,
    maxWidth: '550px',
  },
};

export default function NavBar() {
  const history = useHistory();
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const showSearch = account || location.pathname !== '/home';
  const dispatch = useDispatch();
  return (
    <Header theme="light" style={styles.root}>
      <div style={{ cursor: 'pointer' }} onClick={() => history.push('/home')}>
        <img style={styles.appTitle} src={MyAionLogo} alt="my aion logo" />
      </div>
      {showSearch && (
      <div style={styles.appSearchWrapper}>
        <SearchInput />
      </div>
      )}
      <div style={styles.buttonsLayout}>
        <Button
          size="large"
          type="link"
          onClick={() => history.push('/all-pools')}
        >
          All Pools
        </Button>
        <Button
          size="large"
          onClick={() =>
            window.open(
              'https://aionnetwork.atlassian.net/servicedesk/customer/portal/9',
            )}
          type="link"
        >
          Contact
        </Button>
        <Button
          size="large"
          type="link"
          onClick={() => window.open('https://docs-aion.theoan.com/docs')}
        >
          Docs
        </Button>
        {account ? (
          <Button
            style={styles.actionButton}
            size="large"
            type="primary"
            onClick={() => {
              sendAmplitudeData(strings.LOGIN_OPENED);
              dispatch(toggleAccountModal());
            }}
          >
            <span>{formatAddressEnd(account.address)}</span>
            <CaretDownOutlined />
          </Button>
        ) : (
          <Button
            onClick={() => {
              sendAmplitudeData(strings.LOGIN_OPENED);
              dispatch(toggleLoginModal());
            }}
            style={styles.actionButton}
            size="large"
            type="primary"
          >
            Sign In
          </Button>
        )}
      </div>
    </Header>
  );
}
