import React from 'react';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const styles = {
  poolName: {
    width: '200px',
    display: 'flex',
    alignItems: 'center',
  },
  logoText: {
    fontSize: 14,
  },
  logo: {
    width: '32px',
    height: '32px',
    marginRight: '5px',
  },
  logoMobile: {
    width: '28px',
    height: '28px',
    marginRight: '3px',
  },
};
const PoolName = ({ logo, name, style }) => {
  const screens = useBreakpoint();
  const mobile = !screens.xl;
  return (
    <div style={{ ...style }}>
      {logo ? (
        <img style={mobile ? styles.logoMobile : styles.logo} src={logo} alt="pool logo" />
      ) : (
        <span style={styles.logoText}>
          No
          <br />
          Image
        </span>
      )}
      {name || <span>No name found</span>}
    </div>
  );
};
export default PoolName;
