import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  toggleAccountModal,
  toggleLoginModal,
  toggleAddressCopyConfirmation,
  togglePoolDetailsModal,
  closeAllModals,
} from '../actions/modals';

import ReceiveModal from './modals/ReceiveModal';
import SendModal from './modals/SendModal';
import UnstakeModal from './modals/UnstakeModal';
import AccountModal from './modals/AccountModal';
import CloseIcon from '../assets/icons/ic_close_alt.svg';
import AccountCopyModal from './modals/AccountCopyModal';
import LoginModal from './modals/LoginModal';
import PoolDetailsModal from './modals/PoolDetailsModal';

import './ModalsPortal.less';
import DataModel from '../models/DataModel';
import { updateRawTransaction } from '../actions/rawTransaction';

const styles = {
  root: {
    padding: '60px 40px',
  },
  rootMobile: {
    padding: '30px 20px',
  },
  closeIcon: {
    marginTop: '60px',
    marginRight: '40px',
  },
  closeIconMobile: {
    marginTop: '30px',
    marginRight: '10px',
  },
};

export default function ComposeModal() {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mobile = !screens.md;
  const {
    showReceiveFundsModal,
    showSendFundsModal,
    showUnstakeFundsModal,
    showAccountModal,
    showAddressCopyModal,
    showPoolDetailsModal,
    modalExtraData,
    showLoginModal,
  } = useSelector((state) => state.modals);
  let dialogData;
  const commonClose = () => {
    dispatch(closeAllModals());
    dispatch(updateRawTransaction(new DataModel()));
  };
  if (showReceiveFundsModal) {
    dialogData = {
      show: showReceiveFundsModal,
      onOk: commonClose,
      onCancel: commonClose,
      component: ReceiveModal,
    };
  } else if (showSendFundsModal) {
    dialogData = {
      show: showSendFundsModal,
      onOk: commonClose,
      onCancel: commonClose,
      component: SendModal,
    };
  } else if (showUnstakeFundsModal) {
    dialogData = {
      show: showUnstakeFundsModal,
      onOk: commonClose,
      onCancel: commonClose,
      component: UnstakeModal,
    };
  } else if (showAccountModal) {
    dialogData = {
      show: showAccountModal,
      onOk: toggleAccountModal(),
      onCancel: toggleAccountModal(),
      component: AccountModal,
      dialogProps: { width: 'min-content' },
    };
  } else if (showLoginModal) {
    dialogData = {
      show: showLoginModal,
      onOk: toggleLoginModal(),
      onCancel: toggleLoginModal(),
      component: LoginModal,
      dialogProps: { width: 'min-content' },
    };
  } else if (showAddressCopyModal) {
    dialogData = {
      show: showAddressCopyModal,
      onOk: toggleAddressCopyConfirmation(),
      onCancel: toggleAddressCopyConfirmation(),
      component: AccountCopyModal,
    };
  } else if (showPoolDetailsModal) {
    dialogData = {
      show: showPoolDetailsModal,
      onOk: togglePoolDetailsModal(),
      onCancel: togglePoolDetailsModal(),
      component: PoolDetailsModal,
    };
  }// else add other dialog types here
  return (
    <>
      {dialogData?.show && (
        <Modal
          className="my-modal"
          bodyStyle={mobile ? styles.rootMobile : styles.root}
          visible={dialogData.show}
          onOk={() => dispatch(dialogData.onOk)}
          onCancel={() => dispatch(dialogData.onCancel)}
          footer={null}
          closeIcon={
            <img style={mobile ? styles.closeIconMobile : styles.closeIcon} src={CloseIcon} alt="close" />
          }
          {...(mobile ? { width: '100%' } : dialogData.dialogProps)}
        >
          <dialogData.component modalExtraData={modalExtraData} />
        </Modal>
      )}
    </>
  );
}
