import React from 'react';
import PropTypes from 'prop-types';

function IconCloseAlt(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="21"
      viewBox="1 -5 20 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.8782 11.9989L19.606 6.28357C19.8568 6.03271 19.9977 5.69246 19.9977 5.33769C19.9977 4.98291 19.8568 4.64266 19.606 4.3918C19.3552 4.14093 19.015 4 18.6602 4C18.3055 4 17.9653 4.14093 17.7145 4.3918L12 10.1204L6.28552 4.3918C6.03469 4.14093 5.6945 4 5.33977 4C4.98504 4 4.64485 4.14093 4.39402 4.3918C4.14319 4.64266 4.00227 4.98291 4.00227 5.33769C4.00227 5.69246 4.14319 6.03271 4.39402 6.28357L10.1218 11.9989L4.39402 17.7142C4.26917 17.838 4.17007 17.9853 4.10244 18.1477C4.03482 18.31 4 18.4842 4 18.66C4 18.8359 4.03482 19.01 4.10244 19.1724C4.17007 19.3347 4.26917 19.4821 4.39402 19.6059C4.51785 19.7308 4.66517 19.8299 4.8275 19.8975C4.98982 19.9652 5.16392 20 5.33977 20C5.51562 20 5.68972 19.9652 5.85204 19.8975C6.01437 19.8299 6.16169 19.7308 6.28552 19.6059L12 13.8773L17.7145 19.6059C17.8383 19.7308 17.9856 19.8299 18.148 19.8975C18.3103 19.9652 18.4844 20 18.6602 20C18.8361 20 19.0102 19.9652 19.1725 19.8975C19.3348 19.8299 19.4822 19.7308 19.606 19.6059C19.7308 19.4821 19.8299 19.3347 19.8976 19.1724C19.9652 19.01 20 18.8359 20 18.66C20 18.4842 19.9652 18.31 19.8976 18.1477C19.8299 17.9853 19.7308 17.838 19.606 17.7142L13.8782 11.9989Z" fill={color} />

    </svg>
  );
}

IconCloseAlt.propTypes = {
  color: PropTypes.string.isRequired,
};

export default IconCloseAlt;
