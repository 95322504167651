import { ACCOUNT_TRANSACTIONS_UPDATE } from '../actions/accountTransactions';
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

const initialState = new DataModel();

export default ReduxUtils.createReducer(initialState, {
  ...ReduxUtils.createObjectHandler(
    ACCOUNT_TRANSACTIONS_UPDATE,
    'accountTransactions',
  ),
});
