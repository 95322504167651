import React from 'react';
import PropTypes from 'prop-types';

function IconCheckAlt(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 -5 20 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.26348 10.5734H4.26317H2.62489C2.25933 10.5734 2.05537 10.9945 2.28092 11.2806L2.281 11.2807L8.70053 19.4135L8.70061 19.4136C9.10109 19.9204 9.8692 19.9192 10.2712 19.4143L10.2719 19.4135L21.7174 4.90976C21.7177 4.90939 21.718 4.90901 21.7183 4.90864C21.9478 4.62134 21.7359 4.20312 21.3749 4.20312H19.7366C19.431 4.20312 19.1401 4.34321 18.9516 4.58485C18.9514 4.58504 18.9513 4.58522 18.9511 4.5854L9.48507 16.577L5.04785 10.9547L4.85145 11.1094L5.0477 10.9545L5.04782 10.9546C4.95436 10.836 4.83525 10.7401 4.69941 10.674C4.56357 10.608 4.41452 10.5736 4.26348 10.5734Z" fill={color} />

    </svg>
  );
}

IconCheckAlt.propTypes = {
  color: PropTypes.string.isRequired,
};

export default IconCheckAlt;
