import React from 'react';
import PropTypes from 'prop-types';

function IconHome(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 20.332V15.2476C13 14.4385 12.6839 13.6626 12.1213 13.0905C11.5587 12.5184 10.7956 12.197 10 12.197C9.20435 12.197 8.44129 12.5184 7.87868 13.0905C7.31607 13.6626 7 14.4385 7 15.2476V20.332H2C1.46957 20.332 0.960859 20.1177 0.585786 19.7363C0.210714 19.3549 0 18.8376 0 18.2982V7.2468C-3.32274e-06 6.8956 0.0894306 6.55038 0.259593 6.24476C0.429755 5.93914 0.674846 5.68354 0.971 5.50285L8.971 0.621831C9.28183 0.432181 9.63751 0.332001 10 0.332001C10.3625 0.332001 10.7182 0.432181 11.029 0.621831L19.029 5.50285C19.3252 5.68354 19.5702 5.93914 19.7404 6.24476C19.9106 6.55038 20 6.8956 20 7.2468V18.2982C20 18.8376 19.7893 19.3549 19.4142 19.7363C19.0391 20.1177 18.5304 20.332 18 20.332H13Z"
        fill={color}
      />
    </svg>
  );
}

IconHome.propTypes = {
  color: PropTypes.string.isRequired,
};

export default IconHome;
