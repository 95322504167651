import React from 'react';
import { Grid, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  text, sizes, elements, strings,
} from '../constants';
import SearchWalletSection from '../components/SearchWalletSection';
import SearchTransactionHashSection from '../components/SearchTransactionHashSection';
import SearchError from '../components/SearchError';
import IconBack from '../assets/icons/ic_back.svg';
import HomeTransactions from '../components/HomeTransactions';
import { transactionsRequest } from '../actions/searchedAccountTransactions';
import { stakingTransactionsRequest } from '../actions/searchedStakingTransactions';
import EmptyState from '../components/EmptyState';
import SearchInput from '../components/SearchInput';

const { useBreakpoint } = Grid;

const styles = {
  mobileTitle: {
    ...text.TITLE_MEDIUM,
    marginBottom: sizes.SPACE_MEDIUM,
  },
  appTitle: {
    ...text.TITLE_LARGE,
    flexGrow: '1',
    flexDirection: 'row',
  },
  appHeading: {
    ...text.HEADING_LARGE,
    marginTop: sizes.SPACE_MEDIUM,
    flexGrow: '1',
    flexDirection: 'row',
  },
  headingContainer: {
    flexGrow: '1',
    flexDirection: 'row',
    display: 'flex',
    paddingBottom: sizes.SPACE_SMALL,
  },
  cardHeading: {
    ...text.HEADING_MEDIUM,
    flexGrow: '1',
    marginLeft: sizes.SPACE_SMALL,
    marginRight: sizes.SPACE_SMALL,
    marginBottom: sizes.SPACE_SMALL,
  },
  textLabel: {
    ...text.BODY,
    flexGrow: '1',
    width: '10%',
    flexDirection: 'row',
    marginLeft: sizes.SPACE_SMALL,
    marginRight: sizes.SPACE_SMALL,
    marginTop: sizes.SPACE_SMALL,
    color: 'gray', // TODO Color code needed from Mishaal
    backgroundColor: 'white',
  },
  walletCard: {
    ...elements.CARD,
    marginBottom: sizes.SPACE_MEDIUM,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'initial',
    padding: '0',
    width: 'max-content',
  },
  walletCardMobile: {
    ...elements.CARD,
    marginBottom: sizes.SPACE_MEDIUM,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'initial',
    padding: '0',
    width: '100%',
  },
  cardBody: {
    padding: '8px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    overflowWrap: 'anywhere',
    cursor: 'pointer',
  },
  backIcon: {
    width: '15px',
    height: '15px',
    marginRight: '5px',
  },
};

export default function Search({ match }) {
  const history = useHistory();
  const searchQuery = match?.params?.slug ?? '';
  const isAccount = RegExp(strings.walletRegex).test(searchQuery);
  const isTransaction = RegExp(strings.transactionRegex).test(searchQuery);
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const mobile = !screens.md;
  console.log({ isAccount, isTransaction });
  return (
    <>
      {mobile ? (
        <div>
          <div style={styles.mobileTitle}>Search</div>
          <SearchInput />
          <br />
        </div>
      ) : (
        <></>
      )}

      {history?.location?.state?.back && (
        <Card
          bodyStyle={styles.cardBody}
          style={mobile ? styles.walletCardMobile : styles.walletCard}
          onClick={() => history.goBack()}
        >
          <img style={styles.backIcon} src={IconBack} alt="back" />
          <span>
            Back&nbsp;to&nbsp;
            {history.location.state.back}
          </span>
        </Card>
      )}

      {isAccount && (
        <div>
          <SearchWalletSection accountAddress={searchQuery} />
          <div style={styles.appHeading}>Transactions</div>
          <HomeTransactions
            selectorAccount="searchedAccount"
            showPages
            showStakingPages
            selectorWalletTransactions="searchedAccountTransactions"
            walletTransactionsSource={(page) => dispatch(transactionsRequest(page))}
            transactionsEmptyLayout={(
              <EmptyState
                title="No transaction data found."
                description="No transaction data found here."
              />
)}
            selectorStakingTransactions="searchedStakingTransactions"
            stakingTransactionsSource={(page) =>
              dispatch(stakingTransactionsRequest(page))}
            stakingTransactionsEmptyLayout={(
              <EmptyState
                title="No staking information found."
                description="No staking information here."
              />
)}
          />
        </div>
      )}

      {isTransaction && (
        <div>
          <SearchTransactionHashSection transactionHash={searchQuery} />
        </div>
      )}
      {!isAccount && !isTransaction && searchQuery !== '' && <SearchError />}
    </>
  );
}
