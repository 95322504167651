import React, { useState } from 'react';
import { Input, Tag } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { text, sizes, elements } from '../../../constants';
import Button from '../../Button';
import WarningIcon from '../../../assets/icons/ic_exclamation_warning_filled.svg';
import TransactionHelper from '../../../utils/TransactionHelper';

const regex = /^(0x)?[0-9a-f]{128}$/i;

const SAMPLE_PK =
  'f1d4617dac88c581a94fd17c72e35d31a228af2852d59d969fc7ab1b0224266191ec15fc072fb593fee703ba5e5259227cd99612fec6aae5fcdb6b0f065cec80';
const SHOW_SAMPLE = process.env.REACT_APP_MOCK_LOGIN_STRINGS === 'true';

const styles = {
  root: {
    width: '561px',
    maxWidth: '900px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rootMobile: {
    width: '100%',
  },
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
    marginBottom: '50px',
  },
  lable: {
    ...elements.INPUT_LABLE,
    marginTop: sizes.SPACE_SMALL,
  },
  input: {
    marginTop: '8px',
    marginBottom: '5px',
    color: '#1C1C1C',
    fontSize: '26px',
  },
  buttonLayout: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row-reverse',
    marginTop: sizes.SPACE_MEDIUM,
  },
  tagWrapper: {
    marginTop: sizes.SPACE_SMALL,
    marginBottom: sizes.SPACE_SMALL,
  },
};
const PrivateKeyAccount = ({ next }) => {
  const screens = useBreakpoint();
  const mobile = !screens.md;
  const [privateKey, setPrivateKey] = useState(SHOW_SAMPLE ? SAMPLE_PK : '');
  const [error, setError] = useState(false);
  return (
    <div style={mobile ? styles.rootMobile : styles.root}>
      <div style={styles.title}>Private Key Sign In</div>
      <div style={styles.lable}>Private Key</div>
      <Input
        style={styles.input}
        size="large"
        type="text"
        placeholder="Enter your private key..."
        value={privateKey}
        onChange={(e) => {
          setPrivateKey(e.target.value);
          setError(false);
        }}
      />
      {error && (
        <div style={styles.tagWrapper}>
          <Tag icon={<img src={WarningIcon} alt="warning" />} color="red">
            Invalid key format
          </Tag>
        </div>
      )}
      <div style={styles.buttonLayout}>
        <Button
          disabled={!privateKey}
          type="primary"
          onClick={async () => {
            if (regex.test(privateKey)) {
              const acct = await TransactionHelper.accountFromPrivateKey(
                privateKey,
              );
              next(acct);
            } else {
              setError(true);
            }
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default PrivateKeyAccount;
