import React from 'react';
import PropTypes from 'prop-types';
import { text } from '../constants';
import Button from './Button';

const styles = {
  root: {
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    ...text.TITLE_MEDIUM,
  },
  description: {
    ...text.HEADING_LARGE,
    textTransform: 'none',
  },
};
const EmptyState = ({
  title, description, buttonText, onClick,
}) => (
  <div style={styles.root}>
    <div style={styles.title}>{title}</div>
    <br />
    <div style={styles.description}>{description}</div>
    <br />
    <Button type="primary" onClick={onClick}>{buttonText}</Button>
  </div>
);

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EmptyState;
