import { combineReducers } from 'redux';
import account from './account';
import balance from './balance';
import {
  delegations,
  rewardsBalance,
  stakedBalance,
} from './delegations';
import pools from './pools';
import stakingTransactions from './stakingTransactions';
import stakingFinalizationTransactions from './stakingFinalizationTransactions';
import poolLogos from './poolLogos';
import modals from './modals';
import rawTransaction from './rawTransaction';
import ledger from './ledger';
import accountTransactions from './accountTransactions';
import nonce from './nonce';
import pendingTransactions from './pendingTransactions';
import accountRecentTransactions from './accountRecentTransactions';
import searchedAccount from './searchedAccount';
import searchedBalance from './searchedBalance';
import searchedNonce from './searchedNonce';
import searchedStakingTransactions from './searchedStakingTransactions';
import searchedAccountTransactions from './searchedAccountTransactions';
import searchedSingleTransaction from './searchedSingleTransaction';

const rootReducer = combineReducers({
  account,
  balance,
  nonce,
  pools,
  stakingTransactions,
  stakingFinalizationTransactions,
  delegations,
  rewardsBalance,
  stakedBalance,
  poolLogos,
  modals,
  rawTransaction,
  ledger,
  accountTransactions,
  accountRecentTransactions,
  pendingTransactions,
  searchedAccount,
  searchedBalance,
  searchedNonce,
  searchedStakingTransactions,
  searchedAccountTransactions,
  searchedSingleTransaction,
});

export default rootReducer;
