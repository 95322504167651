import React from 'react';
import { Tabs } from 'antd';
import { text, sizes, elements } from '../constants';
import PoolsList from '../components/PoolsList';

const { TabPane } = Tabs;

const styles = {
  appTitle: {
    ...text.TITLE_LARGE,
    flexGrow: '1',
    flexDirection: 'row',
  },
  appHeading: {
    ...text.HEADING_LARGE,
    marginTop: sizes.SPACE_MEDIUM,
    flexGrow: '1',
    flexDirection: 'row',
  },
  headingContainer: {
    flexGrow: '1',
    flexDirection: 'row',
    display: 'flex',
    paddingBottom: sizes.SPACE_SMALL,
  },
  walletCard: {
    ...elements.CARD,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardHeading: {
    ...text.HEADING_MEDIUM,
    flexGrow: '1',
    marginLeft: sizes.SPACE_SMALL,
    marginRight: sizes.SPACE_SMALL,
    marginBottom: sizes.SPACE_SMALL,
  },
  textLabel: {
    ...text.BODY,
    flexGrow: '1',
    width: '10%',
    flexDirection: 'row',
    marginLeft: sizes.SPACE_SMALL,
    marginRight: sizes.SPACE_SMALL,
    marginTop: sizes.SPACE_SMALL,
    color: 'gray', // TODO Color code needed from Mishaal
    backgroundColor: 'white',
  },
  tabs: {
    marginTop: sizes.SPACE_SMALL,
    paddingLeft: sizes.SPACE_SMALL,
    paddingRight: sizes.SPACE_SMALL,
  },
};

export default function AllPools() {
  return (
    <>
      <div style={styles.headingContainer}>
        <div style={styles.appTitle}>All Pools</div>
      </div>
      <Tabs
        className="my-tabs"
        tabBarStyle={styles.tabs}
        size="large"
        defaultActiveKey="1"
      >
        <TabPane tab="All Pools" key="1">
          <PoolsList />
        </TabPane>
      </Tabs>
    </>
  );
}
