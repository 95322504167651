import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { sizes } from '../constants';
import { closeAllModals } from '../actions/modals';

const styles = {
  root: {
    height: '100%',
    marginLeft: sizes.SPACE_MEDIUM,
    marginRight: sizes.SPACE_MEDIUM,
    color: '#6F6F6F',
  },
  item: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'center',

    cursor: 'pointer',
  },
  icon: {
    height: '21px',
  },
  selected: {
    color: '#586FE1',
  },
};
function BottomMenuBar(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { items } = props;
  return (
    <Row style={styles.root} justify="space-between" align="middle">
      {items.map((item) => (
        <Col
          style={styles.item}
          onClick={() => {
            dispatch(closeAllModals());
            history.push(item.link);
          }}
          key={item.link}
        >
          <item.icon
            style={styles.icon}
            color={
              location.pathname === item.link
                ? styles.selected.color
                : styles.root.color
            }
          />
          <div style={location.pathname === item.link ? styles.selected : null}>
            {item.text}
          </div>
        </Col>
      ))}
    </Row>
  );
}

BottomMenuBar.propTypes = {
  items: PropTypes.array.isRequired,
};

export default BottomMenuBar;
