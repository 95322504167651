import ABICoder from 'aion-web3-avm-abi';
import BigNumber from 'bignumber.js';
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';
import TransactionHelper, { signAsync } from '../utils/TransactionHelper';
import { numbers } from '../constants';
import { fetchNonce } from './nonce';
import { addPendingTransaction } from './pendingTransactions';
import { fetchAccountTransactions } from './accountTransactions';

const abi = new ABICoder();

export const RAW_TX_TYPE = {
  DELEGATE: 'delegate',
  UNDELEGATE: 'undelegate',
  MOVE_STAKE: 'move_stake',
  WITHDRAW: 'withdraw',
  AUTODELEGATE: 'autodelegate',
  DISABLE_AUTODELEGATE: 'disable_autodelegate',
  SEND: 'SEND',
};

export const UPDATE_RAW_TX = 'UPDATE_RAW_TX';

export const updateRawTransaction = ReduxUtils.createAction(
  UPDATE_RAW_TX,
  'tx',
);

const formatToAccountTransaction = (tx, hash) => ({
  timestamp: parseInt(tx.timestamp, 10) / 1000 / 1000,
  from: tx.from,
  to: tx.to,
  value: tx.amount,
  data: tx.data,
  hash,
});

export const sendRawTransactionAddress = (value, to, gas) => async (
  dispatch,
  getState,
  { apiService },
) => {
  dispatch(updateRawTransaction(DataModel.initLoading()));
  try {
    const { account } = getState();
    const nonceResp = await dispatch(fetchNonce());
    const nonce = nonceResp.result;
    const tx = TransactionHelper.buildTransaction(
      nonce,
      account.address,
      to,
      new BigNumber(gas).times(numbers.BN_AION).toFixed(),
      numbers.BN_GAS_USAGE_TRANSFER.toFixed(),
      account.privateKey,
      null,
      new BigNumber(value).times(numbers.BN_AION).toFixed(),
    );

    console.log('UnsignedTx', tx);
    const signed = await signAsync(tx, account);
    console.log('SignedTx', signed);

    const txHash = await apiService.fetchRawTxSubmission(`0x${signed}`);
    if (txHash.result) {
      dispatch(updateRawTransaction(new DataModel(txHash.result)));
      dispatch(addPendingTransaction(formatToAccountTransaction(tx, txHash.result)));
      dispatch(fetchAccountTransactions(0));
    } else {
      dispatch(updateRawTransaction(DataModel.error(0, 'Failed submitting transaction')));
    }
  } catch (ex) {
    console.trace(ex);
    dispatch(updateRawTransaction(DataModel.error(0, ex.message)));
  }
};

export const sendRawTransactionPool = (pool, amount, txType, toPool) => async (
  dispatch,
  getState,
  { apiService },
) => {
  dispatch(updateRawTransaction(DataModel.initLoading()));
  try {
    const { account } = getState();
    const nonceResp = await dispatch(fetchNonce());
    const nonce = nonceResp.result;
    console.log({
      pool,
      amount,
      txType,
      toPool,
    });
    let data;
    let value;
    switch (txType) {
      case RAW_TX_TYPE.DELEGATE: {
        value = new BigNumber(amount).multipliedBy(numbers.BN_AION).toFixed();
        data = abi.encodeMethod('delegate', ['Address'], [pool]);
        break;
      }
      case RAW_TX_TYPE.UNDELEGATE: {
        const fee = 0;
        value = new BigNumber(0);
        data = abi.encodeMethod(
          'undelegate',
          ['Address', 'BigInteger', 'BigInteger'],
          [
            pool,
            new BigNumber(amount).multipliedBy(numbers.BN_AION).toFixed(),
            new BigNumber(fee).multipliedBy(numbers.BN_AION).toFixed(),
          ],
        );
        break;
      }
      case RAW_TX_TYPE.WITHDRAW: {
        data = abi.encodeMethod('withdrawRewards', ['Address'], [pool]);
        break;
      }
      case RAW_TX_TYPE.MOVE_STAKE: {
        const fee = 0;
        data = abi.encodeMethod(
          'transferDelegation',
          ['Address', 'Address', 'BigInteger', 'BigInteger'],
          [
            pool,
            toPool,
            new BigNumber(amount).multipliedBy(numbers.BN_AION).toFixed(),
            new BigNumber(fee).multipliedBy(numbers.BN_AION).toFixed(),
          ],
        );
        break;
      }
      case RAW_TX_TYPE.AUTODELEGATE: {
        const feePercentage = 0;
        data = abi.encodeMethod(
          'enableAutoRewardsDelegation',
          ['Address', 'int'],
          [pool, feePercentage],
        );
        break;
      }
      case RAW_TX_TYPE.DISABLE_AUTODELEGATE: {
        data = abi.encodeMethod(
          'disableAutoRewardsDedelegation',
          ['Address'],
          [pool],
        );
        break;
      }
      default: {
        throw new Error(`Unknown RAW_TX_TYPE:${txType}`);
      }
    }

    const tx = TransactionHelper.buildTransaction(
      nonce,
      account.address,
      process.env.REACT_APP_POOL_REGISTRY,
      numbers.BN_GAS_PRICE.toFixed(),
      numbers.BN_GAS_USAGE_SEND.toFixed(),
      account.privateKey,
      data,
      value,
    );
    console.log('UnsignedTx', tx);
    const signed = await signAsync(tx, account);
    console.log('SignedTx', signed);

    const txHash = await apiService.fetchRawTxSubmission(`0x${signed}`);
    if (txHash.result) {
      dispatch(updateRawTransaction(new DataModel(txHash.result)));
      dispatch(addPendingTransaction(formatToAccountTransaction(tx, txHash.result)));
      dispatch(fetchAccountTransactions(0));
    } else {
      dispatch(updateRawTransaction(DataModel.error(0, 'Failed submitting transaction')));
    }
  } catch (ex) {
    console.trace(ex);
    dispatch(updateRawTransaction(DataModel.error(0, ex.message)));
  }
};
