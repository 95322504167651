import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { text, sizes } from '../../../constants';
import Step1Image from '../../../assets/logos/ledger_step_1.png';
import Step2Image from '../../../assets/logos/ledger_step_2.png';
import { subscribeToLedgerStatus, unsubscribeToLedgerStatus } from '../../../actions/ledger';

const styles = {
  root: {},
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
  },
  column: {
    padding: '30px 40px',
    minWidth: '336px',
  },
  columnMobile: {
    padding: '0px',
    minWidth: '350px',
  },
  heading: {
    ...text.BODY,
    fontWeight: 'bold',
    color: '#1C1C1C',
    textTransform: 'capitalize',
    marginBottom: sizes.SPACE_TINY,
  },
  description: {
    ...text.BODY,
    color: '#6F6F6F',
    maxWidth: 175,
    marginBottom: sizes.SPACE_TINY,
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  grid: {
    padding: '0 40px',
    flexWrap: 'nowrap',
  },
  gridMobile: {
    padding: '0px',
    flexWrap: 'wrap',
    marginTop: sizes.SPACE_TINY,
  },
};
const LedgerInfo = ({ next }) => {
  const ledger = useSelector((state) => state.ledger);
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const mobile = !screens.md;

  useEffect(() => {
    console.log('Subscribed to ledger status...');
    dispatch(subscribeToLedgerStatus());
    return () => {
      console.log('Unsubscribed from ledger status...');
      dispatch(unsubscribeToLedgerStatus());
    };
  }, [dispatch]);

  if (ledger.status) {
    next();
  }
  return (
    <div style={styles.root}>
      <div style={styles.title}>Ledger Sign In</div>
      <Row style={mobile ? styles.gridMobile : styles.grid}>
        <Col style={mobile ? styles.columnMobile : styles.column} lg={12} xs={24} flex="column">
          <div style={styles.heading}>Step One</div>
          <div style={styles.description}>
            Connect your Ledger device to your computer
          </div>
          <img style={styles.image} src={Step1Image} alt="step 1" />
        </Col>
        <Col style={mobile ? styles.columnMobile : styles.column} lg={12} xs={24} flex="column">
          <div style={styles.heading}>Step Two</div>
          <div style={styles.description}>
            Open the Aion Application on your Ledger device
          </div>
          <img style={styles.image} src={Step2Image} alt="step 2" />
        </Col>
      </Row>
    </div>
  );
};

export default LedgerInfo;
