import React from 'react';
import PropTypes from 'prop-types';
import { text } from '../constants';
import { formatAionValue } from '../utils/utils';
import AionIcon from '../assets/logos/AionIcon';

const styles = {
  iconlarge: {
    width: 16,
    height: 16,
    marginLeft: 4,
  },
  iconmedium: {
    width: 13,
    height: 13,
    marginLeft: 4,
  },
  iconsmall: {
    width: 10,
    height: 10,
    marginLeft: 4,
  },
  large: {
    ...text.BALANCE,
  },
  medium: {
    ...text.BODY,
    fontWeight: 'medium',
    color: 'inherit',
  },
  small: {
    ...text.BODY_LIGHT,
  },
};
const Balance = ({
  value = 0, type, formatter, style,
}) => {
  let formattedValue = value;
  if (formatter && value.toNumber) {
    formattedValue = formatter(value.toNumber());
  }
  return (
    <span style={style}>
      <span style={{ ...styles[type], color: style?.color ?? styles[type].color }}>{formattedValue}</span>
      <AionIcon style={styles[`icon${type}`]} color={style?.color} />
    </span>
  );
};
Balance.defaultProps = {
  type: 'large',
  formatter: formatAionValue,
};
Balance.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['large', 'medium', 'small']),
  formatter: PropTypes.func,
  style: PropTypes.object,
};

export default Balance;
