import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Radio, Spin, Row, Col,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { toggleAccountModal } from '../../actions/modals';
import {
  setAccountAndFetchData,
  setAccountToLoggedOut,
  fetchPastAccountsWithBalances,
} from '../../actions/account';
import { text, sizes, strings } from '../../constants';
import LoginModal from './LoginModal';
import Balance from '../Balance';
import Button from '../Button';
import { sendAmplitudeData } from '../../utils/Amplitude';

const styles = {
  root: {
    width: '80vw',
    maxWidth: '900px',
  },
  rootMobile: {
    width: '100%',
  },
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
    marginBottom: sizes.SPACE_MEDIUM,
  },
  row: {
    minHeight: '79px',
    border: '2px solid #ECECEC',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0',
    padding: sizes.SPACE_SMALL,
    cursor: 'pointer',
  },
  addressColumn: {
    width: '70%',
  },
  balanceColumn: {
    width: '25%',
  },
  radio: {
    width: '5%',
  },
  lable: {
    ...text.HEADING_SMALL,
    marginBottom: sizes.SPACE_TINY,
  },
  address: {
    ...text.BODY,
    fontWeight: 'bold',
  },
  buttonLayout: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: sizes.SPACE_LARGE,
  },
  spinnerLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: '110px',
    color: '#CFCFCF',
  },
};
export default () => {
  const screens = useBreakpoint();
  const mobile = !screens.md;
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  const [pastAccounts, setPastAccounts] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    pastAccounts?.findIndex?.((acct) => account.address === acct.address) ?? 0,
  );
  useEffect(() => {
    async function getPast() {
      const resp = await dispatch(fetchPastAccountsWithBalances());
      setPastAccounts(resp);
    }
    getPast();
  }, [dispatch, setPastAccounts]);

  let content;
  sendAmplitudeData(strings.ACCOUNT_MODAL_OPENED);
  switch (step) {
    case 0: {
      content = (
        <div style={mobile ? styles.rootMobile : styles.root}>
          <div style={styles.title}>Select your Address</div>
          {!pastAccounts && (
            <div style={styles.spinnerLayout}>
              {' '}
              <Spin />
              <br />
              <div>We&apos;re loading your accounts...</div>
            </div>
          )}
          {pastAccounts && (
            <>
              {pastAccounts?.map?.((account, i) => (
                <div
                  key={i}
                  style={styles.row}
                  onClick={() => {
                    setSelectedIndex(i);
                  }}
                >
                  <div style={styles.addressColumn}>
                    <div style={styles.lable}>{i}</div>
                    <div style={styles.address}>{account.address}</div>
                  </div>
                  <div style={styles.balanceColumn}>
                    <div style={styles.lable}>Total Aion</div>
                    <div style={styles.address}>
                      <Balance value={account.balance} />
                    </div>
                  </div>
                  <Radio checked={selectedIndex === i} style={styles.radio} />
                </div>
              ))}
              <div
                style={{ ...styles.row, ...styles.address }}
                onClick={() => setStep(1)}
              >
                <div style={styles.addressColumn}>+ Add another Address</div>
              </div>
            </>
          )}
          <Row justify="end" gutter={[16, 16]}>
            <Col lg={4} xs={24}>
              <Button
                style={{ width: '100%' }}
                disabled={selectedIndex === null}
                type="primary"
                onClick={() => {
                  const selectedAccount = pastAccounts[selectedIndex];
                  dispatch(toggleAccountModal());
                  dispatch(setAccountAndFetchData({ ...selectedAccount }));
                }}
              >
                Continue
              </Button>
            </Col>
            <Col lg={4} xs={24}>
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  sendAmplitudeData(strings.SIGN_OUT_CLICKED);
                  dispatch(toggleAccountModal());
                  dispatch(setAccountToLoggedOut());
                  window.location = '/';
                }}
              >
                Sign Out
              </Button>
            </Col>
          </Row>
        </div>
      );
      break;
    }
    default: {
      content = <LoginModal />;
      break;
    }
  }
  return <div>{content}</div>;
};
