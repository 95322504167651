import {
  UPDATE_LEDGER_STATUS,
  UPDATE_LEDGER_ACCOUNTS,
} from '../actions/ledger';
import ReduxUtils from '../utils/ReduxUtils';

const initialState = {
  status: false,
  accounts: null,
};

export default ReduxUtils.createReducer(initialState, {
  [UPDATE_LEDGER_STATUS]: (state, action) => ({
    ...state,
    status: action.status,
  }),
  [UPDATE_LEDGER_ACCOUNTS]: (state, action) => ({
    ...state,
    accounts: action.accounts,
  }),
});
