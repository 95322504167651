import BigNumber from 'bignumber.js';
import ReduxUtils from '../utils/ReduxUtils';

export const SEARCHED_NONCE_UPDATE = 'SEARCHED_NONCE_UPDATE';

export const updateSearchedNonce = ReduxUtils.createAction(SEARCHED_NONCE_UPDATE, 'searchedNonce');

export const fetchSearchedNonce = () => async (
  dispatch,
  getState,
  { apiService },
) => {
  const { searchedAccount } = getState();
  const nonceResp = await apiService.fetchNonce(searchedAccount.address);
  const nonce = new BigNumber(nonceResp.result);
  dispatch(updateSearchedNonce(nonce.toNumber()));
};
