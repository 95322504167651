import BigNumber from 'bignumber.js';
import ReduxUtils from '../utils/ReduxUtils';

export const NONCE_UPDATE = 'NONCE_UPDATE';

export const updateNonce = ReduxUtils.createAction(NONCE_UPDATE, 'nonce');

export const fetchNonce = () => async (
  dispatch,
  getState,
  { apiService },
) => {
  const { account } = getState();
  const nonceResp = await apiService.fetchNonce(account.address);
  const nonce = new BigNumber(nonceResp.result);
  dispatch(updateNonce(nonce.toNumber()));
  return nonceResp;
};
