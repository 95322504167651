import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

export const STAKING_TRANSACTIONS_UPDATE = 'STAKING_TRANSACTIONS_UPDATE';

export const updateStakingTransactions = ReduxUtils.createAction(
  STAKING_TRANSACTIONS_UPDATE,
  'stakingTransactions',
);

export const fetchStakingTransactions = () => async (
  dispatch,
  getState,
  { apiService },
) => {
  const { account, stakingTransactions } = getState();
  dispatch(
    updateStakingTransactions(
      stakingTransactions.data
        ? new DataModel(stakingTransactions.data)
        : DataModel.initLoading(),
    ),
  );
  try {
    apiService.fetchStakingTransactions(account.address);
  } catch (ex) {
    dispatch(updateStakingTransactions(DataModel.error(0, ex.message)));
  }
};
