/* eslint-disable no-param-reassign */
import BigNumber from 'bignumber.js';
import { numbers } from '../constants';
import { hexCharCodeToStr, mapDelegationsToTotals } from './utils';
import { updatePools } from '../actions/pools';
import { updateStakingTransactions } from '../actions/stakingTransactions';
import { updateSearchedStakingTransactions } from '../actions/searchedStakingTransactions';
import { updateBalance } from '../actions/balance';
import { fetchPoolLogos } from '../actions/poolLogos';
import {
  updateDelegations,
  updateRewardsBalance,
  updateStakedBalance,
} from '../actions/delegations';
import DataModel from '../models/DataModel';
import { updateRawTransaction } from '../actions/rawTransaction';
import { updateStakingFinalizationTransactions } from '../actions/stakingFinalizationTransactions';

let instance;

export default class SocketHandler {
  static getInstance() {
    if (!instance) {
      instance = new SocketHandler();
    }
    return instance;
  }

  constructor() {
    this.store = null;
    this.apiService = null;
  }

  handle(res) {
    try {
      const { account } = this.store.getState();
      if (
        !account?.address &&
        ['pools', 'performance'].indexOf(res.method) < 0
      ) {
        return;
      }
      // console.log(res);
      switch (res.method) {
        case 'pools':
          this.handlePools(res);
          break;
        case 'delegations':
          this.handleDelegations(res);
          break;
        case 'transactions':
          this.handleStakingTransactions(res);
          break;
        case 'finalizations':
          this.handleStakingFinalizationTransactions(res);
          break;
        case 'eth_sendRawTransaction':
          this.handleRawTransaction(res);
          break;
        case 'commission_rate_changes':
          break;
        case 'performances':
          break;
        case 'eth_getBlockByNumber':
          break;
        case 'eth_getBalance':
          this.handleBalance(res);
          break;
        case 'eth_getTransactionReceipt':
          break;
        case 'eth_getTransactionCount':
          break;
        case 'eth_blockNumber':
          break;
        default:
          break;
      }
    } catch (err) {
      console.trace(err);
    }
  }

  handlePools(res) {
    const { BN_AION, PERFORMANCE_MAP } = numbers;

    if (res.error) return;
    const values = Object.values(res.result);
    if (!values.length) return;
    let totalStaked = new BigNumber(0);
    values.forEach((v) => {
      v.stake_total = new BigNumber(v.stake_total).dividedBy(BN_AION);
      v.stake_self = new BigNumber(v.stake_self).dividedBy(BN_AION);
      v.stake_pending = new BigNumber(v.stake_pending).dividedBy(BN_AION);
      v.capacity = v.stake_self
        .multipliedBy(100, 10)
        .minus(v.stake_total)
        .minus(v.stake_pending);
      if (v.capacity.comparedTo(0, 10) < 0) v.capacity = new BigNumber(0);
      v.fee = new BigNumber(v.fee).dividedBy(10000, 10);
      v.calculate_active =
        v.stake_self.comparedTo(1000, 10) >= 0 &&
        v.stake_self.multipliedBy(100, 10).comparedTo(v.stake_total) >= 0;
      // v.pos_blk_total = new BigNumber(v.pos_blk_total)
      // total_pos_blk = total_pos_blk.plus(v.pos_blk_total)
      totalStaked = totalStaked.plus(v.stake_total);
      v.meta_data_url = hexCharCodeToStr(v.meta_data_url);
      if (!v.meta_name) v.meta_name = '';
    });
    const pools1 = [];
    const pools2 = [];
    const pools3 = [];
    values.forEach((v) => {
      v.pos_weight = new BigNumber(
        !v.pos_blk_total_network
          ? 0
          : v.pos_blk_total / v.pos_blk_total_network,
      );
      // total_pos_blk.comparedTo(0, 10) === 0 ? new BigNumber(0)
      // : v.pos_blk_total.dividedBy(total_pos_blk)
      v.stake_weight =
        totalStaked.comparedTo(0, 10) === 0
          ? new BigNumber(0)
          : v.stake_total.dividedBy(totalStaked);
      v.performance = v.performance || 0;
      if (v.performance >= PERFORMANCE_MAP.EXCELLENT.value) pools1.push(v);
      else if (v.performance >= PERFORMANCE_MAP.MODERATE.value) pools2.push(v);
      else pools3.push(v);
    });
    pools1.sort(() => Math.random() - 0.5);
    pools2.sort(() => Math.random() - 0.5);
    pools3.sort(() => Math.random() - 0.5);
    const pools = [...pools1, ...pools2, ...pools3];
    if (pools3.length) {
      this.store.dispatch(fetchPoolLogos(pools.map((p) => p.address)));
    }
    this.store.dispatch(updatePools(new DataModel(pools)));
  }

  handleDelegations(res) {
    if (res.error) {
      this.store.dispatch(
        updateDelegations(
          DataModel.error(0, 'Unable to fetch staking transactions'),
        ),
      );
    }
    const { stake, rewards } = mapDelegationsToTotals(res);
    const { dispatch } = this.store;
    dispatch(updateStakedBalance(new DataModel(stake)));
    dispatch(updateRewardsBalance(new DataModel(rewards)));
    dispatch(updateDelegations(new DataModel(res.result.data)));
  }

  handleStakingTransactions(res) {
    if (res.error) {
      this.store.dispatch(
        updateStakingTransactions(
          DataModel.error(0, 'Unable to fetch staking transactions'),
        ),
      );
    }
    const { BN_AION } = numbers;
    res.result.data.forEach((v) => {
      v.amount = new BigNumber(v.amount).dividedBy(BN_AION);
      if (v.amount.toString() === 'NaN') v.amount = new BigNumber(0);
    });
    this.store.dispatch(
      updateStakingTransactions(SocketHandler.toDataModel(res.result.data)),
    );
  }

  handleStakingFinalizationTransactions(res) {
    if (res.error) {
      this.store.dispatch(
        updateStakingFinalizationTransactions(
          DataModel.error(
            0,
            'Unable to fetch staking finalization transactions',
          ),
        ),
      );
    }
    const { BN_AION } = numbers;
    res.result.forEach((v) => {
      v.amount = new BigNumber(v.amount).dividedBy(BN_AION);
      if (v.amount.toString() === 'NaN') v.amount = new BigNumber(0);
    });
    this.store.dispatch(
      updateStakingFinalizationTransactions(
        SocketHandler.toDataModel(res.result),
      ),
    );
  }

  handleSearchedStakingTransactions(res) {
    if (res.error) {
      this.store.dispatch(
        updateSearchedStakingTransactions(
          DataModel.error(0, 'Unable to fetch staking transactions'),
        ),
      );
    }
    const { BN_AION } = numbers;
    res.result.data.forEach((v) => {
      v.amount = new BigNumber(v.amount).dividedBy(BN_AION);
      if (v.amount.toString() === 'NaN') v.amount = new BigNumber(0);
    });
    this.store.dispatch(
      updateSearchedStakingTransactions(
        SocketHandler.toDataModel(res.result.data),
      ),
    );
  }

  handleBalance(res) {
    if (res && res.result && !res.error) {
      const bn = new BigNumber(res.result);
      const value = bn.shiftedBy(-18);
      this.store.dispatch(updateBalance(SocketHandler.toDataModel(value)));
    } else {
      this.store.dispatch(
        updateBalance(DataModel.error(0, 'Unable to fetch account balance')),
      );
    }
  }

  handleRawTransaction(res) {
    if (res && res.result && !res.error) {
      this.store.dispatch(
        updateRawTransaction(SocketHandler.toDataModel(res.result)),
      );
    } else {
      this.store.dispatch(
        updateRawTransaction(DataModel.error(0, 'Unable to send transaction')),
      );
    }
  }
  // otherHandler() {}

  setStore(store) {
    this.store = store;
  }

  setApiService(apiService) {
    this.apiService = apiService;
  }

  static toDataModel(data) {
    return new DataModel(data, false, null);
  }
}
