import BigNumber from 'bignumber.js';
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';
import { mapDelegationsToTotals } from '../utils/utils';

export const SEARCHED_BALANCE_UPDATE = 'SEARCHED_BALANCE_UPDATE';

export const updateSearchedBalance = ReduxUtils.createAction(
  SEARCHED_BALANCE_UPDATE,
  'searchedBalance',
);

export const fetchSearchedBalance = () => async (
  dispatch,
  getState,
  { apiService },
) => {
  const { searchedAccount } = getState();
  dispatch(updateSearchedBalance(DataModel.initLoading()));
  try {
    const res = await apiService.fetchBalance(searchedAccount.address); // balance
    const resDelegations = await apiService.fetchDelegations(
      searchedAccount.address,
    ); // staked balance
    const { stake } = mapDelegationsToTotals(resDelegations);
    const bn = new BigNumber(res.result);
    const value = bn.shiftedBy(-18);
    dispatch(updateSearchedBalance(new DataModel(value.plus(stake))));
  } catch (ex) {
    dispatch(updateSearchedBalance(DataModel.error(0, ex.message)));
  }
};
