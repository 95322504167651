import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import LedgerInfo from './LedgerInfo';
import LedgerAccounts from './LedgerAccounts';
import LedgerError from './LedgerError';
import { closeAllModals } from '../../../actions/modals';
import { setAccountAndFetchData } from '../../../actions/account';

export default () => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  let content;
  switch (step) {
    case 0: {
      content = <LedgerInfo next={() => setStep(1)} />;
      break;
    }
    case 1: {
      content = (
        <LedgerAccounts
          next={(account) => {
            dispatch(closeAllModals());
            dispatch(
              setAccountAndFetchData({
                address: account.address,
                pubKey: account.pubKey,
                index: account.index,
                type: 'ledger',
              }),
            );
          }}
          onError={() => setStep(2)}
        />
      );
      break;
    }
    default: {
      content = <LedgerError next={() => setStep(0)} />;
      break;
    }
  }
  return <div>{content}</div>;
};
