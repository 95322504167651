import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

export const SEARCHED_SINGLE_TRANSACTION = 'SEARCHED_SINGLE_TRANSACTION';

export const updateSearchedSingleTransaction = ReduxUtils.createAction(SEARCHED_SINGLE_TRANSACTION, 'searchedSingleTransaction');

export const fetchSingleTransaction = (transactionHash) => async (
  dispatch,
  getState,
  { apiService },
) => {
  dispatch(updateSearchedSingleTransaction(DataModel.initLoading()));
  try {
    const res = await apiService.fetchTransaction(transactionHash);
    dispatch(updateSearchedSingleTransaction(new DataModel(res)));
  } catch (ex) {
    console.log(ex);
    dispatch(updateSearchedSingleTransaction(DataModel.error(0, ex.message)));
  }
};
