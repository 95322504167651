import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import { text } from '../../constants';
import Button from '../Button';
import { toggleLoginModal, toggleAccountModal } from '../../actions/modals';
import { formatAddressEnd } from '../../utils/utils';

const styles = {
  title: {
    ...text.TITLE_MEDIUM,
  },
  date: {
    ...text.HEADING_MEDIUM,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

const HomeTitle = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  return (
    <div style={styles.root}>
      <div>
        <div style={styles.date}>{moment().format('dddd, MMM D')}</div>
        <div style={styles.title}>What&apos;s new</div>
      </div>
      {account ? (
        <Button
          style={styles.actionButton}
          size="large"
          type="primary"
          onClick={() => dispatch(toggleAccountModal())}
        >
          <span>{formatAddressEnd(account.address)}</span>
          <CaretDownOutlined />
        </Button>
      ) : (
        <Button
          onClick={() => dispatch(toggleLoginModal())}
          style={styles.loginMobileButton}
          size="large"
          type="primary"
        >
          Sign In
        </Button>
      )}
    </div>
  );
};

export default HomeTitle;
