/* eslint-disable operator-linebreak */
let instance;
export default class SocketService {
  static getInstance(wsUrl, handler) {
    if (!instance) {
      instance = new SocketService(wsUrl, handler);
    }
    return instance;
  }

  constructor(wsUrl, handler) {
    this.wsUrl = wsUrl;
    this.ws = null;
    this.wsCallbackMap = {};
    this.handler = handler;
    // this.runWS();
  }

  static generateId() {
    return Math.random().toString().substring(3) + Date.now();
  }

  // only if ws is not null will retry, don't give param time when call this method in other place
  wsSend(obj, time) {
    // (undefined < 1) === false
    if (time < 1) return null;
    // if ws is null or ws not connect successfully, retry
    if (!this.ws || this.ws.readyState !== 1) {
      return setTimeout(() => {
        this.wsSend(obj, time === undefined ? 5 : time - 1);
      }, 2000);
    }

    // if (callback) {
    //   // eslint-disable-next-line no-param-reassign
    //   obj.id = SocketService.generateId();
    //   this.wsCallbackMap[obj.id] = callback;
    // }
    this.ws.send(JSON.stringify(obj));
    return null;
  }

  wsClose() {
    if (this.ws && this.ws.readyState !== 3) {
      this.ws.close();
      this.ws = null;
    }
  }

  runWS() {
    if (this.ws === null || this.ws.readyState === 3) {
      this.ws = new WebSocket(this.wsUrl);
      this.ws.onopen = () => {
        // this.wsSend({ method: 'pools', params: [false] });
        window.ws = this.ws;
      };
      this.ws.onmessage = (msg) => {
        const res = JSON.parse(msg.data);
        this.handler.handle(res);
      };
      this.ws.onerror = (err) => {
        console.log('websocket err occur:', err);
      };
      this.ws.onclose = (err) => {
        console.log('websocket closed:', err);
        setTimeout(() => this.runWS(), 10000);
      };
    }
  }
}
