import React, { useState } from 'react';
import {
  Input, Row, Col, Collapse, Slider, Tooltip,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { QuestionCircleFilled } from '@ant-design/icons';
import BigNumber from 'bignumber.js';
import {
  text, sizes, elements, numbers, strings,
} from '../../constants';
import Balance from '../Balance';
import Button from '../Button';
import DataModel from '../../models/DataModel';
import {
  sendRawTransactionAddress,
  RAW_TX_TYPE,
} from '../../actions/rawTransaction';
import TransactionStatus from './TransactionStatus';
import Checksum from '../../utils/Checksum';
import Message from '../Message';

const { Panel } = Collapse;

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
  },
  heding: {
    ...text.HEADING_LARGE,
    textTransform: 'initial',
    color: '#000000',
  },
  lable: {
    ...elements.INPUT_LABLE,
    marginTop: sizes.SPACE_LARGE,
  },
  buttonLayout: {
    width: '100%',
    marginTop: sizes.SPACE_MEDIUM,
  },
  buttonCol: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  input: {
    marginTop: '8px',
    marginBottom: '5px',
    color: '#1C1C1C',
    fontSize: '26px',
  },
  entireBal: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: 14,
    color: '#000000',
  },
  slider: {
    width: '100%',
    marginLeft: '17px',
    marginRight: '17px',
  },
  panel: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  sliderMarks: {
    height: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: '8px',
    padding: '0px 15px',
    pointerWvents: 'none',
    transform: 'translateY(-10px)',
  },
  warning: {
    marginBottom: sizes.SPACE_SMALL,
  },
};
const SendModal = () => {
  const [amount, setAmount] = useState('');
  const [recipient, setRecipient] = useState('');
  const [fee, setFee] = useState(1);
  const { balance, rawTransaction, account } = useSelector((state) => state);
  const [warning, setWarning] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const base = numbers.BN_GAS_PRICE.toNumber();
  const gas = new BigNumber(fee * base).dividedBy(numbers.BN_AION);
  const gasTotal = numbers.BN_GAS_USAGE_TRANSFER.times(
    new BigNumber(fee * base),
  ).dividedBy(numbers.BN_AION);

  return (
    <div>
      {DataModel.canFetch(rawTransaction) ? (
        <div style={styles.root}>
          <div style={styles.title}>Send Aion</div>
          <div style={styles.lable}>Amount:</div>
          <Input
            style={styles.input}
            size="large"
            type="number"
            placeholder="Enter an amount..."
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Row gutter={8}>
            <Col>
              <Row justify="center">
                <Col>
                  <Balance value={balance.data} type="small" />
                </Col>
                <Col>&nbsp;available</Col>
              </Row>
            </Col>
            <Col
              style={styles.entireBal}
              onClick={() => setAmount(balance.data.minus(gasTotal).toNumber())}
            >
              <b>Entire Balance</b>
            </Col>
          </Row>
          <div style={styles.lable}>To Address:</div>
          <Input
            style={styles.input}
            size="large"
            type="text"
            placeholder="Enter an address..."
            value={recipient}
            onChange={(e) => {
              setRecipient(e.target.value);
              setWarning(
                !Checksum.test(e.target.value)
                  ? 'Please ensure that the recipient address is valid.'
                  : false,
              );
              setError(
                !RegExp(strings.walletRegex).test(e.target.value)
                  ? 'Invalid address format'
                  : false,
              );
            }}
          />
          {error && (
            <Message style={{ marginTop: '10px' }} type="error">
              {error}
            </Message>
          )}
          {warning && !error && (
            <Message style={{ marginTop: '10px' }} type="warning">
              {warning}
            </Message>
          )}
          <Collapse className="myCollapse" ghost expandIconPosition="right">
            <Panel style={styles.panel} header="Advanced Options" key="1">
              <Row justify="space-between">
                <Col>
                  <div style={styles.heding}>
                    Transaction Speed &nbsp;
                    <Tooltip title="Adjust the transaction speed according to your time preference. ">
                      <span style={{ color: '#888888' }}>
                        <QuestionCircleFilled />
                      </span>
                    </Tooltip>
                  </div>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  Fee:&nbsp;
                  <Balance
                    style={{ fontWeight: 'bold' }}
                    type="medium"
                    value={gasTotal}
                    formatter={(num) => num.toFixed(4)}
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: '15px' }}>
                <Slider
                  tooltipVisible={false}
                  style={styles.slider}
                  min={1}
                  max={30}
                  onChange={setFee}
                  value={fee}
                />
                <div style={styles.sliderMarks}>
                  <div>|</div>
                  <div style={{ paddingLeft: '9px' }}>|</div>
                  <div>|</div>
                </div>
              </Row>
            </Panel>
          </Collapse>
          <Row style={styles.buttonLayout} justify="space-between">
            <Col>
              <div>
                Fees:&nbsp;
                {gasTotal.toFixed(4)}
                {' '}
                Aion
              </div>
              <div>
                Sending:&nbsp;
                {amount === '' ? 0 : amount}
                {' '}
                Aion
              </div>
              <div>
                You pay:&nbsp;
                {gasTotal.plus(amount === '' ? 0 : amount).toFixed(4)}
                {' '}
                Aion
              </div>
            </Col>
            <Col style={styles.buttonCol}>
              <Button
                disabled={error || !recipient || !amount}
                type="primary"
                onClick={() => {
                  dispatch(
                    sendRawTransactionAddress(
                      amount,
                      recipient,
                      gas.toNumber(),
                    ),
                  );
                }}
              >
                Send Aion
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        <TransactionStatus
          rawTransaction={rawTransaction}
          account={account}
          name="name hree"
          address={recipient}
          operationType={RAW_TX_TYPE.SEND}
          amount={amount}
          toName="to name"
          toAddress={null}
        />
      )}
    </div>
  );
};

export default SendModal;
