import React from 'react';
import { Row, Col, Card } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { text, elements, sizes } from '../constants';
import Balance from './Balance';
import Checksum from '../utils/Checksum';

const styles = {
  appHeading: {
    ...text.TITLE_LARGE,
    flexGrow: '1',
    flexDirection: 'row',
    marginBottom: sizes.SPACE_MEDIUM,
  },
  address: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#888888',
    overflowWrap: 'anywhere',
  },
  addressMobile: {
    color: '#6F6F6F',
    fontSize: '14px',
    overflowWrap: 'anywhere',
  },
  totalCount: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#888888',
    marginTop: '60px',
  },
  walletCard: {
    ...elements.CARD,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '136px',
  },
  cardHeading: {
    ...text.HEADING_MEDIUM,
    flexGrow: '1',
  },
  walletCardMobile: {
    minHeight: 'initial',
  },
  totalCountMobile: {
    marginTop: '0px',
  },
};
const TransactionsHeading = ({
  title, address, nonce, balance,
}) => {
  const screens = useBreakpoint();
  const mobile = !screens.xl;
  return (
    <div>
      <div style={styles.appHeading}>{title}</div>
      <Row gutter={{ lg: 24, xs: 12 }} justify="space-between" align="middle">
        <Col lg={16} xs={12}>
          <div style={mobile ? styles.addressMobile : styles.address}>{Checksum.encode(address)}</div>
          <div style={{ ...styles.totalCount, ...(mobile && styles.totalCountMobile) }}>
            Total Transactions:
            {' '}
            <span style={{ fontWeight: 'normal' }}>{nonce}</span>
          </div>
        </Col>
        <Col lg={8} xs={12}>
          <Card bodyStyle={{ ...styles.walletCard, ...(mobile && styles.walletCardMobile) }}>
            <div style={styles.cardHeading}>Available Balance</div>
            <Row style={{ paddingTop: '5px' }}>
              {balance && <Balance value={balance} />}
              <Col />
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TransactionsHeading;
