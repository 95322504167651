import { STAKING_FINILIZATIONS_TRANSACTIONS_UPDATE } from '../actions/stakingFinalizationTransactions';
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

const initialState = new DataModel();

export default ReduxUtils.createReducer(initialState, {
  ...ReduxUtils.createObjectHandler(
    STAKING_FINILIZATIONS_TRANSACTIONS_UPDATE,
    'stakingFinalizationTransactions',
  ),
});
