import React from 'react';
import PoolDetails from '../PoolDetails';

const PoolDetailsModal = ({ modalExtraData }) => {
  console.log(modalExtraData);
  const { pool, hasStake } = modalExtraData;
  return (
    <div>
      <PoolDetails pool={pool} hasStake={hasStake} />
    </div>
  );
};

export default PoolDetailsModal;
