import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Button from './Button';
import { formatAionValue } from '../utils/utils';
import { text, sizes, strings } from '../constants';
import Balance from './Balance';
import { toggleUnstakeFundsModal, toggleLoginModal } from '../actions/modals';

import { RAW_TX_TYPE } from '../actions/rawTransaction';
import HomeStakeControls from './HomeStakeControls';
import PoolName from './PoolName';
import { sendAmplitudeData } from '../utils/Amplitude';

const styles = {
  heading: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    color: '#888888',
  },
  text: {
    ...text.BODY,
    wordBreak: 'break-word',
  },
  boldHeading: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    letterSpacing: '0.02em',
    color: '#1c1c1c',
  },
  row: {
    padding: '16px 24px',
    flexWrap: 'nowrap',
  },
  col: {
    textAlign: 'right',
  },
  colMobile: {
    textAlign: 'left',
  },
  buttonRow: {
    background: '#F2FAFF',
    display: 'flex',
  },
  buttonRowMobile: {
    background: '#F2FAFF',
    padding: sizes.SPACE_TINY,
    display: 'flex',
    flexDirection: 'column',
  },
  verticalSpace: {
    paddingTop: sizes.SPACE_TINY,
  },
  siteLink: {
    cursor: 'pointer',
    color: '#00AEEF',
  },
  controlsWrapper: {
    paddingRight: sizes.SPACE_MEDIUM,
  },
  mobileBox: {
    marginTop: sizes.SPACE_MEDIUM,
    border: '1px solid #ECECEC',
    boxSizing: 'border-box',
    borderRadius: '3px',
    padding: sizes.SPACE_TINY,
  },
};
function PoolDetails({ pool, hasStake }) {
  const {
    staked, name, stakeWeight, capacity, address, url, performance,
  } = pool;
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mobile = !screens.md;
  return (
    <div>
      {mobile && (
      <Row align="middle" justify="space-between">
        <Col xs={12}><PoolName name={pool.name} logo={pool.logo} style={{ fontSize: '14px', fontWeight: 'bold' }} /></Col>
        {hasStake && <Col xs={12} style={styles.controlsWrapper}><HomeStakeControls pool={pool} /></Col>}
      </Row>
      )}
      <Row gutter={[0, 16]} style={mobile ? styles.mobileBox : styles.row}>
        <Col flex="auto">
          <div style={styles.heading}>Pool Details</div>
          <div style={{ ...styles.text, ...styles.verticalSpace }}>
            Website:
            {' '}
            <span style={styles.siteLink} onClick={(event) => window.open(url)}>{url}</span>
          </div>
          <div style={{ ...styles.text, ...styles.verticalSpace }}>
            Address:
            {' '}
            {address}
          </div>
        </Col>
        <Col style={mobile ? styles.colMobile : styles.col} xs={12} lg={4}>
          <div style={styles.heading}>Stake Weight</div>
          <div>
            {`${formatAionValue(100 * stakeWeight)}%`}
          </div>
          <div style={{ ...styles.heading, ...styles.verticalSpace }}>
            Performance
          </div>
          <div>
            {`${parseInt(performance.value * 100, 10)}%`}
          </div>
        </Col>
        <Col style={mobile ? styles.colMobile : styles.col} xs={12} lg={4}>
          <div style={styles.heading}>Total Staked</div>
          <div>
            <Balance
              style={{ ...styles.verticalSpace }}
              type="medium"
              value={staked}
            />
          </div>
          <div style={{ ...styles.heading, ...styles.verticalSpace }}>
            Remaining Capacity
          </div>
          <div>
            <Balance
              style={{ ...styles.verticalSpace }}
              type="medium"
              value={capacity}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[{ xs: 10 }, { xs: 10 }]} style={{ ...(mobile ? styles.buttonRowMobile : styles.buttonRow), ...(!mobile && styles.row) }}>
        <Col style={{ flexGrow: 1 }}>
          <div style={styles.boldHeading}>
            Interested in Staking with this Pool?
          </div>
          <div style={styles.text}>
            Start staking your Aion to
            {' '}
            {name}
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            block={mobile}
            style={{ marginRight: mobile ? '0px' : '10px' }}
            onClick={() => {
              sendAmplitudeData(strings.STAKE_TO_THIS_POOL);
              if (account) {
                dispatch(
                  toggleUnstakeFundsModal({
                    ...pool,
                    operationType: RAW_TX_TYPE.DELEGATE,
                  }),
                );
              } else dispatch(toggleLoginModal());
            }}
          >
            Stake to this pool
          </Button>
        </Col>
        <Col>
          {hasStake ? (
            <Button
              type="outline"
              style={{ background: 'transparent' }}
              block={mobile}
              onClick={() => {
                sendAmplitudeData(strings.UNSTAKE_TO_THIS_POOL);
                dispatch(toggleUnstakeFundsModal({
                  ...pool,
                  operationType: RAW_TX_TYPE.UNDELEGATE,
                }));
              }}
            >
              Unstake from this pool
            </Button>
          ) : (
            <Button
              type="outline"
              style={{ background: 'transparent' }}
              block={mobile}
              onClick={() => {
                sendAmplitudeData(strings.TRANSFER_TO_THIS_POOL);
                if (account) {
                  dispatch(toggleUnstakeFundsModal({
                    ...pool,
                    operationType: RAW_TX_TYPE.MOVE_STAKE,
                  }));
                } else dispatch(toggleLoginModal());
              }}
            >
              Transfer to this pool
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}
PoolDetails.defaultProps = {
  hasStake: false,
};
PoolDetails.propTypes = {
  pool: PropTypes.object.isRequired,
  hasStake: PropTypes.bool,
};

export default PoolDetails;
