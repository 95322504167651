import React, { useState } from 'react';
import { Input, Tag, Switch } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { text, sizes, elements } from '../../../constants';
import Button from '../../Button';
import WarningIcon from '../../../assets/icons/ic_exclamation_warning_filled.svg';
import TransactionHelper from '../../../utils/TransactionHelper';

// const MNEMONIC_SAMPLE =
//   'snap minute pulp paddle struggle twenty mountain reject witness close clay friend';
const LONG_MNEMONIC_SAMPLE = 'half fortune language usual cigar educate angry cargo boost siege solid boy pupil wealth asthma dose crop strong wedding veteran symbol sweet milk journey';
const USE_SAMPLE = process.env.REACT_APP_MOCK_LOGIN_STRINGS === 'true';

const styles = {
  root: {
    width: '677px',
    maxWidth: '900px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rootMobile: {
    width: '100%',
  },
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
    marginBottom: '50px',
  },
  lable: {
    ...elements.INPUT_LABLE,
    marginTop: sizes.SPACE_SMALL,
  },
  buttonLayout: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row-reverse',
    marginTop: sizes.SPACE_MEDIUM,
  },
  tagWrapper: {
    marginTop: sizes.SPACE_SMALL,
    marginBottom: sizes.SPACE_LARGE,
  },
  words: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #CFCFCF',
    borderRadius: '3px',
    marginTop: sizes.SPACE_TINY,
  },
  word: {
    width: '33.33%',
    display: 'flex',
    padding: sizes.SPACE_TINY,
  },
  wordInput: {
    color: '#1C1C1C',
    fontSize: '26px',
    borderBottom: '1px solid #CFCFCF',
  },
};
const MnemonicAccount = ({ next }) => {
  const screens = useBreakpoint();
  const mobile = !screens.md;
  const [longMnemonic, setLongMnemonig] = useState(false);
  const mnemonicLength = longMnemonic ? 24 : 12;
  const [words, setWords] = useState(
    USE_SAMPLE ? LONG_MNEMONIC_SAMPLE.split(' ') : Array(24).fill(''),
  );
  const [error, setError] = useState(false);
  return (
    <div style={mobile ? styles.rootMobile : styles.root}>
      <div style={styles.title}>Keystore File Sign In</div>
      <div style={styles.lable}>Mnemonic Phrase</div>
      <div>
        Use 24-word:&nbsp;
        <Switch size="small" checked={longMnemonic} onChange={setLongMnemonig} />
      </div>
      <div style={styles.words}>
        {words.slice(0, mnemonicLength).map((word, i) => (
          <div key={i} style={styles.word}>
            <Input
              size="large"
              type="text"
              bordered={false}
              prefix={(
                <span>
                  {i + 1}
                  .
                </span>
)}
              style={styles.wordInput}
              value={word}
              onChange={(e) => {
                words[i] = e.target.value.trim();
                setWords([...words]);
              }}
            />
          </div>
        ))}
      </div>
      {error && (
        <div style={styles.tagWrapper}>
          <Tag icon={<img src={WarningIcon} alt="warning" />} color="red">
            Unable to use mnemonic words, potentially incorrect spelling
          </Tag>
        </div>
      )}
      <div style={styles.buttonLayout}>
        <Button
          disabled={!TransactionHelper.isMnemonicValid(words.join(' '))}
          type="primary"
          onClick={async () => {
            try {
              const account = await TransactionHelper.accountFromMnemonic(
                words.slice(0, mnemonicLength).join(' '),
              );
              next(account);
            } catch (ex) {
              setError(true);
            }
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default MnemonicAccount;
