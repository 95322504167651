import React from 'react';
import PropTypes from 'prop-types';

function IconMyTx(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 -2 20 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.60415 7.66791L0.316059 4.80379C0.218774 4.73829 0.139061 4.64983 0.0839445 4.54619C0.0288282 4.44255 0 4.32692 0 4.20949C0 4.09206 0.0288282 3.97643 0.0839445 3.87279C0.139061 3.76915 0.218774 3.68068 0.316059 3.61518L4.60415 0.751063C4.76107 0.66673 4.94376 0.644127 5.11645 0.687681C5.28913 0.731234 5.43937 0.837807 5.53773 0.986526C5.63609 1.13525 5.6755 1.3154 5.64823 1.4917C5.62096 1.668 5.52898 1.82776 5.3903 1.93967L3.06758 3.49346H15.7175C15.907 3.49346 16.0888 3.5689 16.2228 3.70318C16.3568 3.83746 16.4321 4.01959 16.4321 4.20949C16.4321 4.39939 16.3568 4.58152 16.2228 4.7158C16.0888 4.85008 15.907 4.92552 15.7175 4.92552H3.06758L5.3903 6.4793C5.51704 6.56463 5.61318 6.68837 5.66462 6.83241C5.71606 6.97646 5.7201 7.13322 5.67615 7.27972C5.6322 7.42623 5.54257 7.55479 5.42039 7.64655C5.29821 7.73831 5.14992 7.78846 4.99723 7.78964C4.85672 7.79125 4.71924 7.74868 4.60415 7.66791ZM19.6839 11.4915L15.3958 8.6274C15.3184 8.5649 15.2288 8.51928 15.1328 8.49346C15.0367 8.46764 14.9363 8.46218 14.8381 8.47744C14.7398 8.49269 14.6458 8.52833 14.5621 8.58206C14.4783 8.6358 14.4067 8.70645 14.3518 8.7895C14.2968 8.87255 14.2598 8.96617 14.2431 9.06437C14.2263 9.16258 14.2302 9.26321 14.2545 9.35982C14.2787 9.45644 14.3229 9.54691 14.384 9.62546C14.4452 9.70401 14.5221 9.76891 14.6097 9.816L16.9324 11.3698H4.28254C4.093 11.3698 3.91122 11.4452 3.77719 11.5795C3.64316 11.7138 3.56786 11.8959 3.56786 12.0858C3.56786 12.2757 3.64316 12.4578 3.77719 12.5921C3.91122 12.7264 4.093 12.8019 4.28254 12.8019H16.9324L14.6097 14.3556C14.483 14.441 14.3868 14.5647 14.3354 14.7087C14.2839 14.8528 14.2799 15.0095 14.3239 15.1561C14.3678 15.3026 14.4574 15.4311 14.5796 15.5229C14.7018 15.6146 14.8501 15.6648 15.0028 15.666C15.1433 15.6676 15.2808 15.625 15.3958 15.5442L19.6839 12.6801C19.7812 12.6146 19.8609 12.5262 19.9161 12.4225C19.9712 12.3189 20 12.2033 20 12.0858C20 11.9684 19.9712 11.8528 19.9161 11.7491C19.8609 11.6455 19.7812 11.557 19.6839 11.4915Z"
        fill={color}
      />
    </svg>
  );
}

IconMyTx.propTypes = {
  color: PropTypes.string.isRequired,
};

export default IconMyTx;
