import {
  ADD_PENDING_TX,
  REMOVE_PENDING_TX,
  CLEAR_PENDING_TX,
} from '../actions/pendingTransactions';
import ReduxUtils from '../utils/ReduxUtils';

const initialState = [];

export default ReduxUtils.createReducer(initialState, {
  ...ReduxUtils.createArrayHandler(ADD_PENDING_TX, 'txs'),
  [ADD_PENDING_TX]: (state, action) => [...state, action.txs],
  [REMOVE_PENDING_TX]: (state, action) => [
    ...state.filter((pendingTx) => pendingTx.hash !== action.txHash),
  ],
  [CLEAR_PENDING_TX]: () => [],
});
