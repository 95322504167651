import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeAllModals } from '../../../actions/modals';
import { setAccountAndFetchData } from '../../../actions/account';
import UnsecureWarning from '../UnsecureWarning';
import PrivateKeyAccount from './PrivateKeyAccount';

export default () => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  let content;
  switch (step) {
    case 0: {
      content = (
        <UnsecureWarning typeText="private key" next={() => setStep(1)} />
      );
      break;
    }
    case 1: {
      content = (
        <PrivateKeyAccount
          next={(account) => {
            dispatch(closeAllModals());
            dispatch(
              setAccountAndFetchData({
                address: account.address,
                pubKey: account.public_key,
                privateKey: account.private_key,
                type: 'privateKey',
              }),
            );
          }}
        />
      );
      break;
    }
    default: {
      break;
    }
  }
  return <div>{content}</div>;
};
