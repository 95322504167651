import React from 'react';
import { sizes } from '../constants';

const styles = {
  root: {
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10vh',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '34px',
    color: '#1C1C1C',
  },
  body: {
    fontSize: '18px',
    color: '#4F4F4F',
    marginTop: sizes.SPACE_SMALL,
  },
  browsers: {
    fontSize: '14px',
    color: '#4F4F4F',
    marginTop: sizes.SPACE_MEDIUM,
  },
  link: {
    color: '#00AEEF',
  },
};

export default () => (
  <div style={styles.root}>
    <div style={styles.title}>Unsupported Browser</div>
    <div style={styles.body}>
      You’re almost there — we don’t currently support
      your browser at the moment. We recommend using a
      chromium based browser instead.
    </div>
    <div style={styles.browsers}>
      Popular chromium based browsers :
      {' '}
      <a style={styles.link} href="https://www.google.com/intl/en_ca/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a>
      ,
      {' '}
      <a style={styles.link} href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noopener noreferrer">Mircosoft Edge</a>
      , and
      {' '}
      <a style={styles.link} href="https://brave.com/" target="_blank" rel="noopener noreferrer">Brave</a>
    </div>
  </div>
);
