import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';
import { removePendingTransaction } from './pendingTransactions';

export const ACCOUNT_RECENT_TRANSACTIONS_UPDATE = 'ACCOUNT_RECENT_TRANSACTIONS_UPDATE';

export const updateAccountTransactions = ReduxUtils.createAction(
  ACCOUNT_RECENT_TRANSACTIONS_UPDATE,
  'accountRecentTransactions',
);

export const fetchAccountRecentTransactions = (page = 0) => async (
  dispatch,
  getState,
  { apiService },
) => {
  const { account, pendingTransactions, accountRecentTransactions } = getState();
  dispatch(
    updateAccountTransactions(
      accountRecentTransactions.data
        ? new DataModel(accountRecentTransactions.data)
        : DataModel.initLoading(),
    ),
  );
  try {
    const outgoingResp = await apiService.fetchAccountTransactions(
      account.address,
      page,
    );
    let mergedTxs = [];
    const transactions = outgoingResp?.transactionArray?.[0] ?? [];
    const totalPages = outgoingResp?.totalPages ?? 0;
    if (page === 0) {
      const matchedPending = pendingTransactions.filter(
        (pending) =>
          transactions.filter((tx) => tx.hash === pending.hash).length > 0,
      );
      matchedPending.forEach((pending) => {
        setTimeout(() => dispatch(removePendingTransaction(pending.hash), 1));
      });
      const cleanPendingTransactions = pendingTransactions.filter((pending) =>
        matchedPending.filter((tx) => tx.hash === pending.hash));
      mergedTxs = [...cleanPendingTransactions, ...transactions];
    } else {
      mergedTxs = [...transactions];
    }
    dispatch(updateAccountTransactions(new DataModel({ transactions: mergedTxs, totalPages })));
  } catch (ex) {
    console.trace(ex);
    dispatch(updateAccountTransactions(DataModel.error(0, ex.message)));
  }
};
