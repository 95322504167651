/* eslint-disable no-await-in-loop */
import BigNumber from 'bignumber.js';
import ReduxUtils from '../utils/ReduxUtils';
import { fetchStakingTransactions } from './stakingTransactions';
import { fetchBalance } from './balance';
import { fetchAllDelegationData } from './delegations';
import { fetchAccountRecentTransactions } from './accountRecentTransactions';
import { fetchNonce } from './nonce';
import { fetchStakingFinalizationTransactions } from './stakingFinalizationTransactions';
import DataModel from '../models/DataModel';
import { updatePools } from './pools';
import { clearPendingTransaction } from './pendingTransactions';

export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';

export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';

export const updateAccount = ReduxUtils.createAction(ACCOUNT_UPDATE, 'account');

export const logoutAccount = ReduxUtils.createAction(ACCOUNT_LOGOUT, 'account');

let timerId = null;
export const fetchDataIfAccountExists = () => async (dispatch, getState) => {
  const { account } = getState();
  if (account) {
    dispatch(fetchNonce());
    dispatch(fetchBalance());
    dispatch(fetchAccountRecentTransactions());
    dispatch(fetchStakingTransactions());
    dispatch(fetchStakingFinalizationTransactions());
    dispatch(fetchAllDelegationData());
  }
};

export const setAccountAndFetchData = (account) => async (dispatch) => {
  clearTimeout(timerId);
  dispatch(updateAccount(account));
  dispatch(clearPendingTransaction());
  dispatch(fetchDataIfAccountExists());
  timerId = setInterval(() => {
    dispatch(fetchDataIfAccountExists());
  }, 10000);
};

export const setAccountToLoggedOut = () => async (dispatch) => {
  clearTimeout(timerId);
  dispatch(logoutAccount());
  dispatch(clearPendingTransaction());
  dispatch(updatePools(DataModel.initLoading()));
};

export const fetchPastAccountsWithBalances = () => async (dispatch, getState, { apiService }) => {
  const { account } = getState();
  const { pastAccounts } = account;
  for (let i = 0; i < pastAccounts.length; i += 1) {
    const acct = pastAccounts[i];
    let bal;
    try {
      bal = await apiService.fetchBalance(acct.address);
      const bn = new BigNumber(bal.result);
      const value = bn.shiftedBy(-18);
      acct.balance = value;
    } catch (ex) {
      console.trace(ex);
      acct.balance = new BigNumber(0);
    }
  }
  return pastAccounts;
};
