/* eslint-disable react/prop-types */
import React from 'react';

export default ({ color = '#1C1C1C', style }) => (
  <svg
    style={style}
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M89.75 179.53C101.044 179.53 110.2 170.374 110.2 159.08C110.2 147.786 101.044 138.63 89.75 138.63C78.4558 138.63 69.3 147.786 69.3 159.08C69.3 170.374 78.4558 179.53 89.75 179.53Z"
      fill={color}
    />
    <path
      d="M112.38 0H67.13L4 177.72H42.23L89.75 43.93L137.28 177.72H175.51L112.38 0Z"
      fill={color}
    />
  </svg>
);
