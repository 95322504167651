import {
  TOGGLE_RECEIVE_FUNDS,
  TOGGLE_SEND_FUNDS,
  TOGGLE_UNSTAKE_FUNDS,
  TOGGLE_ACCOUNT_MODAL,
  TOGGLE_LOGIN_MODAL,
  TOGGLE_ADDRESS_COPY_CONFIRMATION_MODAL,
  TOGGLE_POOL_DETAILS_MODAL,
  CLOSE_ALL_MODALS,
  MODAL_EXTRA_DATA,
} from '../actions/modals';
import ReduxUtils from '../utils/ReduxUtils';

const initialState = {
  showSendFundsModal: false,
  showReceiveFundsModal: false,
  showUnstakeFundsModal: false,
  showAccountModal: false,
  showAddressCopyModal: false,
  showTransferStakeModal: false,
  showPoolDetailsModal: false,
  showLoginModal: false,
  [MODAL_EXTRA_DATA]: null,
};

export default ReduxUtils.createReducer(initialState, {
  [TOGGLE_RECEIVE_FUNDS]: (state, action) => ({
    ...state,
    showReceiveFundsModal: !state.showReceiveFundsModal,
    [MODAL_EXTRA_DATA]: action[MODAL_EXTRA_DATA],
  }),
  [TOGGLE_SEND_FUNDS]: (state, action) => ({
    ...state,
    showSendFundsModal: !state.showSendFundsModal,
    [MODAL_EXTRA_DATA]: action[MODAL_EXTRA_DATA],
  }),
  [TOGGLE_UNSTAKE_FUNDS]: (state, action) => ({
    ...state,
    showUnstakeFundsModal: !state.showUnstakeFundsModal,
    [MODAL_EXTRA_DATA]: action[MODAL_EXTRA_DATA],
  }),
  [TOGGLE_ACCOUNT_MODAL]: (state, action) => ({
    ...state,
    showAccountModal: !state.showAccountModal,
    [MODAL_EXTRA_DATA]: action[MODAL_EXTRA_DATA],
  }),
  [TOGGLE_LOGIN_MODAL]: (state, action) => ({
    ...state,
    showLoginModal: !state.showLoginModal,
    [MODAL_EXTRA_DATA]: action[MODAL_EXTRA_DATA],
  }),
  [TOGGLE_ADDRESS_COPY_CONFIRMATION_MODAL]: (state, action) => ({
    ...state,
    showAddressCopyModal: !state.showAddressCopyModal,
    [MODAL_EXTRA_DATA]: action[MODAL_EXTRA_DATA],
  }),
  [TOGGLE_POOL_DETAILS_MODAL]: (state, action) => ({
    ...state,
    showPoolDetailsModal: !state.showPoolDetailsModal,
    [MODAL_EXTRA_DATA]: action[MODAL_EXTRA_DATA],
  }),
  [CLOSE_ALL_MODALS]: (state) => ({
    ...state,
    showSendFundsModal: false,
    showReceiveFundsModal: false,
    showUnstakeFundsModal: false,
    showAccountModal: false,
    showAddressCopyModal: false,
    showPoolDetailsModal: false,
    showTransferStakeModal: false,
    showLoginModal: false,
    [MODAL_EXTRA_DATA]: null,
  }),
});
