import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

export const DELEGATIONS_UPDATE = 'DELEGATIONS_UPDATE';
export const STAKED_BALANCE_UPDATE = 'STAKED_BALANCE_UPDATE';
export const REWARDS_BALANCE_UPDATE = 'REWARDS_BALANCE_UPDATE';

export const updateDelegations = ReduxUtils.createAction(
  DELEGATIONS_UPDATE,
  'delegations',
);

export const updateStakedBalance = ReduxUtils.createAction(
  STAKED_BALANCE_UPDATE,
  'stakedBalance',
);

export const updateRewardsBalance = ReduxUtils.createAction(
  REWARDS_BALANCE_UPDATE,
  'rewardsBalance',
);

export const fetchAllDelegationData = () => async (
  dispatch,
  getState,
  { socketService },
) => {
  const { account, delegations } = getState();
  dispatch(
    updateDelegations(
      delegations.data
        ? new DataModel(delegations.data)
        : DataModel.initLoading(),
    ),
  );
  try {
    socketService.wsSend({
      method: 'delegations',
      params: [account.address, 0, 50], // todo use parameters for page data
    });
  } catch (ex) {
    dispatch(updateDelegations(DataModel.error(0, ex.message)));
  }
};
