import { ACCOUNT_UPDATE, ACCOUNT_LOGOUT } from '../actions/account';
import ReduxUtils from '../utils/ReduxUtils';
import { pushIfNotContains } from '../utils/utils';

const initialState = null;

export default ReduxUtils.createReducer(initialState, {
  [ACCOUNT_UPDATE]: (state, action) => ({
    ...state,
    ...action.account,
    pastAccounts: state?.pastAccounts
      ? pushIfNotContains(state.pastAccounts, action.account, 'address')
      : [action.account],
  }),
  [ACCOUNT_LOGOUT]: () => (null),
});
