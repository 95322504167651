/* eslint-disable no-param-reassign */
import BigNumber from 'bignumber.js';
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';
import { numbers } from '../constants';

export const SEARCHED_STAKING_TRANSACTIONS = 'SEARCHED_STAKING_TRANSACTIONS';

export const updateSearchedStakingTransactions = ReduxUtils.createAction(
  SEARCHED_STAKING_TRANSACTIONS,
  'searchedStakingTransactions',
);

export const stakingTransactionsRequest = () => async (
  dispatch,
  getState,
  { apiService },
) => {
  const { searchedAccount } = getState();
  dispatch(updateSearchedStakingTransactions(DataModel.initLoading()));
  try {
    const outgoingResp = await apiService.fetchStakingTransactions(
      searchedAccount.address,
      true,
    );
    const { BN_AION } = numbers;
    outgoingResp.result.data.forEach((v) => {
      v.amount = new BigNumber(v.amount).dividedBy(BN_AION);
      if (v.amount.toString() === 'NaN') v.amount = new BigNumber(0);
    });

    dispatch(
      updateSearchedStakingTransactions(
        new DataModel(outgoingResp.result.data),
      ),
    );
  } catch (ex) {
    console.trace(ex);
    dispatch(updateSearchedStakingTransactions(DataModel.error(0, ex.message)));
  }
};
