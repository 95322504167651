import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  decoration,
} from '../constants';

const styles = {
  root: {
    display: 'contents',
  },
  appSearch: {
    ...decoration.SHADOW_SMALL,
    maxWidth: '550px',
  },
};

function SearchInput() {
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();

  return (
    <form
      style={styles.root}
      onSubmit={(e) => {
        e.preventDefault();
        history.push(`/search/${searchValue.trim()}`);
      }}
    >
      <Input
        style={styles.appSearch}
        size="large"
        placeholder="Search for an Address or Transaction"
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        prefix={<SearchOutlined />}
      />
    </form>
  );
}

export default SearchInput;
