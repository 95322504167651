import React, { useState } from 'react';
import {
  Table, Grid, Tag, ConfigProvider,
} from 'antd';
import './HomeStakesList.less';
import glamorous from 'glamorous';
import { useSelector, useDispatch } from 'react-redux';
import { CaretDownFilled } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import { elements, strings, text } from '../constants';
import Balance from './Balance';
import HomeStakeControls from './HomeStakeControls';
import { formatAionValue, hexColorForPerformance } from '../utils/utils';
import PoolDetails from './PoolDetails';
import EmptyState from './EmptyState';
import { togglePoolDetailsModal } from '../actions/modals';
import PoolName from './PoolName';
import { sendAmplitudeData } from '../utils/Amplitude';

const { useBreakpoint } = Grid;

const styles = {
  ...elements.TABLE,
  root: {
    color: '#222222',
  },
  name: {
    ...text.BODY_BOLD,
  },
  performance: glamorous.div((props) => ({
    '&::before': {
      ...elements.PERFORMANCE,
      background: hexColorForPerformance(props.performance),
    },
  })),
  downBox: elements.DOWNBOX,
  downBoxSelected: {
    background: '#F2F2F2',
  },
  poolDetails: {
    border: '1px solid #ECECEC',
    boxSizing: 'border-box',
    borderRadius: '5px',
    margin: 0,
  },
  poolName: {
    width: '200px',
    display: 'flex',
    alignItems: 'center',
  },
};

const stylesMobile = {
  root: {
    color: '#222222',
  },
  name: {
    ...text.BODY_BOLD,
  },
  performance: glamorous.div((props) => ({
    '&::before': {
      ...elements.PERFORMANCE,
      background: hexColorForPerformance(props.performance),
    },
  })),
  downBox: elements.DOWNBOX,
  downBoxSelected: {
    background: '#F2F2F2',
  },
  poolDetails: {
    border: '1px solid #ECECEC',
    boxSizing: 'border-box',
    borderRadius: '5px',
    margin: 0,
  },
  poolName: {
    width: '110px',
    textAlign: 'start',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ...elements.TABLE_MOBILE,
};

const columns = [
  (styleToUse) => ({
    title: 'Name',
    dataIndex: 'name',
    className: 'nameCell',
    sorter: {
      compare: (a, b) => a.name.text.localeCompare(b.name.text),
    },
    render: (value) => (
      <div
        style={{
          ...styleToUse.borderTopBottom,
          ...styleToUse.borderLeft,
        }}
      >
        <PoolName
          name={value.text}
          logo={value.logo}
          style={styleToUse.poolName}
        />
      </div>
    ),
  }),
  (styleToUse) => ({
    title: 'Status',
    dataIndex: 'status',
    render: (value) => (
      <div style={styleToUse.borderTopBottom}>
        {value ? (
          <Tag color="green">ACTIVE</Tag>
        ) : (
          <Tag color="red">INACTIVE</Tag>
        )}
      </div>
    ),
  }),
  (styleToUse) => ({
    title: 'Staked',
    dataIndex: 'staked',
    sorter: {
      compare: (a, b) => a.staked - b.staked,
    },
    render: (value) => (
      <div style={styleToUse.borderTopBottom}>
        <Balance type="medium" value={value} />
      </div>
    ),
  }),
  (styleToUse, shouldSort) => ({
    title: 'Rewards',
    dataIndex: 'rewards',
    defaultSortOrder: shouldSort ? 'descend' : null,
    sorter: {
      compare: (a, b) => a.rewards - b.rewards,
    },
    render: (value) => (
      <div style={styleToUse.borderTopBottom}>
        <Balance type="medium" value={value} />
      </div>
    ),
  }),
  (styleToUse, shouldSort) => ({
    title: 'Fees',
    dataIndex: 'fees',
    defaultSortOrder: shouldSort ? 'descend' : null,
    sorter: {
      compare: (a, b) => a.fees - b.fees,
    },
    render: (value) => (
      <div
        style={{
          ...styleToUse.borderTopBottom,
        }}
      >
        {`${formatAionValue(value)}%`}
      </div>
    ),
  }),
  (styleToUse) => ({
    title: styleToUse === styles ? 'Performance' : 'Perf.',
    dataIndex: 'performance',
    sorter: {
      compare: (a, b) => a.performance.value - b.performance.value,
    },
    render: (value) => (
      <styleToUse.performance
        performance={value.value}
        style={{
          ...styleToUse.borderTopBottom,
          ...(value?.mobile ? styleToUse.borderRight : null),
        }}
      >
        {`${parseInt(value.value * 100, 10)}%`}
      </styleToUse.performance>
    ),
  }),
  (styleToUse) => ({
    title: '',
    dataIndex: 'controls',
    render: ({ pool, autoStake }) => (
      <div style={{ ...styleToUse.borderTopBottom }}>
        <HomeStakeControls
          pool={{ ...pool, logo: pool.name.logo, name: pool.name.text }}
          autoStake={autoStake}
        />
      </div>
    ),
  }),
];
function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}
const HomeStakesList = ({ onStartStaking, filterIndex }) => {
  const delegations = useSelector((state) => state.delegations);
  const poolLogos = useSelector((state) => state.poolLogos);
  const pools = useSelector((state) => state.pools);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  const screens = useBreakpoint();
  const mobile = !screens.xl;

  if (redirect && !onStartStaking) {
    return <Redirect to={redirect} />;
  }
  if (redirect && onStartStaking) {
    onStartStaking();
  }
  let columnSet = columns;
  if (mobile) {
    columnSet = [
      columns[0](stylesMobile, filterIndex === 0), // name
      columns[1](stylesMobile, filterIndex === 1), // status
      columns[4](stylesMobile, filterIndex === 4), // fees
      columns[5](stylesMobile, filterIndex === 5), // performance
    ];
  } else {
    columnSet = [
      columns[0](styles, filterIndex === 0),
      columns[1](styles, filterIndex === 1),
      columns[2](styles, filterIndex === 2),
      columns[3](styles, filterIndex === 3),
      columns[4](styles, filterIndex === 4),
      columns[5](styles, filterIndex === 5),
      columns[6](styles, filterIndex === 6),
    ];
  }

  const data = [];
  if (delegations.data) {
    Object.keys(delegations.data).forEach((key) => {
      const delegation = delegations.data[key];
      const pool = pools.data?.find((p) => p.address === key);
      if (pool) {
        pool.fee.mobile = mobile;
      }
      const logo = poolLogos.data?.find((l) => l.address === key)?.logo;
      const poolData = {
        key,
        logo,
        name: { logo, text: pool?.meta_name },
        status: pool?.calculate_active,
        staked: delegation.stake,
        fees: pool?.fee,
        performance: { value: pool?.performance, mobile },
        address: pool?.address,
        url: pool?.meta_url,
        selfBond: pool?.stake_self,
        stakeWeight: pool?.stake_weight,
        rewards: delegation.rewards,
        capacity: pool?.capacity,
      };

      data.push({
        ...poolData,
        controls: {
          pool: poolData,
          autoStake: delegation.auto_delegate_rewards,
        },
      });
    });
  }
  data.sort((a, b) => a.name?.text?.localeCompare(b.name?.text));
  return (
    <ConfigProvider
      renderEmpty={() => (
        <EmptyState
          title="You’re not currently staking to any pools"
          description={
            <span>Start earning interest by staking your account balance</span>
          }
          buttonText="Start Staking"
          onClick={() => {
            sendAmplitudeData(strings.START_STAKING);
            setRedirect('/staking?tab=2');
          }}
        />
      )}
    >
      <Table
        style={styles.tableRoot}
        className="my-table"
        rowClassName="my-stake-row "
        columns={columnSet}
        dataSource={data}
        onChange={onChange}
        pagination={false}
        loading={delegations.loading}
        onRow={
          mobile
            ? (record, rowIndex) => ({
              onClick: (event) => {
                dispatch(
                  togglePoolDetailsModal({
                    pool: {
                      ...record,
                      logo: record.name.logo,
                      name: record.name.text,
                    },
                    hasStake: true,
                  }),
                );
              },
            })
            : null
        }
        expandable={
          !mobile && {
            expandRowByClick: true,
            expandedRowRender: (pool) => (
              <div style={styles.poolDetails}>
                <PoolDetails
                  pool={{ ...pool, logo: pool.name.logo, name: pool.name.text }}
                  hasStake
                />
              </div>
            ),
            rowExpandable: () => true,
            expandIcon: ({ expanded, onExpand, record }) => (
              <div
                role="presentation"
                style={{
                  ...styles.borderTopBottom,
                  ...styles.borderRight,
                }}
                onKeyPress={(e) => onExpand(record, e)}
                onClick={(e) => onExpand(record, e)}
              >
                <CaretDownFilled
                  style={{
                    ...styles.downBox,
                    ...(expanded ? styles.downBoxSelected : null),
                  }}
                />
              </div>
            ),
          }
        }
        expandIconColumnIndex={columnSet.length}
      />
    </ConfigProvider>
  );
};

HomeStakesList.propTypes = {};

export default HomeStakesList;
