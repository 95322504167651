import { SEARCHED_STAKING_TRANSACTIONS } from '../actions/searchedStakingTransactions';
import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

const initialState = new DataModel();

export default ReduxUtils.createReducer(initialState, {
  ...ReduxUtils.createObjectHandler(
    SEARCHED_STAKING_TRANSACTIONS,
    'searchedStakingTransactions',
  ),
});
