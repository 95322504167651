import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, Pagination, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { text, sizes } from '../../../constants';
import Button from '../../Button';
import Balance from '../../Balance';
import { fetchLedgerAccounts } from '../../../actions/ledger';

const styles = {
  root: {
    width: '80vw',
    maxWidth: '900px',
  },
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
    marginBottom: sizes.SPACE_MEDIUM,
  },
  row: {
    border: '2px solid #ECECEC',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0',
    padding: sizes.SPACE_SMALL,
    cursor: 'pointer',
  },
  addressColumn: {
    width: '70%',
  },
  balanceColumn: {
    width: '25%',
  },
  radio: {
    width: '5%',
  },
  lable: {
    ...text.HEADING_SMALL,
    marginBottom: sizes.SPACE_TINY,
  },
  address: {
    ...text.BODY,
    fontWeight: 'bold',
  },
  buttonLayout: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: sizes.SPACE_LARGE,
  },
  paginationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: '110px',
    color: '#CFCFCF',
  },
  spinnerLayout: {
    minHeight: 520,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

const LedgerAccounts = ({ next, onError, accountIndex = 0 }) => {
  const PAGE_SIZE = 5;
  const [page, setPage] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(accountIndex);
  const accounts = useSelector((state) => state.ledger.accounts);
  const dispatch = useDispatch();
  if (page === 0) {
    setPage(1);
    dispatch(fetchLedgerAccounts(0, PAGE_SIZE));
  }
  return (
    <div style={styles.root}>
      <div style={styles.title}>Select your Address</div>
      {!accounts && (
        <div style={styles.spinnerLayout}>
          {' '}
          <Spin size="large" />
          <br />
          <div>We&apos;re connecting to your Ledger wallet...</div>
        </div>
      )}
      {accounts &&
        accounts.map((account, i) => (
          <div
            key={i}
            style={styles.row}
            onClick={() => {
              setSelectedIndex(account.index);
            }}
          >
            <div style={styles.addressColumn}>
              <div style={styles.lable}>{account.index}</div>
              <div style={styles.address}>{account.address}</div>
            </div>
            <div style={styles.balanceColumn}>
              <div style={styles.lable}>Total Aion</div>
              <div style={styles.address}>
                <Balance value={account.balance} />
              </div>
            </div>
            <Radio
              checked={selectedIndex === account.index}
              style={styles.radio}
            />
          </div>
        ))}
      <div style={styles.paginationWrapper}>
        <Pagination
          size="small"
          current={page}
          onChange={(p, pageSize) => {
            try {
              dispatch(fetchLedgerAccounts(p - 1, pageSize));
              setPage(p);
            } catch (ex) {
              onError();
            }
          }}
          pageSize={PAGE_SIZE}
          total={100}
          showSizeChanger={false}
        />
      </div>
      <div style={styles.buttonLayout}>
        <Button
          disabled={selectedIndex === null || accounts === null}
          type="primary"
          onClick={() => next(accounts[selectedIndex % PAGE_SIZE])}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
LedgerAccounts.propTypes = {
  next: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default LedgerAccounts;
