import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isChromium, isChrome, isEdgeChromium } from 'react-device-detect';
import './App.css';
import { Layout, Grid } from 'antd';
import MenuBar from './components/MenuBar';
import Home from './pages/Home';
import Staking from './pages/Staking';
import MyTransactions from './pages/MyTransactions';
import Search from './pages/Search';
import NavBar from './components/NavBar';
import { sizes } from './constants';
import AllPools from './pages/AllPools';
import BottomMenuBar from './components/BottomMenuBar';
import IconHome from './assets/icons/IconHome';
import IconStaking from './assets/icons/IconStaking';
import IconMyTx from './assets/icons/IconMyTx';
import IconSearch from './assets/icons/IconSearch';
import ModalsPortal from './components/ModalsPortal';
import Browser from './pages/Browser';

const { useBreakpoint } = Grid;

const { Header, Content, Sider } = Layout;
const MENU_ITEMS = [
  { text: 'Home', link: '/home', icon: IconHome },
  { text: 'Staking', link: '/staking', icon: IconStaking },
  {
    text: 'My Transactions',
    link: '/my-transactions',
    icon: IconMyTx,
  },
];
const MOBILE_EXTRA_MENU_ITEMS = [
  { text: 'Search', link: '/search', icon: IconSearch },
];
const styles = {
  menu: {
    height: '100vh',
    position: 'fixed',
    marginTop: sizes.NAV_HEIGHT,
    paddingTop: 30,
  },
  nav: {
    height: '100%',
    borderRight: 0,
  },
  content: {
    margin: '40px 91px 40px 321px',
    overflow: 'initial',
  },
  contentLoggedOut: {
    margin: '40px 91px 40px 91px',
    overflow: 'initial',
  },
  contentMobile: {
    margin: '30px 22px 100px 22px',
  },
  modal: {
    position: 'absolute',
  },
};

function App() {
  const screens = useBreakpoint();
  const mobile = !screens.md;
  const account = useSelector((state) => state.account);
  const isApprovedBrowser = isEdgeChromium || isChromium || isChrome;
  return (
    <div className="App">
      <Router>
        <Layout>
          <NavBar style={styles.nav} />
          <Layout>
            {account && (
              <>
                <Sider width="230" style={styles.menu}>
                  <MenuBar items={MENU_ITEMS} />
                </Sider>
                <div className="mobile-menu">
                  <BottomMenuBar
                    items={[...MENU_ITEMS, ...MOBILE_EXTRA_MENU_ITEMS]}
                  />
                </div>
              </>
            )}

            <Layout className="site-layout" style={{ ...(mobile && { backgroundColor: 'white' }) }}>
              <Header
                className="site-layout-background"
                style={{ padding: 0 }}
              />
              <Content style={mobile ? styles.contentMobile : (account ? styles.content : styles.contentLoggedOut)}>
                {isApprovedBrowser ? (
                  <Switch>
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/search/:slug?" component={Search} />
                    {account && (
                    <>
                      <Route exact path="/staking" component={Staking} />
                      <Route
                        exact
                        path="/my-transactions"
                        component={MyTransactions}
                      />
                      <Route exact path="/all-pools" component={AllPools} />
                    </>
                    )}
                    <Route exact path="/all-pools" component={AllPools} />
                    <Route>
                      <Redirect to="/home" />
                    </Route>
                  </Switch>
                ) : <Browser />}

              </Content>
            </Layout>
          </Layout>
        </Layout>
      </Router>
      <ModalsPortal />
    </div>
  );
}

export default App;
