import React, { useState } from 'react';
import {
  Card,
  Tabs,
  Table,
  Grid,
  Tag,
  Pagination,
  ConfigProvider,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { useHistory } from 'react-router-dom';
import { elements, text, sizes } from '../constants';
import {
  formatAddress, formatDate, formatAionDynamicDecimal, formatAddressEnd, formatAionValueToShort,
} from '../utils/utils';
import Balance from './Balance';
import DataModel from '../models/DataModel';
import UpArrow from '../assets/icons/ic_arrow_up.svg';
import DownArrow from '../assets/icons/ic_arrow_down.svg';

import './HomeTransactions.less';

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const TX_TYPES = Object.freeze({
  IN: Symbol('IN'),
  OUT: Symbol('OUT'),
  PENDING: Symbol('PENDING'),
});

const stakeTypeMap = {
  ADSDelegated: (amount, poolName) => `Delegated ${amount} AION to ${poolName}`,
  ADSWithdrew: (amount, poolName) =>
    `Withdrew ${amount} AION reward from ${poolName}`,
  ADSAutoRewardsDelegationEnabled: () => 'Auto delegation was enabled',
  ADSAutoRewardsDelegationDisabled: () => 'Auto delegation was disabled',
  AutoADSDelegated: (amount, poolName) =>
    `Auto-Delegated ${amount} AION to ${poolName}`,
  ADSDelegationTransferred: (amount, poolName, poolTo) =>
    `Initialized stake transfer of ${amount} AION from ${poolName} to ${poolTo}`,
  TransferFinalized: (amount, poolName, poolTo) =>
    `Stake transfer of ${amount} AION from ${poolName} to ${poolTo}`,
  UnbondFinalized: (amount, poolName) =>
    `Undelegated ${amount} AION from ${poolName}`,
  ADSUndelegated: (amount, poolName) =>
    `Initialized undelegation ${amount} AION from ${poolName}`,
};

const styles = {
  walletCard: {
    ...elements.CARD,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0',
    paddingRight: '0',
  },
  walletCardMobile: {
    marginTop: '-32px',
  },
  cardHeading: {
    ...text.HEADING_MEDIUM,
    flexGrow: '1',
    marginLeft: sizes.SPACE_SMALL,
    marginRight: sizes.SPACE_SMALL,
  },
  tabs: {
    marginTop: sizes.SPACE_SMALL,
    paddingLeft: sizes.SPACE_SMALL,
    paddingRight: sizes.SPACE_SMALL,
  },
  pagesLayout: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagesContainer: {
    position: 'absolute',
    transform: 'translateY(-150%)',
  },
  pagesContainerMobile: {
    position: 'absolute',
    transform: 'translateY(-88px)',
  },
  stakingEventType: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    maxWidth: '100px',
  },
};
const stakingColumns = [
  {
    title: 'Date',
    dataIndex: 'block_timestamp',
    sorter: {
      compare: (a, b) => a.date - b.date,
    },
    render: (value) => <div>{formatDate(value)}</div>,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    sorter: {
      compare: (a, b) => a.value - b.value,
    },
    render: (value) => <Balance type="small" value={value} />,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    sorter: {
      compare: (a, b) => (a.attr > b.attr) - (a.attr < b.attr),
    },
    render: (value) => <div style={styles.stakingEventType}>{value}</div>,
  },
  {
    title: 'Description',
    dataIndex: 'descr',
    render: (value) => <div>{value}</div>,
  },
];
const columns = [
  {
    title: 'Block',
    dataIndex: 'block',
    sorter: {
      compare: (a, b) => a.block - b.block,
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    sorter: {
      compare: (a, b) => a.date - b.date,
    },
    render: (value) => <div>{formatDate(value)}</div>,
  },
  (mobile) => ({
    title: 'From',
    dataIndex: 'from',
    render: (value) => <div>{mobile ? formatAddressEnd(value) : formatAddress(value)}</div>,
  }),
  {
    title: '',
    dataIndex: 'txType',
    render: (value) => (
      <>
        {value === TX_TYPES.IN && (
          <Tag icon={<img src={DownArrow} alt="incoming" />} color="blue">
            IN
          </Tag>
        )}
        {value === TX_TYPES.OUT && (
          <Tag icon={<img src={UpArrow} alt="outgoing" />} color="yellow">
            OUT
          </Tag>
        )}
        {value === TX_TYPES.PENDING && (
          <div>
            <Spin />
            {' '}
            Pending
          </div>
        )}
      </>
    ),
  },
  (mobile) => ({
    title: 'To',
    dataIndex: 'to',
    render: (value) => <div>{mobile ? formatAddressEnd(value) : formatAddress(value)}</div>,
  }),
  (mobile) => ({
    title: 'Value',
    dataIndex: 'value',
    sorter: {
      compare: (a, b) => a.value - b.value,
    },
    render: (value) => <Balance type="small" value={value} formatter={mobile ? formatAionValueToShort : formatAionDynamicDecimal} />,
  }),
  {
    title: 'Transaction Hash',
    dataIndex: 'hash',
    render: (value) => <div>{formatAddress(value)}</div>,
  },
];

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}
function onStakingTabChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}
function onStakingFinalizationTabChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}
function createDesctiptionFor(type, amount, pool, poolTo) {
  return stakeTypeMap[type]?.(amount, pool?.meta_name, poolTo?.meta_name);
}

const HomeTransactions = ({
  showPages,
  showStakingPages,
  selectorWalletTransactions,
  walletTransactionsSource,
  transactionsEmptyLayout,
  selectorStakingTransactions,
  stakingTransactionsSource,
  stakingTransactionsEmptyLayout,
  selectorStakingFinalizationTransactions,
  stakingFinilizationTransactionsSource,
  selectorAccount,
}) => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState('1');
  const [page, setPage] = useState(1);
  const [stakePage, setStakePage] = useState(1);
  const stakingTransactions = useSelector(
    (state) => state[selectorStakingTransactions],
  );
  const stakingFinalizationTransactions = useSelector(
    (state) => state[selectorStakingFinalizationTransactions],
  );
  const accountTransactions = useSelector(
    (state) => state[selectorWalletTransactions],
  );
  const account = useSelector((state) => state[selectorAccount]);
  if (!accountTransactions || DataModel.canFetch(accountTransactions)) {
    walletTransactionsSource(page - 1);
  }
  const transactions = accountTransactions.data?.transactions?.map?.(
    (tx, key) => ({
      key,
      block: tx.blockNumber,
      date: tx.timestamp,
      from: tx.from,
      txType: !tx.blockNumber
        ? TX_TYPES.PENDING
        : tx.from === account.address
          ? TX_TYPES.OUT
          : TX_TYPES.IN,
      to: tx.to,
      value: new BigNumber(tx.value).shiftedBy(-18),
      hash: tx.hash,
    }),
  );
  const pools = useSelector((state) => state.pools);
  const stackingTxWithDescription = stakingTransactions.data
    ?.map((tx, key) => {
      const descr = createDesctiptionFor(
        tx.type,
        tx.amount,
        pools.data?.find((pool) => pool.address === tx.pool),
        pools.data?.find((pool) => pool.address === tx.pool_to),
      );
      return { key, ...tx, descr };
    })
    .slice((stakePage - 1) * 10, (stakePage - 1) * 10 + 10);

  const stakingFinalizationTxWithDescription = stakingFinalizationTransactions?.data?.map(
    (tx, key) => {
      const descr = createDesctiptionFor(
        tx.kind,
        tx.amount,
        pools.data?.find((pool) => pool.address === tx.pool),
        pools.data?.find((pool) => pool.address === tx.pool_to),
      );
      return { key, ...tx, descr };
    },
  );
  const screens = useBreakpoint();
  const mobile = !screens.md;
  let columnSet;
  let stakingColumnSet;
  if (mobile) {
    columnSet = [columns[5](mobile), columns[2](mobile), columns[3], columns[4](mobile)];
  } else {
    columnSet = [columns[0], columns[1], columns[2](mobile), columns[3], columns[4](mobile), columns[5](mobile), columns[6]];
  }
  if (mobile) {
    stakingColumnSet = [stakingColumns[0], stakingColumns[1], stakingColumns[3]];
  } else {
    stakingColumnSet = [...stakingColumns];
  }
  return (
    <Card bodyStyle={{ ...styles.walletCard, ...(mobile && styles.walletCardMobile) }}>
      {!mobile && <div style={styles.cardHeading}>Recent Transactions</div>}
      <Tabs
        className="my-tabs"
        tabBarStyle={styles.tabs}
        size="large"
        defaultActiveKey="1"
        onChange={setSelectedTab}
        centered={mobile}
      >
        <TabPane tab="Wallet Transactions" key="1">
          {showPages &&
            !mobile &&
            selectedTab === '1' &&
            accountTransactions.data?.totalPages > 0 && (
              <div style={styles.pagesLayout}>
                <div
                  style={
                    mobile ? styles.pagesContainerMobile : styles.pagesContainer
                  }
                >
                  <Pagination
                    size="small"
                    current={page}
                    onChange={(p) => {
                      try {
                        walletTransactionsSource(p - 1);
                        setPage(p);
                      } catch (ex) {
                        console.trace(ex);
                      }
                    }}
                    pageSize={10}
                    total={(accountTransactions.data?.totalPages ?? 1) * 10}
                    showSizeChanger={false}
                  />
                </div>
              </div>
          )}
          <ConfigProvider renderEmpty={() => transactionsEmptyLayout}>
            <Table
              rowClassName="my-tx-row"
              className="my-table"
              columns={columnSet}
              dataSource={transactions}
              loading={accountTransactions.loading}
              onChange={onChange}
              pagination={false}
              onRow={(record, rowIndex) => ({
                onClick: (event) => {
                  history.push(`/search/${record.hash}`);
                },
              })}
            />
          </ConfigProvider>
        </TabPane>
        <TabPane
          tab={(
            <div style={{ textAlign: 'center' }}>
              Staking
              {' '}
              {mobile && <br />}
              Transactions
            </div>
          )}
          key="2"
        >
          {stakingTransactions.loading && <div>Loading...</div>}
          {stakingTransactions.error && (
          <div>Error loading staking transactions.</div>
          )}
          {stakingTransactions.data && (
          <>
            {showStakingPages &&
                !mobile &&
                selectedTab === '2' &&
                stakingTransactions?.data?.length > 9 && (
                  <div style={styles.pagesLayout}>
                    <div
                      style={
                        mobile
                          ? styles.pagesContainerMobile
                          : styles.pagesContainer
                      }
                    >
                      <Pagination
                        size="small"
                        current={stakePage}
                        onChange={(p) => {
                          try {
                            setStakePage(p);
                          } catch (ex) {
                            console.trace(ex);
                          }
                        }}
                        pageSize={10}
                        total={stakingTransactions?.data?.length ?? 0}
                        showSizeChanger={false}
                      />
                    </div>
                  </div>
            )}
            <ConfigProvider
              renderEmpty={() => stakingTransactionsEmptyLayout}
            >
              <Table
                rowClassName="my-tx-row"
                className="my-table"
                columns={stakingColumnSet}
                dataSource={stackingTxWithDescription}
                onChange={onStakingTabChange}
                pagination={false}
              />
            </ConfigProvider>
          </>
          )}
        </TabPane>
        {stakingFinalizationTxWithDescription?.length > 0 && (
          <TabPane tab="Pending Finalizations" key="3">
            <Table
              rowClassName="my-tx-row"
              className="my-table"
              columns={stakingColumns}
              dataSource={stakingFinalizationTxWithDescription}
              onChange={onStakingFinalizationTabChange}
              pagination={false}
            />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};

HomeTransactions.defaultProps = {
  showPages: false,
  showStakingPages: false,
};

HomeTransactions.propTypes = {
  showPages: PropTypes.bool,
  showStakingPages: PropTypes.bool,
};

export default HomeTransactions;
