import React, { useState, useEffect } from 'react';
import {
  Card, Grid, Row, Col, Tag,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { CopyOutlined } from '@ant-design/icons';
import {
  sizes, elements, text, numbers,
} from '../constants';
import Balance from './Balance';
import {
  formatDate,
  usePrevious,
  formatAionDynamicDecimal,
} from '../utils/utils';
import IconForward from '../assets/icons/ic_forward.svg';
import IconDownOutline from '../assets/icons/ic_caret_down_outline.svg';
import { fetchSingleTransaction } from '../actions/searchedSingleTransaction';
import SearchError from './SearchError';
import Button from './Button';
import IconCheckAlt from '../assets/icons/IconCheckAlt';
import IconCloseAlt from '../assets/icons/IconCloseAlt';

const { useBreakpoint } = Grid;

const styles = {
  title: {
    ...text.TITLE_MEDIUM,
    marginTop: sizes.SPACE_SMALL,
  },
  txText: {
    fontSize: 16,
    fontWeight: 'bold',
    overflowWrap: 'anywhere',
    marginTop: sizes.SPACE_SMALL,
    marginBottom: sizes.SPACE_TINY,
    color: '#595959',
  },
  commonValue: {
    fontSize: '18px',
    color: '#1C1C1C',
  },
  walletCard: {
    ...elements.CARD,
    display: 'flex',
    flexDirection: 'column',
    width: '800p',
    minHeight: '134px',
  },
  walletCardMobile: {
    ...elements.CARD_ROUND,
    display: 'flex',
    width: '800p',
    flexDirection: 'column',
  },
  cardHeading: {
    ...text.HEADING_MEDIUM,
    flexGrow: '1',
    marginBottom: sizes.SPACE_TINY,
  },
  cardHorizontalBody: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  cardTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingRight: '10px',
    flexGrow: 1,
  },
  cardText: {
    ...text.BODY,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  rowSpacing: {
    ...text.BODY,
    display: 'inline-flex',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  appHeading: {
    ...text.HEADING_LARGE,
    marginTop: sizes.SPACE_MEDIUM,
    flexGrow: '1',
    flexDirection: 'row',
  },
  addressDetails: {
    ...text.HEADING_LARGE,
    paddingRight: 600,
  },
  addressText: {
    fontSize: text.BODY_LIGHT,
    overflowWrap: 'anywhere',
  },
  singleElement: {
    flexDirection: 'column',
    paddingRight: 30,
  },
  statusBox: {
    border: '1px solid #2DC93C',
    borderRadius: 3,
    backgroundColor: '#E8FFE8',
    paddingRight: 30,
    justifyContent: 'right',
    marginLeft: 80,
    color: '#2DC93C',
  },
  singleBoxElement: {
    border: '2px solid rgb(212, 212, 212)',
    borderRadius: 10,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'column',
    minHeight: '55px',
  },
  expandIconUnclicked: {
    border: '1px solid rgb(212, 212, 212)',
    borderRadius: 4,
    marginLeft: 20,
  },
  spacing: {
    marginHorizontal: 100,
    marginLeft: 30,
    marginRight: 30,
  },
  iconWrapper: {
    padding: sizes.SPACE_SMALL,
    margin: 'auto',
  },
  successIcon: {
    padding: 4,
  },
  successText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  addressLink: {
    display: 'flex',
    cursor: 'pointer',
  },
  alertMessage: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  copyButton: {
    float: 'right',
    backgroundColor: '#00AEEF',
    borderColor: '#00AEEF',
    borderTopRightRadius: '10px',
  },
  tagStyle: {
    fontSize: '16px',
    textTransform: 'uppercase',
    display: 'inline-flex',
    verticalAlign: 'middle',

  },
  tagText: {
    letterSpacing: '-0.02em',
    padding: '4px',
    display: 'block',
  },
  logs: {
    maxHeight: '300px',
    overflowY: 'auto',
    height: '100%',
  },
};

const SearchTransactionHashSection = ({ transactionHash }) => {
  const screens = useBreakpoint();
  const history = useHistory();
  const mobile = !screens.md;
  const dispatch = useDispatch();
  const transactionResponse = useSelector(
    (state) => state.searchedSingleTransaction,
  );
  const [expendExtra, setExpendExtra] = useState(false);
  const transactionData = transactionResponse.data;
  const prevHash = usePrevious(transactionHash);

  useEffect(() => {
    if (!transactionResponse || transactionHash !== prevHash) {
      dispatch(fetchSingleTransaction(transactionHash));
    }
  }, [dispatch, transactionResponse, transactionHash, prevHash]);

  const statusComponent = transactionData?.error !== '' ? (
    <Tag style={styles.tagStyle} icon={<IconCloseAlt color="#FFB900" />} color="yellow">
      <span style={styles.tagText}>
&nbsp;
        {transactionData?.error}
      </span>
    </Tag>
  ) : (
    <Tag style={styles.tagStyle} icon={<IconCheckAlt color="#2DC93C" />} color="green">
      <span style={styles.tagText}>&nbsp;Success</span>
    </Tag>
  );

  return (
    <>
      {transactionResponse.loading && (
        <div>
          <ContentLoader viewBox="0 0 100 80">
            <rect x="0" y="0" rx="1" ry="1" width="10" height="3" />
            <rect x="0" y="10" rx="2" ry="2" width="32" height="13" />
            <rect x="33" y="10" rx="2" ry="2" width="32" height="13" />
            <rect x="66" y="10" rx="2" ry="2" width="32" height="13" />
            <rect x="0" y="30" rx="2" ry="2" width="100" height="80" />
          </ContentLoader>
        </div>
      )}
      {transactionResponse.error && <SearchError />}
      {transactionResponse.data && (
        <>
          <div style={styles.title}>Transaction</div>
          <div style={styles.txText}>{transactionData?.hash}</div>
          <Card
            bodyStyle={mobile ? styles.walletCardMobile : styles.walletCard}
          >
            <div style={styles.rowSpacing}>
              <Row style={{ width: '100%' }}>
                <Col>
                  <div style={styles.singleElement}>
                    <div style={styles.cardHeading}>Value</div>
                    <Balance
                      formatter={formatAionDynamicDecimal}
                      value={
                        new BigNumber(transactionData?.value).shiftedBy(-18) ||
                        0
                      }
                    />
                  </div>
                </Col>
                <Col flex="auto">
                  <div style={styles.singleElement}>
                    <div style={styles.cardHeading}>Date</div>
                    <div style={styles.commonValue}>
                      {formatDate(transactionData?.timestamp)}
                    </div>
                  </div>
                </Col>
                {!mobile && <div>{statusComponent}</div>}
              </Row>
            </div>

            <div style={styles.rowSpacing}>
              <Row gutter={[0, 16]} justify="center" align="middle">
                <Col xs={24} lg={12}>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        ...styles.singleBoxElement,
                        ...styles.addressLink,
                      }}
                      onClick={() => {
                        history.push(`/search/${transactionData?.from}`, {
                          back: transactionHash,
                        });
                      }}
                    >
                      <div style={styles.cardHeading}>From</div>
                      <div style={styles.addressText}>
                        {' '}
                        {transactionData?.from}
                        {' '}
                      </div>
                    </div>
                    {!mobile && (
                      <div style={styles.iconWrapper}>
                        <img src={IconForward} alt="to" />
                      </div>
                    )}
                  </div>
                </Col>

                <Col xs={24} lg={12}>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        ...styles.singleBoxElement,
                        ...styles.addressLink,
                      }}
                      onClick={() => {
                        history.push(`/search/${transactionData?.to}`, {
                          back: transactionHash,
                        });
                      }}
                    >
                      <div style={styles.cardHeading}>To</div>
                      <div style={styles.addressText}>
                        {' '}
                        {transactionData?.to}
                        {' '}
                      </div>
                    </div>
                    {!mobile && (
                      <div
                        style={{ ...styles.iconWrapper, cursor: 'pointer' }}
                        onClick={() => setExpendExtra(!expendExtra)}
                      >
                        <img src={IconDownOutline} alt="expand" />
                      </div>
                    )}
                  </div>
                </Col>
                {mobile && (
                  <Col style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>{statusComponent}</div>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => setExpendExtra(!expendExtra)}
                      >
                        <img src={IconDownOutline} alt="expand" />
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>

            {expendExtra && (
              <>
                <div style={styles.rowSpacing}>
                  <Row gutter={[0, 16]}>
                    <Col>
                      <div style={styles.singleElement}>
                        <div style={styles.cardHeading}>Transaction Cost</div>
                        <Balance
                          formatter={formatAionDynamicDecimal}
                          value={
                            new BigNumber(transactionData.gas)
                              .times(new BigNumber(transactionData.gasPrice))
                              .dividedBy(numbers.BN_AION) || 0
                          }
                        />
                        {' '}
                      </div>
                    </Col>
                    <Col>
                      <div style={styles.singleElement}>
                        <div style={styles.cardHeading}>Energy Cost</div>
                        {transactionData?.gasPrice}
                      </div>
                    </Col>
                    <Col>
                      <div style={styles.singleElement}>
                        <div style={styles.cardHeading}>Index</div>
                        {transactionData?.transactionIndex}
                      </div>
                    </Col>
                    <Col>
                      <div style={styles.singleElement}>
                        <div style={styles.cardHeading}>Nonce</div>
                        {transactionData?.nonce}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div style={styles.rowSpacing}>
                  <div style={styles.singleElement} />
                  <div style={styles.singleElement} />
                </div>
                <Row gutter={[16, mobile ? 32 : 16]}>
                  <Col xs={24} lg={12}>
                    <div style={styles.cardHeading}>Transaction Logs</div>
                    <div style={{ ...styles.singleBoxElement, ...styles.logs }}>
                      <div style={styles.addressText}>
                        <CopyToClipboard
                          style={styles.copyButton}
                          text={JSON.stringify(transactionData?.transactionLog)}
                        >
                          <Button
                            size="middle"
                            type="primary"
                          >
                            COPY
                            <CopyOutlined />
                          </Button>
                        </CopyToClipboard>
                        {JSON.stringify(transactionData?.transactionLog)}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={12}>
                    <div style={styles.cardHeading}>Input Logs</div>
                    <div style={{ ...styles.singleBoxElement, ...styles.logs }}>
                      <div style={styles.addressText}>
                        <CopyToClipboard
                          style={styles.copyButton}
                          text={transactionData?.input}
                        >
                          <Button
                            size="middle"
                            type="primary"
                          >
                            COPY
                            <CopyOutlined />
                          </Button>
                        </CopyToClipboard>

                        {transactionData?.input}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </>
      )}
    </>
  );
};

SearchTransactionHashSection.propTypes = {};

export default SearchTransactionHashSection;
