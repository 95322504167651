import ReduxUtils from '../utils/ReduxUtils';

export const ADD_PENDING_TX = 'ADD_PENDING_TX';
export const REMOVE_PENDING_TX = 'REMOVE_PENDING_TX';
export const CLEAR_PENDING_TX = 'CLEAR_PENDING_TX';

export const addPendingTransaction = ReduxUtils.createAction(
  ADD_PENDING_TX,
  'txs',
);

export const removePendingTransaction = ReduxUtils.createAction(
  REMOVE_PENDING_TX,
  'txHash',
);

export const clearPendingTransaction = ReduxUtils.createAction(
  CLEAR_PENDING_TX,
  'txs',
);
