import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

export const SEARCHED_ACCOUNT_TRANSACTIONS = 'SEARCHED_ACCOUNT_TRANSACTIONS';

export const updateSearchedAccountTransactions = ReduxUtils.createAction(
  SEARCHED_ACCOUNT_TRANSACTIONS,
  'searchedAccountTransactions',
);

export const transactionsRequest = (page = 0) => async (
  dispatch,
  getState,
  { apiService },
) => {
  const { searchedAccount } = getState();
  dispatch(updateSearchedAccountTransactions(DataModel.initLoading()));
  try {
    const outgoingResp = await apiService.fetchAccountTransactions(searchedAccount.address, page);
    const transactions = outgoingResp.transactionArray?.[0] ?? [];
    const totalPages = outgoingResp?.totalPages ?? 0;
    dispatch(updateSearchedAccountTransactions(new DataModel({ transactions, totalPages })));
  } catch (ex) {
    console.trace(ex);
    dispatch(updateSearchedAccountTransactions(DataModel.error(0, ex.message)));
  }
};
