const BigNumber = require('bignumber.js');

const FONT_BOLD = 'inherit';
const FONT_REGULAR = 'inherit';
module.exports = {
  numbers: {
    BN_GAS_USAGE_SEND: new BigNumber('2000000'),
    BN_GAS_USAGE_TRANSFER: new BigNumber('32000'),
    BN_GAS_PRICE: new BigNumber('11000000000'),
    BN_AION: new BigNumber('1000000000000000000'),
    PERFORMANCE_MAP: {
      EXCELLENT: { name: 'Excellent', value: 0.9, color: '#08de00' },
      MODERATE: { name: 'Moderate', value: 0.8, color: '#ffc400' },
      POOR: { name: 'Poor', value: 0, color: '#e84f4f' },
      NEW: { name: 'New', color: '#69c0ff' },
    },
    SEARCH_TERM_LENGTH: 66,
  },
  sizes: {
    NAV_HEIGHT: '66px',
    SPACE_LARGE: '66px',
    SPACE_MEDIUM: '24px',
    SPACE_SMALL: '16px',
    SPACE_TINY: '7px',
  },
  text: {
    TITLE_LARGE: {
      fontFamily: FONT_BOLD,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 34,
      lineHeight: '39px',
      letterSpacing: '-0.02em',
      color: '#222222',
    },
    TITLE_MEDIUM: {
      fontFamily: FONT_BOLD,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: '28px',
      color: '#222222',
    },
    HEADING_LARGE: {
      fontFamily: FONT_REGULAR,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '39px',
      letterSpacing: '-0.02em',
      color: '#6F6F6F',
      textTransform: 'uppercase',
    },
    HEADING_MEDIUM: {
      fontFamily: FONT_BOLD,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '14px',
      color: '#6F6F6F',
      textTransform: 'uppercase',
    },
    HEADING_SMALL: {
      fontFamily: FONT_BOLD,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 10,
      lineHeight: '11px',
      color: '#6F6F6F',
      textTransform: 'uppercase',
    },
    BALANCE: {
      fontFamily: FONT_REGULAR,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '23px',
      color: '#1C1C1C',
    },
    BODY: {
      fontFamily: FONT_REGULAR,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '20px',
      color: '#1C1C1C',
    },
    BODY_LARGE: {
      fontFamily: FONT_REGULAR,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 22,
      lineHeight: '25px',
      color: '#1C1C1C',
    },
    BODY_BOLD: {
      fontFamily: FONT_BOLD,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '20px',
      color: '#1C1C1C',
    },
    BODY_LIGHT: {
      fontFamily: FONT_REGULAR,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 11,
      lineHeight: '13px',
      color: '#535353',
      letterSpacing: '0.02em',
    },
  },
  elements: {
    PERFORMANCE: {
      content: "''",
      position: 'relative',
      width: 10,
      height: 10,
      transform: 'translate(0px, 0px) scale(0.5)',
      border: '0px solid transparent',
      borderRadius: '30px',
    },
    INPUT: {},
    INPUT_LABLE: {
      fontFamily: FONT_BOLD,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '20px',
      color: '#000000',
    },
    DOWNBOX: {
      border: '1px solid #CFCFCF',
      boxSizing: 'border-box',
      borderRadius: '3px',
      padding: 4,
    },
    CARD: {
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
      borderRadius: '3px',
      padding: '16px',
      background: 'white',
    },
    CARD_ROUND: {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
      background: '#FFFFFF',
      borderRadius: '6px',
      padding: '16px',
    },
    TABLE_MOBILE: {
      borderTopBottom: {
        border: '1px solid #ececec',
        borderLeft: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
        height: '100%',
        minHeight: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      borderLeft: {
        border: '1px solid #ececec',
        borderLeft: '1px solid #ececec',
        borderRight: 'none',
        borderTopLeftRadius: '3px',
        borderBottomLeftRadius: '3px',
        boxSizing: 'border-box',
        height: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginLeft: '10px',
        paddingLeft: '10px',
      },
      borderRight: {
        border: '1px solid #ececec',
        borderLeft: 'none',
        borderRight: '1px solid #ececec',
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
        boxSizing: 'border-box',
        height: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginRight: '10px',
        paddingRight: '10px',
      },
      logo: {
        width: '32px',
        height: '32px',
        marginRight: '3px',
      },
    },
    TABLE: {
      borderTopBottom: {
        border: '1px solid #ececec',
        borderLeft: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
        height: '100%',
        minHeight: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      borderLeft: {
        border: '1px solid #ececec',
        borderLeft: '1px solid #ececec',
        borderRight: 'none',
        borderTopLeftRadius: '3px',
        borderBottomLeftRadius: '3px',
        boxSizing: 'border-box',
        height: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
        marginLeft: '20px',
        paddingLeft: '20px',
      },
      borderRight: {
        border: '1px solid #ececec',
        borderLeft: 'none',
        borderRight: '1px solid #ececec',
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
        boxSizing: 'border-box',
        height: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
        marginRight: '20px',
        paddingRight: '20px',
      },
      logo: {
        width: '32px',
        height: '32px',
        marginRight: '5px',
      },
    },
  },
  decoration: {
    SHADOW_SMALL: {
      boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.16)',
    },
  },
  strings: {
    walletRegex: '0xa0[0-9a-zA-Z]{62}',
    transactionRegex: '0x(?!a0)[0-9a-z]{64}',
    WALLET_SEARCH: 'WALLET_SEARCH',
    TRANSACTION_SEARCH: 'TRANSACTION_SEARCH',
    LOGIN_OPENED: 'Opened login modal',
    LOGIN_LEDGER: 'Login with Ledger',
    LOGIN_KEYSTORE: 'Login with Keystore',
    LOGIN_PRIVATE_KEY: 'Login with Private Key',
    LOGIN_MNEMONIC_PHRASE: 'Login with Mnemonic Phrase',
    SEND_AION: 'Send Aion opened',
    RECEIVE_AION: 'Recieve Aion opened',
    START_STAKING: 'Start Staking opened',
    UNSTAKE_TO_THIS_POOL: 'Unstake to this pool opened',
    STAKE_TO_THIS_POOL: 'Stake to this pool opened',
    TRANSFER_TO_THIS_POOL: 'Transfer to this pool opened',
    START_RECIEVING_AION: 'Start receiving Aion opened',
    ACCOUNT_MODAL_OPENED: 'Address button (top right)',
    SIGN_OUT_CLICKED: 'Address button (top right)” > Sign Out',
    WITHDRAW_REWARDS: 'Withdraw Rewards',
    VIEW_TRANSACTIONS: 'My Transactions',
    VIEW_HOME: 'Home',
    VIEW_STAKING: 'Staking',

  },
};
