import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';

const styles = {
  root: {},
  item: {
    width: '80%',
    margin: '10px 10% 0 10%',
    verticalAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    color: '#ffffff',
  },
  icon: {
    marginTop: '-10px',
  },
};

export default function MenuBar(props) {
  const history = useHistory();
  const location = useLocation();
  const { items } = props;
  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={['/home']}
      style={styles.root}
      selectedKeys={[location.pathname]}
    >
      {items.map((item) => (
        <Menu.Item
          style={styles.item}
          onClick={() => history.push(item.link)}
          key={item.link}
          icon={<Icon component={() => <item.icon color="#ffffff" />} />}
        >
          {item.text}
        </Menu.Item>
      ))}
    </Menu>
  );
}

MenuBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.func,
    }),
  ).isRequired,
};
