import React from 'react';
import PropTypes from 'prop-types';

function IconSearch(props) {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3894 20.37L21.3894 20.37C21.4166 20.3428 21.4381 20.3106 21.4528 20.2751C21.4675 20.2397 21.4751 20.2017 21.4751 20.1633C21.4751 20.1249 21.4675 20.0869 21.4528 20.0514C21.4381 20.016 21.4166 19.9838 21.3895 19.9566C21.3895 19.9566 21.3894 19.9566 21.3894 19.9566L15.3646 13.9318C16.2886 12.7062 16.7875 11.2187 16.7875 9.65625C16.7875 7.74992 16.0433 5.96206 14.6981 4.61451C13.3528 3.26677 11.5601 2.525 9.65625 2.525C7.75237 2.525 5.95978 3.26914 4.61451 4.61438C3.26678 5.95973 2.525 7.75005 2.525 9.65625C2.525 11.5602 3.26916 13.3528 4.61445 14.6981C5.95979 16.0457 7.75008 16.7875 9.65625 16.7875C11.2187 16.7875 12.7039 16.2886 13.9294 15.3669L19.9543 21.3894C19.9543 21.3894 19.9543 21.3894 19.9543 21.3895C19.9814 21.4166 20.0136 21.4381 20.0491 21.4528C20.0845 21.4675 20.1225 21.4751 20.1609 21.4751C20.1993 21.4751 20.2373 21.4675 20.2728 21.4528C20.3083 21.4381 20.3405 21.4166 20.3676 21.3894L21.3894 20.37ZM13.298 13.298C12.323 14.2708 11.0312 14.8062 9.65625 14.8062C8.2813 14.8062 6.98952 14.2708 6.01446 13.298C5.04175 12.323 4.50625 11.0312 4.50625 9.65625C4.50625 8.28123 5.04179 6.98717 6.01438 6.01454C6.98944 5.04178 8.28126 4.50625 9.65625 4.50625C11.0314 4.50625 12.3254 5.03955 13.298 6.01438C14.2707 6.98944 14.8062 8.28126 14.8062 9.65625C14.8062 11.0313 14.2707 12.3254 13.298 13.298Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
    </svg>
  );
}

IconSearch.propTypes = {
  color: PropTypes.string.isRequired,
};

export default IconSearch;
