import React, { useState } from 'react';
import { Input, Tag } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { text, sizes, elements } from '../../../constants';
import Button from '../../Button';
import WarningIcon from '../../../assets/icons/ic_exclamation_warning_filled.svg';
import TransactionHelper from '../../../utils/TransactionHelper';

const styles = {
  root: {
    width: '561px',
    maxWidth: '900px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rootMobile: {
    width: '100%',
  },
  title: {
    ...text.TITLE_MEDIUM,
    whiteSpace: 'break-spaces',
    maxWidth: '75%',
    marginBottom: '50px',
  },
  lable: {
    ...elements.INPUT_LABLE,
    marginTop: sizes.SPACE_SMALL,
  },
  input: {
    marginTop: '8px',
    marginBottom: '5px',
    color: '#1C1C1C',
    fontSize: '26px',
  },
  buttonLayout: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row-reverse',
    marginTop: sizes.SPACE_MEDIUM,
  },
  tagWrapper: {
    marginTop: sizes.SPACE_SMALL,
    marginBottom: sizes.SPACE_LARGE,
  },
  browse: {
    position: 'absolute',
    transform: 'translate(-110%, 40%)',
    pointerEvents: 'none',
    backgroundColor: '#1C1C1C',
    borderColor: 'transparent',
  },
};
const KeystoreAccounts = ({ next }) => {
  const [fileBuffer, setFileBuffer] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [keystorePassword, setKeystorePassword] = useState(null);
  const [error, setError] = useState(false);
  const screens = useBreakpoint();
  const mobile = !screens.md;
  return (
    <div style={mobile ? styles.rootMobile : styles.root}>
      <div style={styles.title}>Keystore File Sign In</div>
      <div style={styles.lable}>Keystore File</div>
      <label htmlFor="fileInput">
        <input
          id="fileInput"
          style={{ display: 'none' }}
          size="large"
          type="file"
          placeholder="Browse your computer..."
          onChange={(e) => {
            //   setPrivateKey(e.target.value);
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
              setFileName(file.name);
              setFileBuffer(Buffer.from(reader.result));
            };
            reader.readAsArrayBuffer(file);

            setError(false);
          }}
        />
        <Input
          style={{ ...styles.input, pointerEvents: 'none' }}
          size="large"
          placeholder="Browse your computer..."
          value={fileName}
        />
        <Button style={styles.browse} type="primary">
          BROWSE
        </Button>
      </label>

      <div style={styles.lable}>Password</div>
      <Input
        style={styles.input}
        size="large"
        type="password"
        placeholder="Enter your password..."
        value={keystorePassword}
        onChange={(e) => {
          setKeystorePassword(e.target.value);
          setError(false);
        }}
      />
      {error && (
        <div style={styles.tagWrapper}>
          <Tag icon={<img src={WarningIcon} alt="warning" />} color="red">
            Unable to read keystore file, potentially incorrect password
          </Tag>
        </div>
      )}
      <div style={styles.buttonLayout}>
        <Button
          disabled={!keystorePassword || !fileBuffer || !fileName}
          type="primary"
          onClick={async () => {
            try {
              const account = await TransactionHelper.accountFromKeystore(
                fileBuffer,
                keystorePassword,
              );
              next(account);
            } catch (ex) {
              setError(true);
            }
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default KeystoreAccounts;
