import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

export const POOLS_UPDATE = 'POOLS_UPDATE';

export const updatePools = ReduxUtils.createAction(POOLS_UPDATE, 'pools');

export const fetchPools = () => async (
  dispatch,
  getState,
  { socketService },
) => {
  dispatch(updatePools(DataModel.initLoading()));
  try {
    socketService.wsSend({
      method: 'pools',
      params: [false],
    });
  } catch (ex) {
    dispatch(updatePools(DataModel.error(0, ex.message)));
  }
};
