import React from 'react';
import {
  Col, Row, Card, Grid,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Balance from './Balance';
import Button from './Button';
import { elements, text } from '../constants';
import { sendAmplitudeData } from '../utils/Amplitude';
import {strings} from '../constants';

const { useBreakpoint } = Grid;

const styles = {
  walletCard: {
    ...elements.CARD,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '134px',
  },
  walletCardMobile: {
    ...elements.CARD_ROUND,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeading: {
    ...text.HEADING_MEDIUM,
    flexGrow: '1',
  },
  cardHorizontalBody: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  cardTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingRight: '10px',
    flexGrow: 1,
  },
  cardText: {
    ...text.BODY,
    paddingRight: '8px',
  },
};

const HomeStakingSection = ({ onStake, onUnstake, onWithdraw }) => {
  const balance = useSelector((state) => state.balance);
  const stakedBalance = useSelector((state) => state.stakedBalance);
  const rewardsBalance = useSelector((state) => state.rewardsBalance);
  const screens = useBreakpoint();
  const mobile = !screens.md;
  return (
    <Row gutter={[16, 16]}>
      <Col lg={8} xs={24}>
        <Card bodyStyle={mobile ? styles.walletCardMobile : styles.walletCard}>
          <div style={styles.cardHeading}>Available Balance</div>
          <Row style={{ paddingTop: '5px' }}>
            <Col style={styles.cardTextWrapper}>
              <div style={styles.cardText}>
                {balance.data && <Balance value={balance.data} />}
              </div>
            </Col>
            <Col style={{ paddingTop: '5px' }}>
              <Button type="primary" onClick={onStake}>Stake</Button>
            </Col>
          </Row>
        </Card>
      </Col>
      {!stakedBalance?.data?.isZero() && (
      <Col lg={8} xs={12}>
        <Card bodyStyle={mobile ? styles.walletCardMobile : styles.walletCard}>
          <div style={styles.cardHeading}>Staked balance</div>
          <Row justify="space-between">
            <Col style={styles.cardTextWrapper}>
              <div style={styles.cardText}>
                {stakedBalance.data && <Balance value={stakedBalance.data} />}
              </div>
            </Col>
            <Col style={{ paddingTop: '5px' }}>
              <Button onClick={onUnstake}>Unstake</Button>
            </Col>
          </Row>
        </Card>
      </Col>
      )}
      {!rewardsBalance?.data?.isZero() && (
      <Col lg={8} xs={12}>
        <Card bodyStyle={mobile ? styles.walletCardMobile : styles.walletCard}>
          <div style={styles.cardHeading}>Rewards</div>
          <Row justify="space-between">
            <Col style={styles.cardTextWrapper}>
              <div style={styles.cardText}>
                {rewardsBalance.data && <Balance value={rewardsBalance.data} />}
              </div>
            </Col>
            <Col style={{ paddingTop: '5px' }}>
              <Button onClick={ ()=> { sendAmplitudeData(strings.WITHDRAW_REWARDS);
                onWithdraw(); //Verify working.
              }}>
                Withdraw
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
      )}
    </Row>
  );
};

HomeStakingSection.propTypes = {
  onUnstake: PropTypes.func.isRequired,
};

export default HomeStakingSection;
