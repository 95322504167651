import ReduxUtils from '../utils/ReduxUtils';
import DataModel from '../models/DataModel';

export const STAKING_FINILIZATIONS_TRANSACTIONS_UPDATE = 'STAKING_FINILIZATIONS_TRANSACTIONS_UPDATE';

export const updateStakingFinalizationTransactions = ReduxUtils.createAction(
  STAKING_FINILIZATIONS_TRANSACTIONS_UPDATE,
  'stakingFinalizationTransactions',
);

export const fetchStakingFinalizationTransactions = () => async (
  dispatch,
  getState,
  { apiService },
) => {
  const { account, stakingFinalizationTransactions } = getState();
  dispatch(
    updateStakingFinalizationTransactions(
      stakingFinalizationTransactions.data
        ? new DataModel(stakingFinalizationTransactions.data)
        : DataModel.initLoading(),
    ),
  );
  try {
    apiService.fetchStakingFinalizationTransactions(account.address);
  } catch (ex) {
    dispatch(updateStakingFinalizationTransactions(DataModel.error(0, ex.message)));
  }
};
