import ReduxUtils from '../utils/ReduxUtils';

export const TOGGLE_SEND_FUNDS = 'TOGGLE_SEND_FUNDS';
export const SHOW_SEND_FUNDS = 'TOGGLE_SEND_FUNDS';

export const TOGGLE_RECEIVE_FUNDS = 'TOGGLE_RECEIVE_FUNDS';
export const SHOW_RECEIVE_FUNDS = 'TOGGLE_RECEIVE_FUNDS';

export const TOGGLE_UNSTAKE_FUNDS = 'TOGGLE_UNSTAKE_FUNDS';

export const TOGGLE_ACCOUNT_MODAL = 'TOGGLE_ACCOUNT_MODAL';

export const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL';

export const TOGGLE_ADDRESS_COPY_CONFIRMATION_MODAL =
  'TOGGLE_ADDRESS_COPY_CONFIRMATION_MODAL';

export const TOGGLE_POOL_DETAILS_MODAL = 'TOGGLE_POOL_DETAILS_MODAL';

export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';

export const MODAL_EXTRA_DATA = 'modalExtraData';

export const toggleSendFundsModal = ReduxUtils.createAction(
  TOGGLE_SEND_FUNDS,
  MODAL_EXTRA_DATA,
);

export const toggleReceiveFundsModal = ReduxUtils.createAction(
  TOGGLE_RECEIVE_FUNDS,
  MODAL_EXTRA_DATA,
);

export const toggleUnstakeFundsModal = ReduxUtils.createAction(
  TOGGLE_UNSTAKE_FUNDS,
  MODAL_EXTRA_DATA,
);

export const toggleAccountModal = ReduxUtils.createAction(
  TOGGLE_ACCOUNT_MODAL,
  MODAL_EXTRA_DATA,
);

export const toggleLoginModal = ReduxUtils.createAction(
  TOGGLE_LOGIN_MODAL,
  MODAL_EXTRA_DATA,
);

export const toggleAddressCopyConfirmation = ReduxUtils.createAction(
  TOGGLE_ADDRESS_COPY_CONFIRMATION_MODAL,
  MODAL_EXTRA_DATA,
);

export const togglePoolDetailsModal = ReduxUtils.createAction(
  TOGGLE_POOL_DETAILS_MODAL,
  MODAL_EXTRA_DATA,
);

export const onAddressClipboardCopy = () => async (dispatch) => {
  dispatch(toggleReceiveFundsModal());
  dispatch(toggleAddressCopyConfirmation());
};

export const closeAllModals = ReduxUtils.createAction(
  CLOSE_ALL_MODALS,
  MODAL_EXTRA_DATA,
);
