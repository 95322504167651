import React from 'react';
import {
  Col, Row, Card, Grid,
} from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { elements, strings, text } from '../constants';
import Balance from './Balance';
import Button from './Button';
import {
  toggleReceiveFundsModal,
  toggleSendFundsModal,
  toggleAddressCopyConfirmation,
} from '../actions/modals';
import { formatAddress } from '../utils/utils';
import Checksum from '../utils/Checksum.js';
import { sendAmplitudeData } from '../utils/Amplitude';

const { useBreakpoint } = Grid;

const styles = {
  walletCard: {
    ...elements.CARD,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '155px',
  },
  walletCardMobile: {
    ...elements.CARD_ROUND,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  walletBlockCardMobile: {
    ...elements.CARD_ROUND,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '175px',
  },
  cardHeading: {
    ...text.HEADING_MEDIUM,
    flexGrow: '1',
  },
  cardHorizontalBody: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  cardTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingRight: '10px',
    flexGrow: 1,
  },
  cardText: {
    ...text.BODY,
    paddingRight: '8px',
  },
  availBal: {
    color: '#CFCFCF',
    fontSize: '20px',
    fontWeight: 'bold',
  },
};

const HomeWalletSection = (props) => {
  const dispatch = useDispatch();
  const { account, balance, stakedBalance } = useSelector(
    (state) => ({
      account: state.account,
      balance: state.balance,
      stakedBalance: state.stakedBalance,
    }),
    shallowEqual,
  );

  const sum = stakedBalance.data?.plus(balance.data);
  const screens = useBreakpoint();
  const mobile = !screens.md;
  let checksummedAddress = '';
  if (account.address) {
    checksummedAddress = Checksum.encode(account.address);
  }
  return (
    <Row gutter={[16, 16]}>
      <Col lg={8} xs={24}>
        <Card bodyStyle={mobile ? styles.walletCardMobile : styles.walletCard}>
          <div style={styles.cardHeading}>Total Aion</div>
          <Row style={{ paddingTop: '5px', display: 'flex', alignItems: 'baseline' }}>
            {balance.data && (
            <>
              <Balance value={sum || 0} />
              &nbsp;&nbsp;
              <span style={styles.availBal}>
                <Balance style={styles.availBal} value={balance.data || 0} />
                &nbsp;available
              </span>
            </>
            )}
            <Col />
          </Row>
        </Card>
      </Col>
      <Col lg={8} xs={12}>
        <Card bodyStyle={mobile ? styles.walletBlockCardMobile : styles.walletCard}>
          <div style={styles.cardHeading}>Send</div>
          <Row justify="space-between" style={{ alignItems: mobile ? 'center' : 'flex-end', flexWrap: mobile ? 'wrap-reverse' : 'nowrap', flexDirection: mobile ? 'column' : 'row' }}>
            <Col flex="auto">
              <div style={styles.cardText}>
                Safely send Aion to another address.
              </div>
            </Col>
            <Col style={{ paddingTop: '10px' }}>
              <Button onClick={() => {
                sendAmplitudeData(strings.SEND_AION);
                dispatch(toggleSendFundsModal());
              }}
              >
                {mobile ? 'Send' : 'Send Aion'}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col lg={8} xs={12}>
        <Card bodyStyle={mobile ? styles.walletBlockCardMobile : styles.walletCard}>
          <div style={styles.cardHeading}>Receive</div>
          <Row justify="space-between" style={{ alignItems: mobile ? 'center' : 'flex-end', flexWrap: mobile ? 'wrap-reverse' : 'nowrap', flexDirection: mobile ? 'column' : 'row' }}>
            <Col flex="auto">
              <div
                style={{
                  ...styles.cardText,
                  wordBreak: mobile ? 'inherit' : 'break-all',
                }}
              >
                {mobile
                  ? 'Share your address to securely recevie Aion.'
                  : (
                    <CopyToClipboard
                      text={checksummedAddress}
                      onCopy={() => dispatch(toggleAddressCopyConfirmation())}
                    >
                      <span style={{ cursor: 'pointer' }}>{formatAddress(checksummedAddress, 10, 15)}</span>
                    </CopyToClipboard>
                  )}
              </div>
            </Col>
            <Col style={{ paddingTop: '10px' }}>
              <Button
                type="primary"
                onClick={() => {
                  sendAmplitudeData(strings.RECEIVE_AION);
                  dispatch(toggleReceiveFundsModal());
                }}
              >
                {mobile ? 'Receive' : 'Receive Aion'}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

HomeWalletSection.propTypes = {};

export default HomeWalletSection;
